<form [formGroup]="formGroup" novalidate cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
    <div fxLayout="row" fxLayoutGap="10px">
        <div fxLayout="column" fxFlex="1 1 auto">
            <label for="application-meta-title" class="small-input-label" data-name="application-meta-title">{{
                'v2.application.edit.meta.title' | translate
            }}</label>
            <input
                class="small-input"
                type="text"
                id="application-meta-title"
                name="application-meta-title"
                autocomplete="off"
                formControlName="metaTitle" />

            <label for="application-meta-description" class="small-input-label" data-name="application-meta-description">{{
                'v2.application.edit.meta.description' | translate
            }}</label>
            <textarea
                class="small-input"
                type="text"
                id="application-meta-description"
                name="application-meta-description"
                autocomplete="off"
                formControlName="metaDescription"></textarea>

            <app-template-picker
                [label]="'v2.application.edit.meta.home.page' | translate"
                [value]="application.homeTemplateId"
                [addQuotesToValue]="false"
                (valueUpdated)="onHomeTemplateChanged($event)"
                (openTab)="onOpenTemplate($event)"></app-template-picker>

            <h4 class="!text-sm !font-bold !mb-1 !mt-1">{{ 'v2.application.edit.meta.favicon.title' | translate }}</h4>
            <div class="button-group" role="group">
                <button
                    [matTooltip]="'v2.part.image.from.media.library' | translate"
                    type="button"
                    class="button-group-button-left"
                    autofocus
                    (click)="formGroup.controls['faviconLinkType'].setValue('media-library')"
                    [ngClass]="formGroup.controls['faviconLinkType'].value === 'media-library' ? 'button-group-button-selected' : ''">
                    <mat-icon>image</mat-icon>
                </button>

                <button
                    [matTooltip]="'v2.part.image.from.url' | translate"
                    type="button"
                    class="button-group-button-right"
                    autofocus
                    (click)="formGroup.controls['faviconLinkType'].setValue('url')"
                    [ngClass]="formGroup.controls['faviconLinkType'].value === 'url' ? 'button-group-button-selected' : ''">
                    <mat-icon>http</mat-icon>
                </button>
            </div>

            <ng-container *ngIf="this.formGroup.get('faviconLinkType').value === 'url'">
                <label for="application-meta-favicon-url" class="small-input-label" data-name="application-meta-favicon-url">{{
                    'v2.application.edit.meta.favicon.image.url' | translate
                }}</label>
                <input
                    class="small-input"
                    type="text"
                    id="application-meta-favicon-url"
                    name="application-meta-favicon-url"
                    autocomplete="off"
                    formControlName="faviconUrl" />
            </ng-container>

            <codex-media-picker
                *ngIf="this.formGroup.get('faviconLinkType').value === 'media-library'"
                [mediaId]="this.application.faviconMediaId"
                (mediaChanged)="onFaviconMediaIdChanged($event)"
                type="image">
            </codex-media-picker>
            <hr class="seperator" />
            <div class="flex gap-1 justify-end mt-2 mb-2">
                <button class="primary-button button-large" data-name="save" (click)="onUpdateApplication()">
                    <mat-icon>save</mat-icon>
                    {{ 'general.save' | translate }}
                </button>
                <button class="secondary-button button-large" data-name="cancel" (click)="onCancel()">
                    <mat-icon>cancel</mat-icon>
                    {{ 'general.cancel' | translate }}
                </button>
            </div>
        </div>
    </div>
</form>
