import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Argument, createArguments, showArgumentInput } from '@backoffice/data-access/editor';
import { Observable, Subscription } from 'rxjs';
import { OverviewActionDto } from '@backoffice/data-access/editor';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TemplateEditorFacade } from '@backoffice/data-access/editor';
import { plainToClass } from 'class-transformer';
import { Parameter } from '@backoffice/data-access/editor';
import { Scope } from '@backoffice/data-access/editor';
import { take } from 'rxjs/operators';
import { TabDefinition } from '@backoffice/data-access/editor';
import { backofficeEnvironment } from '@shared/environment';

@Component({
    selector: 'argument-template-params',
    templateUrl: './argument-template-params.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class ArgumentTemplateParamsComponent implements OnDestroy {
    @Input()
    set arguments(argumentList: Argument[]) {
        this._arguments = argumentList;
        this.loadTemplateArguments();
    }

    _arguments!: Argument[];

    @Input()
    set argument(argument: Argument) {
        this._argument = argument;
    }

    _argument!: Argument;

    @Input() language!: string;
    @Input() contextId!: string;
    @Input() root: Argument[];
    @Input() scope!: Scope;

    @Input()
    onlyLiteralValues: boolean = false;

    @Output() argumentUpdated: EventEmitter<{ argument: Argument }> = new EventEmitter<{ argument: Argument }>();
    @Output() openTab: EventEmitter<TabDefinition> = new EventEmitter<TabDefinition>();

    actions!: Observable<OverviewActionDto[]>;

    formGroup!: FormGroup;

    subscriptions: Subscription = new Subscription();

    templateArgument!: Argument | undefined;

    showSubArguments: boolean = false;

    subArguments: Argument[] | undefined = [];

    templateId!: string | null;

    filterParameters: string[] = [];

    constructor(
        public changeDetectorRef: ChangeDetectorRef,
        private fb: FormBuilder,
        private templateEditorFacade: TemplateEditorFacade
    ) {}

    ngOnDestroy(): void {
        setTimeout(() => {
            this.subscriptions.unsubscribe();
        }, backofficeEnvironment.autosavedebounce + 100);
    }

    getTemplateIdFromArgument() {
        this.templateArgument = this._arguments.find(
            argument =>
                argument.parameterId === this._argument.parameter?.linkedTemplateParameterId ||
                argument.selectorId === this._argument.parameter?.linkedTemplateParameterId
        );
        let templateId = null;
        if (
            this.templateArgument &&
            this.templateArgument.inputSelectionType === 'template' &&
            !!this.templateArgument.value &&
            this.templateArgument.value !== "'undefined'"
        ) {
            templateId = this.templateArgument.value;
        } else if (
            this.templateArgument &&
            this.templateArgument.inputSelectionType === 'template-contract' &&
            !!this.templateArgument.contractValue &&
            this.templateArgument.contractValue !== "'undefined'"
        ) {
            templateId = this.templateArgument.contractValue;
        }
        return templateId;
    }

    loadTemplateArguments() {
        if (this._argument) {
            let templateIdFromArgument = this.getTemplateIdFromArgument();
            if (templateIdFromArgument !== this.templateId && !!templateIdFromArgument) {
                this.templateId = templateIdFromArgument;
                this.templateEditorFacade
                    .findById(this.removeQuotesIfPresent(this.templateId))
                    .pipe(take(1))
                    .subscribe(template => {
                        if (template) {
                            if (this._argument.subArgumentsForAction !== this.templateId) {
                                this._argument.subArguments = createArguments(template.parameters).map(argument => {
                                    return plainToClass(Argument, {
                                        id: argument.id,
                                        value: argument.value,
                                        parameterId: argument?.parameter?.id,
                                        selectorId: argument?.parameter?.selectorId,
                                        parameter: plainToClass(Parameter, argument.parameter),
                                    });
                                });
                            } else {
                                this._argument.mergeArguments(createArguments(template.parameters));
                            }

                            this._arguments.forEach(argument => {
                                if (
                                    this._argument?.parameter?.linkedFilterParams?.indexOf(argument.parameterId) > -1 ||
                                    this._argument.parameter?.linkedFilterParams?.indexOf(argument.selectorId) > -1
                                ) {
                                    if (argument.value) {
                                        this.filterParameters.push(this.removeQuotesIfPresent(argument.value));
                                    }
                                }
                            });
                            this.subArguments = this._argument.subArguments?.filter(
                                subArgument => this.filterParameters.indexOf(subArgument.parameter?.name) === -1
                            );
                            if (!this.subArguments) {
                                this.subArguments = [];
                            }
                            this.showSubArguments = true;
                            this.changeDetectorRef.detectChanges();
                            this._argument.subArgumentsForAction = this.templateId;
                        } else {
                            this.templateId = null;
                            this.showSubArguments = true;
                            this._argument.subArguments = [];
                            this.subArguments = [];
                            this.changeDetectorRef.detectChanges();
                            this.argumentUpdated.emit({ argument: this._argument });
                        }
                    });
            } else if (templateIdFromArgument !== this.templateId && !templateIdFromArgument) {
                // The template was previously searched, but now it was deleted.
                this.templateId = null;
                this.showSubArguments = true;
                this._argument.subArguments = [];
                this.subArguments = [];
                this.changeDetectorRef.detectChanges();
                this.argumentUpdated.emit({ argument: this._argument });
            }
        }
    }

    onActionExecutionArgumentUpdated($event: { argument: Argument }) {
        let subArgument = this._argument.subArguments?.find(subArgument => $event.argument.id === subArgument.id);
        if (subArgument) {
            subArgument.value = $event.argument.value;
            subArgument.inputSelectionType = $event.argument.inputSelectionType;
            subArgument.subArguments = $event.argument.subArguments;
        }
        this.argumentUpdated.emit({ argument: this._argument });
    }

    removeQuotesIfPresent(value: string | undefined): string | undefined {
        if (value && value.startsWith("'") && value.endsWith("'")) {
            return value.substring(1, value.length - 1);
        } else {
            return value;
        }
    }

    identifyArgument(index: number, item: Argument): string {
        return item.id;
    }

    protected readonly showArgumentInput = showArgumentInput;
}
