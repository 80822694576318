<mat-dialog-content class="!p-4">
    <h1 class="!mb-2">{{ 'v2.authorization.rule.title' | translate }}</h1>
    <div>
        <form [formGroup]="form">
            <label for="authorization-link-name" class="small-input-label" data-name="authorization-link-name">{{
                'v2.authorization.rule.name' | translate
            }}</label>
            <input
                class="small-input"
                type="text"
                id="authorization-link-name"
                name="authorization-link-name"
                autocomplete="off"
                formControlName="authenticationLinkName"
                required />

            <div *ngIf="groupsAndRoles$ | async as data; else selectApp">
                <div class="groups" *ngIf="data.groups as groups"></div>
                <div class="roles" *ngIf="data.roles as roles"></div>
            </div>

            <div *ngIf="groupsAndRoles$ | async as data; else selectApp">
                <div class="groups !mb-2" *ngIf="data.groups as groups">
                    <label class="small-input-label">{{ 'company.edit.permissions.groups.title' | translate }}</label>
                    <div class="groups__content grid grid-cols-4">
                        <ng-container *ngIf="groups.length != 0; else noGroups">
                            <div *ngFor="let group of groups" class="flex flex-row gap-2 cursor-pointer items-center">
                                <input
                                    [value]="group.id"
                                    [checked]="group.checked"
                                    (click)="onGroupSelected(group)"
                                    [attr.id]="'group-' + group.id"
                                    type="checkbox"
                                    value=""
                                    class="checkbox cursor-pointer" />
                                <label [attr.for]="'group-' + group.id" class="small-input-label cursor-pointer !m-0">{{
                                    group.name
                                }}</label>
                            </div>
                        </ng-container>
                        <ng-template #noGroups>
                            <p class="text-text_2">{{ 'company.edit.permissions.groups.no.found' | translate }}</p>
                        </ng-template>
                    </div>
                </div>
                <div class="roles !mb-2" *ngIf="data.roles as roles">
                    <label class="small-input-label">{{ 'company.edit.permissions.roles.title' | translate }}</label>
                    <div class="roles__content grid grid-cols-4">
                        <ng-container *ngIf="roles.length != 0; else noRoles">
                            <div *ngFor="let role of roles" class="flex flex-row gap-2 cursor-pointer items-center">
                                <input
                                    [value]="role.id"
                                    [checked]="role.checked"
                                    (click)="onRoleSelected(role)"
                                    [attr.id]="'role-' + role.id"
                                    type="checkbox"
                                    value=""
                                    class="checkbox cursor-pointer" />
                                <label [attr.for]="'role-' + role.id" class="small-input-label cursor-pointer !m-0">{{ role.name }}</label>
                            </div>
                        </ng-container>
                        <ng-template #noRoles>
                            <p class="text-text_2">{{ 'company.edit.permissions.roles.no.found' | translate }}</p>
                        </ng-template>
                    </div>
                </div>
            </div>
            <ng-template #selectApp>
                <p>{{ 'company.edit.permissions.application.select' | translate }}</p>
            </ng-template>
        </form>
    </div>
    <div class="flex justify-end gap-1">
        <button class="primary-button button-medium" (click)="this.dialogRef.close(this.data.authorizationLink)" type="button">
            <mat-icon>save</mat-icon>
            {{ 'general.save' | translate }}
        </button>
        <button class="secondary-button button-medium" (click)="this.dialogRef.close()" type="button">
            <mat-icon>cancel</mat-icon>
            {{ 'general.cancel' | translate }}
        </button>
    </div>
</mat-dialog-content>
