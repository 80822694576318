<mat-dialog-content class="!p-4">
    <ng-container *ngIf="versions$ | async as versions">
        <ng-container *ngIf="versions.length > 0; else noVersions">
            <h1 class="mb-2">{{ 'v2.hub.detail.add.version.title' | translate }}</h1>
            <div>
                <ng-container *ngIf="versions.length > 0">
                    <label for="select-version" class="small-input-label">{{ 'v2.hub.detail.add.version.form.version' | translate }}</label>
                    <select id="select-version" class="small-input" [formControl]="versionControl">
                        <option *ngFor="let version of versions" [value]="version.id">{{ version.name }}</option>
                    </select>
                </ng-container>

                <label for="form-name" class="small-input-label" data-name="form-name">{{
                    'v2.hub.detail.add.version.form.name' | translate
                }}</label>
                <input class="small-input" type="text" id="form-name" name="name" autocomplete="off" [formControl]="nameControl" />

                <label for="version-description" class="small-input-label" data-name="version-description">{{
                    'v2.hub.detail.add.version.form.description' | translate
                }}</label>
                <textarea
                    class="small-input"
                    type="text"
                    id="version-description"
                    name="description"
                    autocomplete="off"
                    [formControl]="descriptionControl"></textarea>
            </div>
            <div class="buttonContainer">
                <button
                    data-name="save"
                    class="primary-button button-medium"
                    [disabled]="!form.valid"
                    type="submit"
                    (click)="onAddClicked()">
                    <mat-icon>save</mat-icon>
                    {{ 'general.save' | translate }}
                </button>
                <button class="secondary-button button-medium" data-name="cancel" (click)="this.onCancelClick()" type="button">
                    <mat-icon>cancel</mat-icon>
                    {{ 'general.cancel' | translate }}
                </button>
            </div>
        </ng-container>
        <ng-template #noVersions>
            <div class="no-versions">
                <h2>{{ 'v2.hub.detail.add.version.no.versions.available.title' | translate }}</h2>
                <img src="/src/images/theme/no_versions.svg" />
                <p>{{ 'v2.hub.detail.add.version.no.versions.available.description' | translate }}</p>
                <button mat-raised-button color="primary" (click)="onCancelClick()">
                    {{ 'v2.hub.detail.add.version.no.versions.available.button.close' | translate }}
                </button>
            </div>
        </ng-template>
    </ng-container>
</mat-dialog-content>
