<ng-container [formGroup]="formGroup" *ngIf="_argument">
    <div class="mb-2">
        <label class="small-input-label">Task name</label>
        <input class="small-input" type="text" autocomplete="off" id="{{ this._argument.id }}-name" formControlName="name" />
    </div>
    <div class="mb-2">
        <label class="small-input-label">Description</label>
        <textarea
            class="small-input"
            type="text"
            id="{{ this._argument.id }}-description"
            autocomplete="off"
            formControlName="description"></textarea>
    </div>
    <argument-value-picker
        *ngIf="descriptionPlaceHolderArguments"
        class="mb-1 block"
        [argument]="this.descriptionPlaceHolderArguments"
        [arguments]="_argument.subArguments"
        [contextId]="contextId"
        [scope]="scope"
        [root]="root"
        [language]="language"
        [onlyLiteralValues]="onlyLiteralValues"
        (openTab)="this.openTab.emit($event)"
        (argumentUpdated)="this.argumentUpdated.emit({ argument: this._argument })">
    </argument-value-picker>

    <div class="mb-2">
        <label class="small-input-label">Expected output</label>
        <textarea
            class="small-input"
            type="text"
            id="{{ this._argument.id }}-expectedOutput"
            autocomplete="off"
            formControlName="expectedOutput"></textarea>
    </div>
    <div class="mb-2">
        <label class="small-input-label">agent</label>
        <input class="small-input" type="text" autocomplete="off" id="{{ this._argument.id }}-agent" formControlName="agent" />
    </div>
</ng-container>
