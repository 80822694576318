<mat-dialog-content class="!p-4 bg-dark-background_level_0 scrollbar scrollbar-primary">
    <form [formGroup]="selectWorkspaceForm" novalidate fxLayout="column">
        <input
            class="small-input"
            type="text"
            id="filtername"
            name="filtername"
            autocomplete="off"
            [placeholder]="'Filter by name'"
            required
            formControlName="filterName" />
        <ul class="workspace-list">
            <ng-container *ngFor="let company of filteredCompanies$ | async">
                <li
                    class="hover:bg-accentlight hover:cursor-pointer"
                    [attr.data-name]="company.companyName.toLowerCase()"
                    (click)="onCompanySelect(company)"
                    [class.selected]="company.id === selectedCompany.id">
                    <ngx-avatars size="35" [round]="false" cornerRadius="5" [name]="company.companyName" matListItemIcon></ngx-avatars>
                    <div class="workspace-name">{{ company.companyName }}</div>
                </li>
            </ng-container>
        </ul>
    </form>
</mat-dialog-content>
