import { combineReducers, createReducer, on } from '@ngrx/store';
import { closeTab, closeTabs, registerTab, selectTab, updateTab } from '../actions/editor.actions';
import { Tab } from '../editor.state';
// @ts-ignore
import { v4 as uuidv4 } from 'uuid';
import { actionDialogReducers, actionInitialState, actionsReducer } from './action-editor.reducers';
import { apiDialogReducers, apiInitialState, apiReducer } from './api-editor.reducers';
import { dataDialogReducers, dataInitialState, dataReducer } from './data-editor.reducers';
import {
    dataFormatDialogReducers,
    dataFormatInitialState,
    dataFormatReducer,
} from '../../dataformat/store/reducer/data-format-editor.reducers';
import { mediaDialogReducers, mediaInitialState, mediaReducer } from './media-editor.reducers';
import {
    templateContextReducer,
    templateDialogReducers,
    templateDragInitialState,
    templateInitialState,
    templateReducer,
} from './template-editor.reducers';
import { selectApplicationSuccess } from '../../../../../../../../apps/no-code-x-backoffice/src/app/store/context/context.actions';
import { jobDialogReducers, jobInitialState, jobReducer } from './job-editor.reducers';
import { actionContextInitialState, actionContextsReducer } from './action-context.reducers';
import {
    designSystemDialogReducers,
    designSystemInitialState,
    designSystemReducer,
} from '../../designsystem/store/reducer/design-system-editor.reducers';
import { rightDialogReducers, rightInitialState, rightReducer } from '../../right/store/reducer/right-editor.reducers';
import { groupDialogReducers, groupInitialState, groupReducer } from '../../group/store/reducer/group-editor.reducers';
import { applicationEventsReducer, applicationEventsState } from './application-events.reducers';

const tabsReducer = (initialData: Tab[]) =>
    createReducer(
        initialData,
        on(registerTab, (tabs, { definition }) => {
            // reset active tabs
            tabs.forEach(t => (t.active = false));

            // If tab present don't add, otherwise add new tab
            const { type, typeId } = definition;
            const existing = tabs.find(t => t.typeId === typeId && t.type === type);
            if (!existing) {
                const name = definition.name ?? 'Unnamed';
                tabs.push({ ...definition, active: true, id: uuidv4(), index: tabs.length, name });
            } else {
                existing.active = true;
            }

            return [...tabs];
        }),
        on(updateTab, (tabs, { definition, updateReference }) => {
            // If tab present update, otherwise do nothing.
            const { name, icon, type, typeId } = definition;
            const existingIndex = tabs.findIndex(t => t.typeId === typeId && t.type === type);
            if (existingIndex !== -1) {
                const tab = tabs[existingIndex];
                tab.name = name;
                tab.icon = icon;

                const updatedTabs = [...tabs];
                updatedTabs[existingIndex] = updateReference ? { ...tab } : tab;

                return updatedTabs;
            }

            return [...tabs];
        }),
        on(selectTab, (tabs, { typeId }) => {
            tabs.forEach(t => (t.active = t.typeId === typeId));
            return [...tabs];
        }),
        on(closeTab, (tabs, { typeId, tabType }) => {
            const filtered: Tab[] = tabs.filter(t => t.typeId !== typeId || t.type !== tabType);
            filtered.forEach((t, i) => (t.index = i));
            const activeTab: Tab | undefined = filtered.find(t => t.active);
            if (!activeTab && filtered.length !== 0) {
                filtered[0].active = true;
            }
            return filtered;
        }),
        on(closeTabs, (tabs, { tabType, typeIds }) => {
            const filtered: Tab[] = tabs.filter(t => !typeIds.includes(t.typeId) || t.type !== tabType);
            filtered.forEach((t, i) => (t.index = i));
            const activeTab: Tab | undefined = filtered.find(t => t.active);
            if (!activeTab && filtered.length !== 0) {
                filtered[0].active = true;
            }
            return filtered;
        }),
        on(selectApplicationSuccess, tabs => [])
    );

export const editorReducers = combineReducers({
    tabs: tabsReducer([]),
    api: apiReducer(apiInitialState),
    apiDialog: apiDialogReducers,
    actions: actionsReducer(actionInitialState),
    actionContexts: actionContextsReducer(actionContextInitialState),
    actionDialog: actionDialogReducers,
    data: dataReducer(dataInitialState),
    dataDialog: dataDialogReducers,
    media: mediaReducer(mediaInitialState),
    mediaDialog: mediaDialogReducers,
    template: templateReducer(templateInitialState),
    templateContext: templateContextReducer(templateDragInitialState),
    templateDialog: templateDialogReducers,
    job: jobReducer(jobInitialState),
    jobDialog: jobDialogReducers,
    dataformat: dataFormatReducer(dataFormatInitialState),
    dataformatDialog: dataFormatDialogReducers,
    designSystem: designSystemReducer(designSystemInitialState),
    designSystemDialog: designSystemDialogReducers,
    right: rightReducer(rightInitialState),
    rightDialog: rightDialogReducers,
    group: groupReducer(groupInitialState),
    groupDialog: groupDialogReducers,
    applicationEvents: applicationEventsReducer(applicationEventsState.applicationEvents),
});
