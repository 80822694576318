import { Program } from './program';
import { Type } from 'class-transformer';
import { Method } from './method';

export class Action {
    public id: string;
    public name: string;
    public description: string;
    public iconName: string;
    public applicationId: string;
    public companyId: string;
    public prototype: boolean;

    @Type(() => Program)
    public program: Program = new Program();

    // Action should be initialized before any adjustments can be made.
    // Mostly due to scope issues when this is not the case
    public initialized = false;

    public deleted: boolean;
    public invalidated: boolean;
    public backEndUpdate: boolean;

    public prepareForSave() {
        if (this.program) {
            this.program.prepareForSave();
        }
    }
    public static createDeleted(id: string): Action {
        const action = new Action();
        action.id = id;
        action.deleted = true;
        return action;
    }

    public isValid() {
        return this.isIdValid() && this.program.isValid();
    }

    public isIdValid() {
        return !!this.id && this.id !== '';
    }

    public initialize(methodMap: Map<string, Method>, actionMap: Map<string, Action>, language: string) {
        this.program.initProgram(methodMap, actionMap);
        this.initialized = true;
    }
}
