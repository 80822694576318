import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { backofficeEnvironment } from '@shared/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoggerService } from '@backoffice/utils';
import { CreateGenerativeTaskDto, GenerativeTaskCreatedDto } from '@backoffice/data-access/editor';
import { GenerativeTaskDto } from '../generative-tasks/interfaces/generative-task.dto';

@Injectable()
export class GenerativeTaskService {
    constructor(
        private readonly log: LoggerService,
        private readonly httpClient: HttpClient
    ) {}

    public create(dto: CreateGenerativeTaskDto): Observable<GenerativeTaskCreatedDto> {
        this.log.debug('Creating generativeTasks', [dto]);
        return this.httpClient.post<GenerativeTaskCreatedDto>(
            `${backofficeEnvironment.rest.v2.generativetasks}company/${dto.companyId}/application/${dto.applicationId}`,
            dto,
            { headers: new HttpHeaders().set('X-Skip-Loading-Interceptor', 'true') }
        );
    }

    public get(generativeTaskId: string, applicationId: string, companyId: string): Observable<GenerativeTaskDto> {
        this.log.debug('Creating generativeTasks', [generativeTaskId]);
        return this.httpClient.get<GenerativeTaskDto>(
            `${backofficeEnvironment.rest.v2.generativetasks}company/${companyId}/application/${applicationId}/${generativeTaskId}`,
            { headers: new HttpHeaders().set('X-Skip-Loading-Interceptor', 'true') }
        );
    }

    public executeChange(
        generativeTask: GenerativeTaskDto,
        generativeTaskId: string,
        applicationId: string,
        companyId: string
    ): Observable<void> {
        this.log.debug('Creating generativeTasks', [generativeTaskId]);
        return this.httpClient.put<void>(
            `${backofficeEnvironment.rest.v2.generativetasks}company/${companyId}/application/${applicationId}/${generativeTaskId}/change/activate`,
            generativeTask,
            { headers: new HttpHeaders().set('X-Skip-Loading-Interceptor', 'true') }
        );
    }

    public executeUndo(
        generativeTask: GenerativeTaskDto,
        generativeTaskId: string,
        applicationId: string,
        companyId: string
    ): Observable<void> {
        this.log.debug('Creating generativeTasks', [generativeTaskId]);
        return this.httpClient.put<void>(
            `${backofficeEnvironment.rest.v2.generativetasks}company/${companyId}/application/${applicationId}/${generativeTaskId}/change/undo`,
            generativeTask,
            { headers: new HttpHeaders().set('X-Skip-Loading-Interceptor', 'true') }
        );
    }

    public executeChangeReference(
        generativeTask: GenerativeTaskDto,
        generativeTaskId: string,
        applicationId: string,
        companyId: string
    ): Observable<void> {
        this.log.debug('Creating generativeTasks', [generativeTaskId]);
        return this.httpClient.put<void>(
            `${backofficeEnvironment.rest.v2.generativetasks}company/${companyId}/application/${applicationId}/${generativeTaskId}/change/reference`,
            generativeTask,
            { headers: new HttpHeaders().set('X-Skip-Loading-Interceptor', 'true') }
        );
    }

    public changeQuality(
        generativeTask: GenerativeTaskDto,
        generativeTaskId: string,
        applicationId: string,
        companyId: string
    ): Observable<void> {
        this.log.debug('Creating generativeTasks', [generativeTaskId]);
        return this.httpClient.put<void>(
            `${backofficeEnvironment.rest.v2.generativetasks}company/${companyId}/application/${applicationId}/${generativeTaskId}/change/quality`,
            generativeTask,
            { headers: new HttpHeaders().set('X-Skip-Loading-Interceptor', 'true') }
        );
    }
}
