<mat-dialog-content class="!p-4">
    <h2 class="!mb-4">Create new workspace</h2>
    <form [formGroup]="form" (ngSubmit)="save()">
        <label for="company-name" class="small-input-label" data-name="company-name">{{ 'companies.edit.company.name' | translate }}</label>
        <input
            class="small-input"
            type="text"
            id="company-name"
            name="company-name"
            autocomplete="off"
            [formControl]="companyNameControl" />
        <p
            class="small-input-error"
            *ngIf="companyNameControl.touched && companyNameControl.invalid && companyNameControl.hasError('required')">
            {{ 'v2.company.onboarding.company.name.error.required' | translate }}
        </p>
        <div class="flex justify-end gap-1">
            <button class="primary-button button-large" data-name="save" type="submit">
                <mat-icon>save</mat-icon>
                {{ 'general.save' | translate }}
            </button>
            <button class="secondary-button button-large" data-name="cancel" (click)="close()" type="button">
                <mat-icon>cancel</mat-icon>
                {{ 'general.cancel' | translate }}
            </button>
        </div>
    </form>
</mat-dialog-content>
