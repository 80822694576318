<label for="types" class="small-input-label">{{ 'v2.data-format.attribute.dataclassification' | translate }}</label>
<select id="types" class="small-input" (input)="this.change.emit()" [(ngModel)]="this.attribute.dataClassification">
    <option value="SECRET">{{ 'v2.data-format.attribute.dataclassifications.secret' | translate }}</option>
    <option value="MEDICAL">{{ 'v2.data-format.attribute.dataclassifications.medical' | translate }}</option>
    <option value="CONFIDENTIAL">{{ 'v2.data-format.attribute.dataclassifications.confidential' | translate }}</option>
    <option value="RESTRICTED">{{ 'v2.data-format.attribute.dataclassifications.restricted' | translate }}</option>
    <option value="UNRESTRICTED">{{ 'v2.data-format.attribute.dataclassifications.unrestricted' | translate }}</option>
    <option value="PUBLIC">{{ 'v2.data-format.attribute.dataclassifications.public' | translate }}</option>
</select>

<div class="property__properties">
    <div class="flex gap-1 justify-end mb-2">
        <h2>Properties</h2>
        <button class="primary-button button-medium" type="button" (click)="onAddPropertyClicked()">
            <mat-icon>add_circle</mat-icon>
            {{ 'v2.data-format.edit.add.property' | translate }}
        </button>
    </div>
    <div
        [attr.id]="'accordion-data-format-attributes-' + this.attribute.id"
        data-accordion="collapse"
        data-active-classes="none"
        data-inactive-classes="none"
        *ngIf="this.attribute?.attributes"
        cdkDropList
        [cdkDropListData]="this.attribute?.attributes"
        (cdkDropListDropped)="reorderAttributes($event)">
        <ng-container *ngFor="let attribute of this.attribute?.attributes; let i = index; trackBy: identifyAttribute">
            <h3
                cdkDrag
                [attr.id]="'accordion-open-heading-' + this.attribute.id"
                [attr.data-name]="'header-' + this.attribute.id"
                class="p-0">
                <button
                    type="button"
                    class="accordion-heading"
                    [class.accordion-heading-first]="i === 0"
                    [class.accordion-heading-last]="i === this.attribute?.attributes?.length - 1"
                    [attr.data-accordion-target]="'#accordion-open-body-' + attribute.id"
                    [attr.aria-controls]="'accordion-open-body-' + attribute.id"
                    aria-expanded="true">
                    <span class="accordion-heading-icon">
                        {{ attribute.name }} ({{ 'v2.types.' + attribute.noCodeXType | translate }})</span
                    >
                    <button cdkDragHandle class="accordion-heading-button" [matTooltip]="'general.drag-n-drop-tooltip' | translate">
                        <mat-icon>import_export</mat-icon>
                    </button>
                    <div *cdkDragPreview class="border bg-primarylight border-primary w-[100%] !h-9 rounded"></div>
                    <button
                        type="button"
                        class="accordion-heading-button destructive-accordion-heading-button"
                        (click)="onDeleteAttribute(attribute)"
                        [matTooltip]="'Remove attribute'">
                        <mat-icon>clear</mat-icon>
                    </button>
                    <svg
                        data-accordion-icon
                        class="w-3 h-3 rotate-180 shrink-0"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 10 6">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
                    </svg>
                </button>
            </h3>
            <codex-json-schema-property
                [index]="i"
                [attributeAmount]="attribute?.attributes?.length"
                [attribute]="attribute"
                (propertyChanged)="onAttributeChange($event)">
            </codex-json-schema-property>
        </ng-container>
    </div>
</div>
