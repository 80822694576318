import { ChangeDetectionStrategy, Component, HostListener, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { filter, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { backofficeEnvironment, BackofficeEnvironmentDto } from '@shared/environment';
import { MatDialog as MatDialog, MatDialogRef as MatDialogRef } from '@angular/material/dialog';
import { CodexInfoComponent } from '../../components/codex-info/codex-info.component';
import { ApplicationDto } from '../../../v2-application/dto/application.dto';
import { LoggerService, SideNavLink } from '@backoffice/utils';
import { AppFacade } from '@core/facades/app.facade';
import { UserContext } from '@shared/interfaces/user-context.interface';
import { CompanyDto } from '@shared/interfaces/company.dto';
import { AddCompanyDialogComponent } from '@add-company/components/add-company/add-company-dialog.component';
import { CreateCompanyDto } from '@shared/interfaces/create-company.dto';
import { KeycloakService } from 'keycloak-angular';
import { CompanyOnboardingComponent } from '@add-company/components/company-onboarding/company-onboarding.component';
import { ChoosePackageComponent } from '@add-company/components/choose-package/choose-package.component';
import { SearchActionDialogComponent } from '../../../../../../../libs/backoffice/ui/editor/src/lib/dialogs/search-action-dialog/search-action-dialog.component';
import { SearchApiDialogComponent } from '../../../../../../../libs/backoffice/ui/editor/src/lib/dialogs/search-api-dialog/search-api-dialog.component';
import { SearchDataDialogComponent } from '../../../../../../../libs/backoffice/ui/editor/src/lib/dialogs/search-data-dialog/search-data-dialog.component';
import { SearchDataformatDialogComponent } from '../../../../../../../libs/backoffice/ui/editor/src/lib/dialogs/search-dataformat-dialog/search-dataformat-dialog.component';
import { SearchMediaDialogComponent } from '../../../../../../../libs/backoffice/ui/editor/src/lib/dialogs/search-media-dialog/search-media-dialog.component';
import { SearchTemplateDialogComponent } from '../../../../../../../libs/backoffice/ui/editor/src/lib/dialogs/search-template-dialog/search-template-dialog.component';
import { SearchJobDialogComponent } from '../../../../../../../libs/backoffice/ui/editor/src/lib/dialogs/search-job-dialog/search-job-dialog.component';
import {
    ActionEditorFacade,
    ApiEditorFacade,
    DataEditorFacade,
    DataFormatEditorFacade,
    DesignSystemEditorFacade,
    EditorFacade,
    JobEditorFacade,
    MediaEditorFacade,
    TemplateEditorFacade,
} from '@backoffice/data-access/editor';
import { DataCreateFormComponent } from '../../../../../../../libs/backoffice/ui/editor/src/lib/components/data-create-form/data-create-form.component';
import { TemplateTypeAndLanguageComponent } from '../../../../../../../libs/backoffice/ui/editor/src/lib/template/editor/language/template-type-and-language/template-type-and-language.component';
import { MediaCreateFormComponent } from '../../../../../../../libs/backoffice/ui/editor/src/lib/components/media-create-form/media-create-form.component';
import { PluginOverviewPageComponent } from 'backoffice/feature/plugins';
import { ApplicationLogsPage } from '../../../../../../../libs/backoffice/feature/application-logs/src/lib/pages/application-logs/application-logs.page';
import { AuditLogsPage } from '../../../../../../../libs/backoffice/feature/audit-logs/src/lib/pages/audit-logs/audit-logs.page';
import { ManageUsersPageComponent } from '../../../../../../../libs/backoffice/feature/company/users/src/lib/components/manage-users-page/manage-users-page.component';
import { DOCUMENT } from '@angular/common';
import { CompanyEditComponent } from '../../../../../../../libs/backoffice/feature/company/edit-company/src/lib/page/my-company-edit.component';
import { ApplicationVersionPageComponent } from '../../../../../../../libs/backoffice/feature/application/application-version/src/lib/pages/application-version/application-version-page.component';
import { MarketplaceWrapperComponent } from '../../../../../../../libs/backoffice/feature/company/marketplace/src/lib/pages/marketplace-wrapper/marketplace-wrapper.component';
import { SearchDesignsystemDialogComponent } from '../../../../../../../libs/backoffice/ui/editor/src/lib/dialogs/search-designsystem-dialog/search-designsystem-dialog.component';
import { SearchRightDialogComponent } from '../../../../../../../libs/backoffice/ui/editor/src/lib/right/dialogs/search-right-dialog/search-right-dialog.component';
import { SearchGroupDialogComponent } from '../../../../../../../libs/backoffice/ui/editor/src/lib/group/dialogs/search-group-dialog/search-group-dialog.component';
import { GroupEditorFacade } from '../../../../../../../libs/backoffice/data-access/editor/src/lib/group/store/facades/group-editor.facade';
import { RightEditorFacade } from '../../../../../../../libs/backoffice/data-access/editor/src/lib/right/store/facades/right-editor.facade';
import { ApiCreateFormComponent } from '../../../../../../../libs/backoffice/ui/editor/src/lib/api/api-create-form/api-create-form.component';
import { DataformatCreateFormComponent } from '../../../../../../../libs/backoffice/ui/editor/src/lib/dataformat/dataformat-create-form/dataformat-create-form.component';
import { ActionCreateFormComponent } from '../../../../../../../libs/backoffice/ui/editor/src/lib/action/components/action-create-form/action-create-form.component';
import { NgxFloatUiPlacements, NgxFloatUiTriggers } from 'ngx-float-ui';
import { GenerativeComponent } from '../../../../../../../libs/backoffice/ui/editor/src/lib/generative/generative.component';

@Component({
    selector: 'authenticated',
    styleUrls: ['authenticated.component.scss'],
    templateUrl: 'authenticated.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class AuthenticatedComponent implements OnInit, OnDestroy {
    subscriptions: Subscription = new Subscription();

    links: SideNavLink[] = backofficeEnvironment.sideNav;

    environment: BackofficeEnvironmentDto = backofficeEnvironment;

    codexInfoDialogRef: MatDialogRef<CodexInfoComponent>;

    user$ = this.appFacade.user;
    userRights$ = this.appFacade.userRights;
    editorNavigationRights$ = this.appFacade.selectEditorNavigationRights;
    companies$ = this.appFacade.companies;
    selectedCompany$ = this.appFacade.selectedCompany;
    recentCompanies$ = this.appFacade.recentCompanies;
    applications$ = this.appFacade.applications;
    selectedApplication$ = this.appFacade.selectedApplication;
    recentApplications$ = this.appFacade.recentApplications;

    handleShortKeys$ = new Subject<'api' | 'template' | 'designsystem' | 'data' | 'data-format' | 'media' | 'action' | 'job'>();

    searchOpened: boolean = false;

    @HostListener('document:keydown', ['$event'])
    handleFastKeysForConsole(event: KeyboardEvent) {
        if (event.altKey && !this.searchOpened) {
            event.preventDefault();
            switch (event.key) {
                case 't':
                    this.handleShortKeys$.next('template');
                    break;
                case 'a':
                    this.handleShortKeys$.next('api');
                    break;
                case 'd':
                    this.handleShortKeys$.next('data');
                    break;
                case 'f':
                    this.handleShortKeys$.next('data-format');
                    break;
                case 'm':
                    this.handleShortKeys$.next('media');
                    break;
                case 'l':
                    this.handleShortKeys$.next('action');
                    break;
                case 'j':
                    this.handleShortKeys$.next('job');
                    break;
                case 's':
                    this.handleShortKeys$.next('designsystem');
                    break;
            }
        }
    }

    protected readonly backofficeEnvironment = backofficeEnvironment;

    constructor(
        public router: Router,
        @Inject(DOCUMENT) private document: Document,
        private log: LoggerService,
        private dialog: MatDialog,
        private keycloak: KeycloakService,
        private readonly appFacade: AppFacade,
        private readonly editorFacade: EditorFacade,
        private readonly apiFacade: ApiEditorFacade,
        private readonly actionFacade: ActionEditorFacade,
        private readonly dataFacade: DataEditorFacade,
        private readonly dataFormatFacade: DataFormatEditorFacade,
        private readonly designsystemfacade: DesignSystemEditorFacade,
        private readonly mediaFacade: MediaEditorFacade,
        private readonly templateFacade: TemplateEditorFacade,
        private readonly jobFacade: JobEditorFacade,
        private readonly groupFacade: GroupEditorFacade,
        private readonly rightFacade: RightEditorFacade
    ) {}

    1;

    ngOnDestroy() {
        if (this.subscriptions) {
            this.subscriptions.unsubscribe();
        }
    }

    ngOnInit() {
        this.user$ = this.appFacade.user;
        this.companies$ = this.appFacade.companies;
        this.applications$ = this.appFacade.applications;
        this.selectedApplication$ = this.appFacade.selectedApplication;
        this.subscriptions.add(this.initialiseLanguageObservable().subscribe());
        this.subscriptions.add(this.companyOnBoardingFlow$().subscribe());
    }

    private initialiseLanguageObservable(): Observable<UserContext> {
        return this.user$.pipe(
            filter(user => !!user),
            tap(user => {
                // ToDo: enkel engels momenteel
                this.log.info('user changed so setting up the language');
                // this.translateService.use(user.language);
            })
        );
    }

    onApplicationChange(newApplication: ApplicationDto) {
        const { companyId, id } = newApplication;
        this.selectedApplication$
            .pipe(
                take(1),
                tap(selectedApplication => {
                    if (id !== selectedApplication.id) {
                        this.router.navigate([`/company/${companyId}/application/${id}`]);
                    }
                })
            )
            .subscribe();
    }

    onSelectCompany(companyDto: CompanyDto) {
        const { id } = companyDto;
        this.selectedCompany$
            .pipe(
                take(1),
                tap(selectedCompany => {
                    if (id !== selectedCompany.id) {
                        this.router.navigate([`/company/${id}`]);
                    }
                })
            )
            .subscribe();
    }

    onCreateCompany() {
        this.appFacade.user.pipe(switchMap(user => this.createCompanyFlow$(user))).subscribe();
    }

    onCreateApplicationClicked(): void {
        this.appFacade.createApplication();
    }

    onEditApplicationClicked(applicationId: string): void {
        this.appFacade.openEditApplicationDialog(applicationId);
    }

    onClickApplicationVersion(application: ApplicationDto) {
        const applicationVersionDialogRef = this.dialog.open(
            ApplicationVersionPageComponent,
            Object.assign(
                {
                    data: {
                        application,
                    },
                    minHeight: '400px',
                },
                backofficeEnvironment.dialogConfig.big
            )
        );
        // return dialogRef.afterClosed();
    }

    onCodexInfo(): void {
        this.codexInfoDialogRef = this.dialog.open(CodexInfoComponent, backofficeEnvironment.dialogConfig.small);
    }

    private handleDialogClose(result: { action: 'open' | 'create' | 'delete'; content: any }): void {
        const { action } = result;
        const { content } = result;
        if (action === 'open') {
            const { type, typeId, name } = content;
            this.editorFacade.registerTab({ type, name, typeId });
        } else if (action === 'create') {
            this.handleCreate(content);
        }
    }

    private handleCreate(content: any): void {
        const { type } = content;
        switch (type) {
            case 'action':
                this.actionFacade.create(ActionCreateFormComponent).subscribe();
                break;
            case 'api':
                this.apiFacade.create(ApiCreateFormComponent).subscribe();
                break;
            case 'data':
                this.dataFacade.create(DataCreateFormComponent).subscribe();
                break;
            case 'data-format':
                this.dataFormatFacade.create(DataformatCreateFormComponent).subscribe();
                break;
            case 'designsystem':
                this.designsystemfacade.create().subscribe();
                break;
            case 'template':
                this.templateFacade.create(TemplateTypeAndLanguageComponent).subscribe();
                break;
            case 'media':
                this.mediaFacade.create(MediaCreateFormComponent).subscribe();
                break;
            case 'job':
                this.jobFacade.create().subscribe();
                break;
            case 'group':
                this.groupFacade.create().subscribe();
                break;
            case 'right':
                this.rightFacade.create().subscribe();
                break;
        }
    }

    private createCompanyFlow$(user: UserContext): Observable<any> {
        return of(user).pipe(
            filter(value => !!value),
            switchMap(({ id }) =>
                this.dialog
                    .open(
                        AddCompanyDialogComponent,
                        Object.assign(
                            {
                                data: {
                                    userId: id,
                                },
                            },
                            backofficeEnvironment.dialogConfig.normal
                        )
                    )
                    .afterClosed()
            ),
            filter(response => !!response),
            switchMap((toCreate: CreateCompanyDto) => this.appFacade.createCompany(toCreate)),
            switchMap(company => this.choosePackageFlow$(company))
        );
    }

    private choosePackageFlow$(company: CompanyDto): Observable<any> {
        return this.dialog
            .open(
                ChoosePackageComponent,
                Object.assign(
                    {
                        disableClose: true,
                        data: {
                            company,
                            showFree: true,
                        },
                    },
                    backofficeEnvironment.dialogConfig.normal
                )
            )
            .afterClosed()
            .pipe(tap(result => this.appFacade.selectCompany(company.id)));
    }

    private companyOnBoardingFlow$(): Observable<any> {
        return this.appFacade.companies.pipe(
            filter(companies => companies && companies.length === 0),
            switchMap(() =>
                this.dialog
                    .open(CompanyOnboardingComponent, Object.assign({ disableClose: true }, backofficeEnvironment.dialogConfig.big))
                    .afterClosed()
            ),
            filter(response => !!response),
            withLatestFrom(this.appFacade.user),
            switchMap(([toCreate, user]) => this.appFacade.createCompany(Object.assign(toCreate, { userId: user.id }))),
            switchMap(company => this.choosePackageFlow$(company))
        );
    }

    onLogout() {
        this.keycloak.logout(`${window.location.protocol}//${window.location.host}`);
    }

    goToUrl(url: string): void {
        this.document.location.href = url;
    }

    onOpenPreview() {}

    onOpenDialog(
        type:
            | 'api'
            | 'template'
            | 'designsystem'
            | 'data'
            | 'data-format'
            | 'media'
            | 'action'
            | 'job'
            | 'logs'
            | 'users'
            | 'groups'
            | 'rights'
            | 'plugins'
            | 'audit-logs'
            | 'company'
            | 'hub'
            | 'generative'
    ): void {
        let ref: MatDialogRef<any>;
        if (type === 'action') {
            ref = this.dialog.open(SearchActionDialogComponent, { minWidth: '80vw', maxHeight: '95vh' });
        } else if (type === 'api') {
            ref = this.dialog.open(SearchApiDialogComponent, { minWidth: '80vw', maxHeight: '95vh' });
        } else if (type === 'data') {
            ref = this.dialog.open(SearchDataDialogComponent, { minWidth: '80vw', maxHeight: '95vh' });
        } else if (type === 'data-format') {
            ref = this.dialog.open(SearchDataformatDialogComponent, { minWidth: '80vw', maxHeight: '95vh' });
        } else if (type === 'media') {
            ref = this.dialog.open(SearchMediaDialogComponent, { minWidth: '80vw', maxHeight: '95vh' });
        } else if (type === 'template') {
            ref = this.dialog.open(SearchTemplateDialogComponent, { minWidth: '80vw', maxHeight: '95vh' });
        } else if (type === 'designsystem') {
            ref = this.dialog.open(SearchDesignsystemDialogComponent, { minWidth: '80vw', maxHeight: '95vh' });
        } else if (type === 'job') {
            ref = this.dialog.open(SearchJobDialogComponent, { minWidth: '80vw', maxHeight: '95vh' });
        } else if (type === 'logs') {
            ref = this.dialog.open(ApplicationLogsPage, { minWidth: '80vw', maxHeight: '95vh' });
        } else if (type === 'audit-logs') {
            ref = this.dialog.open(AuditLogsPage, { minWidth: '80vw', maxHeight: '95vh' });
        } else if (type === 'users') {
            ref = this.dialog.open(ManageUsersPageComponent, { minWidth: '80vw', maxHeight: '95vh' });
        } else if (type === 'groups') {
            ref = this.dialog.open(SearchGroupDialogComponent, { minWidth: '80vw', maxHeight: '95vh' });
        } else if (type === 'rights') {
            ref = this.dialog.open(SearchRightDialogComponent, { minWidth: '80vw', maxHeight: '95vh' });
        } else if (type === 'plugins') {
            ref = this.dialog.open(PluginOverviewPageComponent, { minWidth: '80vw', maxHeight: '95vh' });
        } else if (type === 'company') {
            ref = this.dialog.open(CompanyEditComponent, { minWidth: '80vw', maxHeight: '95vh' });
        } else if (type === 'hub') {
            ref = this.dialog.open(MarketplaceWrapperComponent, { minWidth: '80vw', maxHeight: '95vh' });
        } else if (type === 'generative') {
            ref = this.dialog.open(GenerativeComponent, { minWidth: '80vw', maxHeight: '95vh' });
        }
        ref.afterOpened()
            .pipe(take(1))
            .subscribe(() => {
                this.searchOpened = true;
            });

        ref.afterClosed()
            .pipe(take(1))
            .subscribe(result => {
                this.searchOpened = false;
                if (!!result) {
                    this.handleDialogClose(result);
                }
            });
    }

    protected readonly NgxFloatUiPlacements = NgxFloatUiPlacements;
    protected readonly NgxFloatUiTriggers = NgxFloatUiTriggers;
}
