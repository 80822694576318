<form *ngIf="media && formGroup" [formGroup]="formGroup">
    <div class="general-information-container">
        <div class="name-and-description-container">
            <label for="media-name" class="small-input-label" data-name="media-name">{{ 'v2.media.edit.title' | translate }}</label>
            <input class="small-input" type="text" id="media-name" name="media-name" autocomplete="off" formControlName="title" required />
            <label for="media-alt" class="small-input-label" data-name="media-name">{{ 'v2.media.edit.alt' | translate }}</label>
            <textarea class="small-input" type="text" id="media-alt" name="media-alt" autocomplete="off" formControlName="alt"></textarea>
            <codex-tag-input [applicationId]="media.applicationId" [companyId]="media.companyId" [linkedEntityId]="media.id" type="DATA">
            </codex-tag-input>
        </div>
        <div class="media-preview-container">
            <ng-container
                *ngIf="
                    media.type === 'image/png' ||
                        media.type === 'image/jpeg' ||
                        media.type === 'image/svg+xml' ||
                        media.type === 'image/gif' ||
                        media.type === 'image/webp';
                    else noPreview
                ">
                <img
                    [src]="
                        environment.rest.v2.media +
                        'file/company/' +
                        media.companyId +
                        '/application/' +
                        media.applicationId +
                        '/' +
                        media.id
                    "
                    width="350" />
            </ng-container>
            <ng-template #noPreview>
                <img
                    class="p-[116px] cursor-pointer"
                    (click)="onDownload()"
                    *ngIf="media.extension === 'mp4'"
                    src="/src/images/theme/mp4.png" />
                <img
                    class="p-[116px] cursor-pointer"
                    (click)="onDownload()"
                    *ngIf="media.extension === 'mp3'"
                    src="/src/images/theme/mp3.png" />
                <img
                    class="p-[116px] cursor-pointer"
                    (click)="onDownload()"
                    *ngIf="media.extension === 'pdf'"
                    src="/src/images/theme/pdf.png" />
                <img
                    class="p-[116px] cursor-pointer"
                    (click)="onDownload()"
                    *ngIf="media.extension === 'word'"
                    src="/src/images/theme/doc.png" />
                <img
                    class="p-[116px] cursor-pointer"
                    (click)="onDownload()"
                    *ngIf="media.extension === 'excel'"
                    src="/src/images/theme/xls.png" />
                <img
                    class="p-[116px] cursor-pointer"
                    (click)="onDownload()"
                    *ngIf="media.extension === 'csv'"
                    src="/src/images/theme/csv.png" />
                <img
                    class="p-[116px] cursor-pointer"
                    (click)="onDownload()"
                    *ngIf="media.extension === 'zip'"
                    src="/src/images/theme/zip.png" />
                <img
                    class="p-[116px] cursor-pointer"
                    (click)="onDownload()"
                    *ngIf="media.extension === 'xml'"
                    src="/src/images/theme/xml.png" />
                <img
                    class="p-[116px] cursor-pointer"
                    (click)="onDownload()"
                    *ngIf="media.extension === 'json'"
                    src="/src/images/theme/json.png" />
                <img
                    class="p-[116px] cursor-pointer"
                    (click)="onDownload()"
                    *ngIf="media.extension === 'text'"
                    src="/src/images/theme/txt.png" />
                <img
                    class="p-[116px] cursor-pointer"
                    (click)="onDownload()"
                    *ngIf="media.extension === 'md'"
                    src="/src/images/theme/md.png" />
            </ng-template>
        </div>
    </div>
    <hr class="seperator" />
    <div class="flex justify-between">
        <div>
            <div class="flex items-center gap-1">
                <div class="text-xxs font-bold">Id:&nbsp;</div>
                <div class="text-xxs flex">{{ media.id }}</div>
                <button class="secondary-button button-extra-small" [cdkCopyToClipboard]="media.id" [matTooltip]="'Copy ID'" type="button">
                    <mat-icon>content_copy</mat-icon>
                </button>
                <button
                    class="secondary-button button-extra-small"
                    (click)="onDownload()"
                    [matTooltip]="'v2.media.edit.download' | translate"
                    type="button">
                    <mat-icon>download</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <hr class="seperator" />
    <label class="toggle-line">
        {{ 'v2.media.edit.installFromHub' | translate }}
        <input type="checkbox" value="" class="sr-only peer" formControlName="installFromHub" />
        <div
            class="toggle peer-focus:outline-none peer-checked:bg-dark-button-background peer-checked:after:translate-x-full peer rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white"></div>
    </label>
    <div class="buttonContainer">
        <button class="primary-button button-medium" color="primary" data-name="save" (click)="onUpdate()">
            <mat-icon>save</mat-icon>
            {{ 'general.save' | translate }}
        </button>
    </div>
</form>
