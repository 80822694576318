<mat-dialog-content>
    <div class="p-2">
        <h1>New property</h1>
        <div class="mb-2">
            <label for="attribute-name" class="small-input-label">Name</label>
            <input
                class="small-input"
                type="text"
                id="attribute-name"
                cd-code
                name="attribute-name"
                class="small-input"
                autocomplete="off"
                [formControl]="nameControl" />
            <label for="types" class="small-input-label">Type</label>
            <select id="types" class="small-input" [formControl]="typeControl">
                <ng-container *ngFor="let type of backofficeEnvironment.types">
                    <option *ngIf="type.dataformat" [value]="type.name">{{ 'v2.types.' + type.name | translate }}</option>
                </ng-container>
            </select>
        </div>
        <div class="flex justify-end gap-1">
            <button class="primary-button button-large" type="button" (click)="onAddClicked()">
                <mat-icon>add_circle</mat-icon>
                {{ 'general.ok' | translate }}
            </button>

            <button class="secondary-button button-large" type="button" (click)="onCancelClick()">
                <mat-icon>cancel</mat-icon>
                {{ 'general.cancel' | translate }}
            </button>
        </div>
    </div>
</mat-dialog-content>
