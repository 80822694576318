<mat-dialog-content class="!p-4">
    <h1 class="!mb-2">{{ 'v2.company.users.invite.title' | translate }}</h1>
    <form [formGroup]="form">
        <label for="invitee-email" class="small-input-label" data-name="invitee-name">{{
            'v2.company.users.invite.email' | translate
        }}</label>
        <input class="small-input" type="text" id="invitee-email" name="invitee-name" autocomplete="off" formControlName="mail" required />
    </form>
    <div class="flex justify-end gap-1">
        <button class="primary-button button-large" [mat-dialog-close]="mailControl.value" [disabled]="!form.valid">
            <mat-icon>send</mat-icon>
            {{ 'v2.company.users.invite.sendinvitation' | translate }}
        </button>
        <button class="secondary-button button-large" (click)="onNoClick()">
            <mat-icon>cancel</mat-icon>
            {{ 'general.cancel' | translate }}
        </button>
    </div>
</mat-dialog-content>
