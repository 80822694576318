<ng-container *ngFor="let output of outputs">
    <div
        *ngIf="output.type === 'STRING' || output.type === 'NUMBER' || output.type === 'BOOLEAN' || output.type === 'DATE'"
        class="small text-white flex gap-1 items-center">
        <div *ngIf="hasAssertion(output) && isValid(output)">
            <svg
                floatUi="Assertion succeeded"
                [applyClass]="'tooltip'"
                appendTo="body"
                [showTrigger]="NgxFloatUiTriggers.hover"
                data-tooltip-target="success-tooltip"
                class="icon icon-button"
                aria-hidden="true"
                focusable="false">
                <use href="#check-success" class="ui-element !stroke-dark-success"></use>
            </svg>
        </div>
        <div *ngIf="hasAssertion(output) && isInvalid(output)">
            <svg
                [floatUi]="getMessage(output)"
                [applyClass]="'tooltip'"
                appendTo="body"
                [showTrigger]="NgxFloatUiTriggers.hover"
                class="icon icon-button"
                aria-hidden="true"
                focusable="false">
                <use href="#check-failed" class="ui-element !stroke-dark-destructive_button-background"></use>
            </svg>
        </div>
        <div *ngIf="hasAssertion(output)">
            <svg
                floatUi="Edit assertion"
                [applyClass]="'tooltip'"
                appendTo="body"
                [showTrigger]="NgxFloatUiTriggers.hover"
                class="icon icon-button"
                aria-hidden="true"
                focusable="false"
                (click)="onEditAssertion(output)">
                <use href="#edit" class="ui-element"></use>
            </svg>
        </div>
        <div *ngIf="hasAssertion(output)">
            <svg
                floatUi="Remove assertion"
                [applyClass]="'tooltip'"
                appendTo="body"
                [showTrigger]="NgxFloatUiTriggers.hover"
                class="icon icon-button"
                aria-hidden="true"
                focusable="false"
                (click)="onRemoveAssertion(output)">
                <use href="#remove" class="ui-element"></use>
            </svg>
        </div>
        <div *ngIf="!hasAssertion(output)">
            <svg
                floatUi="Create assertion from this output"
                [applyClass]="'tooltip'"
                appendTo="body"
                [showTrigger]="NgxFloatUiTriggers.hover"
                (click)="addOutputAsAssertion.emit(output)"
                class="icon icon-button"
                aria-hidden="true"
                focusable="false">
                <use href="#check-add" class="ui-element"></use>
            </svg>
        </div>
        <span class="font-bold">{{ output.name }}</span>
        <span>({{ output.type }})</span>: <span>{{ output.value }}</span>
    </div>
    <div *ngIf="output.type === 'USER'" class="small text-white">
        <div class="flex gap-1 items-center mb-2">
            <div *ngIf="hasAssertion(output) && isValid(output)">
                <svg
                    floatUi="Assertion succeeded"
                    [applyClass]="'tooltip'"
                    appendTo="body"
                    [showTrigger]="NgxFloatUiTriggers.hover"
                    data-tooltip-target="success-tooltip"
                    class="icon icon-button"
                    aria-hidden="true"
                    focusable="false">
                    <use href="#check-success" class="ui-element !stroke-dark-success"></use>
                </svg>
            </div>
            <div *ngIf="hasAssertion(output) && isInvalid(output)">
                <svg
                    [floatUi]="getMessage(output)"
                    [applyClass]="'tooltip'"
                    appendTo="body"
                    [showTrigger]="NgxFloatUiTriggers.hover"
                    class="icon icon-button"
                    aria-hidden="true"
                    focusable="false">
                    <use href="#check-failed" class="ui-element !stroke-dark-destructive_button-background"></use>
                </svg>
            </div>
            <div *ngIf="hasAssertion(output)">
                <svg
                    floatUi="Edit assertion"
                    [applyClass]="'tooltip'"
                    appendTo="body"
                    [showTrigger]="NgxFloatUiTriggers.hover"
                    class="icon icon-button"
                    aria-hidden="true"
                    focusable="false"
                    (click)="onEditAssertion(output)">
                    <use href="#edit" class="ui-element"></use>
                </svg>
            </div>
            <div *ngIf="hasAssertion(output)">
                <svg
                    floatUi="Remove assertion"
                    [applyClass]="'tooltip'"
                    appendTo="body"
                    [showTrigger]="NgxFloatUiTriggers.hover"
                    class="icon icon-button"
                    aria-hidden="true"
                    focusable="false"
                    (click)="onRemoveAssertion(output)">
                    <use href="#remove" class="ui-element"></use>
                </svg>
            </div>
            <div *ngIf="!hasAssertion(output)">
                <svg
                    floatUi="Create assertion from this output"
                    [applyClass]="'tooltip'"
                    appendTo="body"
                    [showTrigger]="NgxFloatUiTriggers.hover"
                    (click)="addOutputAsAssertion.emit(output)"
                    class="icon icon-button"
                    aria-hidden="true"
                    focusable="false">
                    <use href="#check-add" class="ui-element"></use>
                </svg>
            </div>
            <span class="font-bold">{{ output.name }}</span>
            <span>({{ output.type }})</span>
        </div>
        <div>
            <ngx-monaco-editor
                [options]="editorOptions"
                [ngModel]="JSON.stringify(output.value)"
                [ngModelOptions]="{ standalone: true }"
                (onInit)="initiateMonaco($event)">
            </ngx-monaco-editor>
        </div>
    </div>
    <div *ngIf="output.type === 'DATA'" class="small text-white">
        <div class="flex gap-1 items-center mb-2" *ngIf="false">
            <div *ngIf="hasAssertion(output) && isValid(output)">
                <svg
                    floatUi="Assertion succeeded"
                    [applyClass]="'tooltip'"
                    appendTo="body"
                    [showTrigger]="NgxFloatUiTriggers.hover"
                    class="icon icon-button"
                    aria-hidden="true"
                    focusable="false">
                    <use href="#check-success" class="ui-element !stroke-dark-success"></use>
                </svg>
            </div>
            <div *ngIf="hasAssertion(output) && isInvalid(output)">
                <svg
                    floatUi="Assertion failed"
                    [applyClass]="'tooltip'"
                    appendTo="body"
                    [showTrigger]="NgxFloatUiTriggers.hover"
                    class="icon icon-button"
                    aria-hidden="true"
                    focusable="false">
                    <use href="#check-failed" class="ui-element !stroke-dark-destructive_button-background"></use>
                </svg>
            </div>
            <div *ngIf="hasAssertion(output)">
                <svg
                    floatUi="Edit assertion"
                    [applyClass]="'tooltip'"
                    appendTo="body"
                    [showTrigger]="NgxFloatUiTriggers.hover"
                    class="icon icon-button"
                    aria-hidden="true"
                    focusable="false"
                    (click)="onEditAssertion(output)">
                    <use href="#edit" class="ui-element"></use>
                </svg>
            </div>
            <div *ngIf="hasAssertion(output)">
                <svg
                    floatUi="Remove assertion"
                    [applyClass]="'tooltip'"
                    appendTo="body"
                    [showTrigger]="NgxFloatUiTriggers.hover"
                    class="icon icon-button"
                    aria-hidden="true"
                    focusable="false"
                    (click)="onRemoveAssertion(output)">
                    <use href="#remove" class="ui-element"></use>
                </svg>
            </div>
            <div *ngIf="!hasAssertion(output)">
                <svg
                    floatUi="Create assertion from this output"
                    [applyClass]="'tooltip'"
                    appendTo="body"
                    [showTrigger]="NgxFloatUiTriggers.hover"
                    (click)="addOutputAsAssertion.emit(output)"
                    class="icon icon-button"
                    aria-hidden="true"
                    focusable="false">
                    <use href="#check-add" class="ui-element"></use>
                </svg>
            </div>
            <span class="font-bold">{{ output.name }}</span
            ><span>({{ output.type }})</span>:
        </div>
        <div>
            <ngx-monaco-editor
                [options]="editorOptions"
                [ngModel]="output.value.body | json"
                [ngModelOptions]="{ standalone: true }"
                (onInit)="initiateMonaco($event)">
            </ngx-monaco-editor>
        </div>
    </div>
    <div *ngIf="output.type === 'ARRAY' || output.type === 'OBJECT'" class="small text-white">
        <div class="flex gap-1 items-center mb-2">
            <div *ngIf="hasAssertion(output) && isValid(output)">
                <svg
                    floatUi="Assertion succeeded"
                    [applyClass]="'tooltip'"
                    appendTo="body"
                    [showTrigger]="NgxFloatUiTriggers.hover"
                    class="icon icon-button"
                    aria-hidden="true"
                    focusable="false">
                    <use href="#check-success" class="ui-element !stroke-dark-success"></use>
                </svg>
            </div>
            <div *ngIf="hasAssertion(output) && isInvalid(output)">
                <svg
                    [floatUi]="getMessage(output)"
                    [applyClass]="'tooltip'"
                    appendTo="body"
                    [showTrigger]="NgxFloatUiTriggers.hover"
                    class="icon icon-button"
                    aria-hidden="true"
                    focusable="false">
                    <use href="#check-failed" class="ui-element !stroke-dark-destructive_button-background"></use>
                </svg>
            </div>
            <div *ngIf="hasAssertion(output)">
                <svg
                    floatUi="Edit assertion"
                    [applyClass]="'tooltip'"
                    appendTo="body"
                    [showTrigger]="NgxFloatUiTriggers.hover"
                    class="icon icon-button"
                    aria-hidden="true"
                    focusable="false"
                    (click)="onEditAssertion(output)">
                    <use href="#edit" class="ui-element"></use>
                </svg>
            </div>
            <div *ngIf="hasAssertion(output)">
                <svg
                    floatUi="Remove assertion"
                    [applyClass]="'tooltip'"
                    appendTo="body"
                    [showTrigger]="NgxFloatUiTriggers.hover"
                    class="icon icon-button"
                    aria-hidden="true"
                    focusable="false"
                    (click)="onRemoveAssertion(output)">
                    <use href="#remove" class="ui-element"></use>
                </svg>
            </div>
            <div *ngIf="!hasAssertion(output)">
                <svg
                    floatUi="Create assertion from this output"
                    [applyClass]="'tooltip'"
                    appendTo="body"
                    [showTrigger]="NgxFloatUiTriggers.hover"
                    (click)="addOutputAsAssertion.emit(output)"
                    class="icon icon-button"
                    aria-hidden="true"
                    focusable="false">
                    <use href="#check-add" class="ui-element"></use>
                </svg>
            </div>
            <span class="font-bold">{{ output.name }}</span
            ><span>({{ output.type }})</span>
        </div>
        <div>
            <ngx-monaco-editor
                [options]="editorOptions"
                [ngModel]="JSON.stringify(output.value)"
                [ngModelOptions]="{ standalone: true }"
                (onInit)="initiateMonaco($event)">
            </ngx-monaco-editor>
        </div>
    </div>
    <div *ngIf="output.type === 'FILE'" class="small text-white">
        <div class="flex gap-1 items-center">
            <div *ngIf="hasAssertion(output) && isValid(output)">
                <svg
                    floatUi="Assertion succeeded"
                    [applyClass]="'tooltip'"
                    appendTo="body"
                    [showTrigger]="NgxFloatUiTriggers.hover"
                    class="icon icon-button"
                    aria-hidden="true"
                    focusable="false">
                    <use href="#check-success" class="ui-element"></use>
                </svg>
            </div>
            <div *ngIf="hasAssertion(output) && isInvalid(output)">
                <svg
                    floatUi="Assertion failed"
                    [applyClass]="'tooltip'"
                    appendTo="body"
                    [showTrigger]="NgxFloatUiTriggers.hover"
                    class="icon icon-button"
                    aria-hidden="true"
                    focusable="false">
                    <use href="#check-failed" class="ui-element !stroke-dark-destructive_button-background"></use>
                </svg>
            </div>
            <div *ngIf="hasAssertion(output)">
                <svg
                    floatUi="Edit assertion"
                    [applyClass]="'tooltip'"
                    appendTo="body"
                    [showTrigger]="NgxFloatUiTriggers.hover"
                    class="icon icon-button"
                    aria-hidden="true"
                    focusable="false"
                    (click)="onEditAssertion(output)">
                    <use href="#edit" class="ui-element"></use>
                </svg>
            </div>
            <div *ngIf="hasAssertion(output)">
                <svg
                    floatUi="Remove assertion"
                    [applyClass]="'tooltip'"
                    appendTo="body"
                    [showTrigger]="NgxFloatUiTriggers.hover"
                    class="icon icon-button"
                    aria-hidden="true"
                    focusable="false"
                    (click)="onRemoveAssertion(output)">
                    <use href="#remove" class="ui-element"></use>
                </svg>
            </div>
            <div *ngIf="!hasAssertion(output)">
                <svg
                    floatUi="Create assertion from this output"
                    [applyClass]="'tooltip'"
                    appendTo="body"
                    [showTrigger]="NgxFloatUiTriggers.hover"
                    (click)="addOutputAsAssertion.emit(output)"
                    class="icon icon-button"
                    aria-hidden="true"
                    focusable="false">
                    <use href="#check-add" class="ui-element"></use>
                </svg>
            </div>
            <span class="font-bold">{{ output.name }}</span
            ><span>({{ output.type }})</span>
        </div>
        <div>
            <a
                [attr.download]="output.value.name"
                *ngIf="!isImage(output.value)"
                class="font-bold"
                target="_blank"
                [attr.href]="'data:' + output.value.type + ';base64,' + output.value.content"
                >{{ output.value.name }}</a
            >
            <ng-container *ngIf="isImage(output.value)">
                <img
                    [attr.alt]="output.value.name"
                    width="250"
                    [attr.src]="'data:' + output.value.type + ';base64,' + output.value.content" />
            </ng-container>
        </div>
    </div>
</ng-container>
