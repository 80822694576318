<form [formGroup]="form" class="!p-4">
    <h1 class="!mb-2">{{ 'v2.hub.add.title' | translate }}</h1>
    <label for="select-application" class="small-input-label">{{ 'v2.hub.add.labels.application' | translate }}</label>
    <select id="select-application" class="small-input" [formControl]="applicationIdControl">
        <option *ngFor="let application of applications$ | async" [value]="application.id">
            {{ application.name }}
        </option>
    </select>

    <ng-container *ngIf="versions$ | async as versions">
        <label for="select-version" class="small-input-label">{{ 'v2.hub.add.labels.version' | translate }}</label>
        <select id="select-version" class="small-input" [formControl]="versionControl">
            <option *ngFor="let version of versions" [value]="version.id" (click)="onVersionSelected(version)">
                {{ version.name }}
            </option>
        </select>
        <p *ngIf="versions.length <= 0">{{ 'v2.hub.add.errors.no.versions' | translate }}</p>
    </ng-container>
    <hr class="seperator" />
    <ng-container *ngIf="versionControl.value">
        <label for="version-name" class="small-input-label" data-name="version-name">{{ 'v2.hub.add.name.value' | translate }}</label>
        <input
            class="small-input"
            type="text"
            id="version-name"
            name="version-name"
            autocomplete="off"
            required
            [formControl]="nameControl" />

        <label for="version-description" class="small-input-label" data-name="version-description">{{
            'v2.hub.add.description.value' | translate
        }}</label>
        <input
            class="small-input"
            type="text"
            id="version-description"
            name="version-description"
            autocomplete="off"
            required
            [formControl]="descriptionControl" />
        <hr class="seperator" />
        <label class="small-input-label">{{ 'v2.hub.type.title' | translate }}</label>
        <div>
            <mat-radio-group aria-labelledby="hub-product-type-label" class="hub-product-type-group" [formControl]="typeControl">
                <mat-radio-button color="primary" value="PUBLIC">
                    <span class="!text-white">{{ 'v2.hub.type.public' | translate }}</span>
                </mat-radio-button>
                <mat-radio-button color="primary" value="PRIVATE">
                    <span class="!text-white">{{ 'v2.hub.type.private' | translate }}</span>
                </mat-radio-button>
                <mat-radio-button
                    color="primary"
                    value="TEST"
                    *ngIf="backofficeEnvironment.testableCompanies.includes(companyIdControl.value)">
                    <span class="!text-white">{{ 'v2.hub.type.test' | translate }}</span>
                </mat-radio-button>
                <mat-radio-button
                    color="primary"
                    value="AI"
                    *ngIf="backofficeEnvironment.testableCompanies.includes(companyIdControl.value)">
                    <span class="!text-white">{{ 'v2.hub.type.ai' | translate }}</span>
                </mat-radio-button>
            </mat-radio-group>
        </div>
    </ng-container>
    <div class="flex gap-1 justify-end">
        <button class="primary-button button-large" (click)="onUpload()">
            <mat-icon>publish</mat-icon>
            {{ 'v2.hub.publish' | translate }}
        </button>
    </div>
</form>
