<ng-container *ngIf="_action && formGroup" [formGroup]="formGroup">
    <label for="action-name" class="small-input-label" data-name="action-name">{{ 'v2.action.properties.name' | translate }}</label>
    <div class="input-button-container">
        <input
            class="small-input"
            type="text"
            id="action-name"
            name="action-name"
            autocomplete="off"
            [formControl]="formGroup.controls.name" />
        <svg
            *ngIf="false"
            class="icon input-button"
            [showTrigger]="NgxFloatUiTriggers.hover"
            [floatUi]="actionNamePopover"
            appendTo="body"
            [preventOverflow]="false"
            [boundariesElement]="'rootBoundary'"
            [positionFixed]="true"
            [placement]="NgxFloatUiPlacements.RIGHT"
            [appendTo]="'body'">
            <use href="#generative" class="ui-element"></use>
        </svg>
        <float-ui-content #actionNamePopover>
            <div class="popover navigation-info">
                <div class="draggable-card-popover-title-without-text">
                    <h3 class="draggable-card-popover-title-element">Generate name using A.I</h3>
                </div>
            </div>
        </float-ui-content>
    </div>
    <codex-icon-picker class="!mb-2 block" [icon]="_action.iconName" (changeIcon)="onChangeIconName(_action, $event)"></codex-icon-picker>
    <label for="action-description" class="small-input-label" data-name="action-description">{{
        'v2.action.properties.description' | translate
    }}</label>
    <div class="input-button-container">
        <textarea
            class="small-input"
            type="text"
            id="action-description"
            name="action-description"
            autocomplete="off"
            [formControl]="formGroup.controls.description"></textarea>
        <svg
            class="icon input-button"
            [showTrigger]="NgxFloatUiTriggers.hover"
            [floatUi]="actionDescription"
            appendTo="body"
            [preventOverflow]="false"
            [boundariesElement]="'rootBoundary'"
            [positionFixed]="true"
            [placement]="NgxFloatUiPlacements.RIGHT"
            [appendTo]="'body'">
            <use href="#generative" class="ui-element"></use>
        </svg>
        <float-ui-content #actionDescription>
            <div class="popover navigation-info">
                <div class="draggable-card-popover-title-without-text">
                    <h3 class="draggable-card-popover-title-element">Generate description using A.I</h3>
                </div>
            </div>
        </float-ui-content>
    </div>
    <label class="toggle-line">
        {{ 'v2.action.properties.prototype' | translate }}
        <input type="checkbox" value="" class="sr-only peer" [formControl]="formGroup.controls.prototype" />
        <div
            class="toggle peer-focus:outline-none peer-checked:bg-dark-button-background peer-checked:after:translate-x-full peer rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white"></div>
    </label>

    <codex-tag-input
        type="ACTION"
        [linkedEntityId]="_action.id"
        [applicationId]="_action.applicationId"
        [companyId]="_action.companyId"></codex-tag-input>
</ng-container>
