<mat-dialog-content class="h-full !p-4">
    <ng-container *ngIf="user$ | async as user">
        <form [formGroup]="createCompanyForm" (ngSubmit)="onAddCompany()">
            <div class="flex flex-row items-center gap-4 mb-5 text-xs">
                <img width="300" class="grow shrink" src="/images/theme/hooray.gif" />
                <div class="flex flex-col">
                    <h1 class="!mb-4">{{ 'v2.company.onboarding.welcome' | translate: { firstName: user.firstname } }}</h1>
                    <p class="grow shrink text-text_2" [innerHTML]="'v2.company.onboarding.no.companies.found' | translate"></p>
                </div>
            </div>
            <hr class="seperator" />
            <label for="company-name" class="small-input-label" data-name="company-name">{{
                'v2.company.onboarding.company.name.label' | translate
            }}</label>
            <input class="small-input" type="text" id="company-name" name="company-name" autocomplete="off" formControlName="companyName" />
            <p
                class="small-input-error"
                *ngIf="
                    createCompanyForm.get('companyName').touched &&
                    this.createCompanyForm.get('companyName').invalid &&
                    createCompanyForm.get('companyName').hasError('required')
                ">
                {{ 'v2.company.onboarding.company.name.error.required' | translate }}
            </p>
            <button class="primary-button button-large w-full flex gap-1 justify-center" type="submit">
                <mat-icon>add_circle</mat-icon>
                {{ 'v2.company.onboarding.create.company' | translate }}
            </button>
        </form>
    </ng-container>
</mat-dialog-content>
