<div *ngIf="{ job: this.job$ | async } as observables" class="edit-form">
    <form
        *ngIf="!!observables.job && !!formGroup"
        [formGroup]="formGroup"
        (ngSubmit)="onUpdateJob()"
        novalidate
        cdkTrapFocus
        [cdkTrapFocusAutoCapture]="true">
        <div class="general-information-container">
            <div class="name-and-description-container">
                <label for="data-name" class="small-input-label" data-name="data-name">{{ 'v2.job.edit.name' | translate }}</label>
                <input class="small-input" type="text" id="data-name" name="name" autocomplete="off" formControlName="name" required />

                <label for="data-description" class="small-input-label" data-name="data-description">{{
                    'v2.job.edit.description' | translate
                }}</label>
                <textarea class="small-input" id="data-description" formControlName="description" align="end"> </textarea>
            </div>
            <div class="icon-picker-edit-container">
                <codex-icon-picker
                    [icon]="this.formGroup.get('iconName').value"
                    (changeIcon)="onChangeIconName($event)"></codex-icon-picker>
            </div>
        </div>
        <codex-tag-input
            type="JOB"
            [linkedEntityId]="observables.job.id"
            [applicationId]="observables.job.applicationId"
            [companyId]="observables.job.companyId">
        </codex-tag-input>
        <hr class="seperator" />
        <label for="select-frequency" class="small-input-label">{{ 'v2.job.edit.frequency' | translate }}</label>
        <select id="select-frequency" class="small-input" formControlName="frequency">
            <option [value]="'PAUSED'">{{ 'v2.job.edit.frequency.type.paused' | translate }}</option>
            <option [value]="'ADVANCED'">{{ 'v2.job.edit.frequency.type.advanced' | translate }}</option>
            <option [value]="'EVERY_5_MINUTES'">{{ 'v2.job.edit.frequency.type.every.5.minutes' | translate }}</option>
            <option [value]="'EVERY_10_MINUTES'">{{ 'v2.job.edit.frequency.type.every.10.minutes' | translate }}</option>
            <option [value]="'EVERY_30_MINUTES'">{{ 'v2.job.edit.frequency.type.every.30.minutes' | translate }}</option>
            <option [value]="'EVERY_HOUR'">{{ 'v2.job.edit.frequency.type.every.hour' | translate }}</option>
            <option [value]="'EVERY_2_HOURS'">{{ 'v2.job.edit.frequency.type.every.2.hours' | translate }}</option>
            <option [value]="'EVERY_6_HOURS'">{{ 'v2.job.edit.frequency.type.every.6.hours' | translate }}</option>
            <option [value]="'EVERY_12_HOURS'">{{ 'v2.job.edit.frequency.type.every.12.hours' | translate }}</option>
            <option [value]="'EVERY_DAY'">{{ 'v2.job.edit.frequency.type.every.day' | translate }}</option>
            <option [value]="'EVERY_DAY_AT_6'">{{ 'v2.job.edit.frequency.type.every.day.at.6' | translate }}</option>
            <option [value]="'EVERY_DAY_AT_12'">{{ 'v2.job.edit.frequency.type.every.day.at.12' | translate }}</option>
            <option [value]="'EVERY_DAY_AT_24'">{{ 'v2.job.edit.frequency.type.every.day.at.24' | translate }}</option>
            <option [value]="'EVERY_MONTH'">{{ 'v2.job.edit.frequency.type.every.month' | translate }}</option>
            <option [value]="'EVERY_MONTH_AT_DAY_1'">{{ 'v2.job.edit.frequency.type.every.month.at.day.1' | translate }}</option>
            <option [value]="'EVERY_MONTH_AT_DAY_10'">{{ 'v2.job.edit.frequency.type.every.month.at.day.10' | translate }}</option>
            <option [value]="'EVERY_MONTH_AT_DAY_20'">{{ 'v2.job.edit.frequency.type.every.month.at.day.20' | translate }}</option>
            <option [value]="'EVERY_MONTH_AT_THE_LAST_DAY'">
                {{ 'v2.job.edit.frequency.type.every.month.at.last.day' | translate }}
            </option>
            <option [value]="'EVERY_YEAR_AT_DAY_1'">{{ 'v2.job.edit.frequency.type.every.year.at.day.1' | translate }}</option>
            <option [value]="'EVERY_YEAR_AT_MONTH_6_DAY_1'">
                {{ 'v2.job.edit.frequency.type.every.year.at.month.6.day.1' | translate }}
            </option>
        </select>

        <ng-container *ngIf="formGroup.get('frequency').value === 'ADVANCED'">
            <label for="cronstatement" class="small-input-label" data-name="cronstatement">{{
                'v2.job.edit.cronstatement' | translate
            }}</label>
            <input
                class="small-input"
                type="text"
                id="cronstatement"
                name="cronstatement"
                autocomplete="off"
                formControlName="cronStatement" />
        </ng-container>

        <hr class="seperator" />
        <div class="actions mb-2">
            <div
                class="flex mb-1 mt-1 gap-1"
                [showTrigger]="NgxFloatUiTriggers.hover"
                [floatUi]="argumentInformationPopover"
                [appendTo]="'body'"
                [boundariesElement]="'body'"
                [preventOverflow]="false"
                [positionFixed]="true"
                [applyClass]="'!max-w-[250px]'"
                [placement]="NgxFloatUiPlacements.BOTTOMSTART">
                <h4 class="!text-sm !font-bold !mb-1 !mt-1 !w-full flex items-center gap-1">
                    {{ 'v2.job.edit.execution' | translate }}
                    <svg class="icon icon-button" aria-hidden="true" focusable="false">
                        <use href="#help" class="ui-element"></use>
                    </svg>
                </h4>
            </div>

            <float-ui-content #argumentInformationPopover>
                <div class="popover">These actions will be executed every time this job runs.</div>
            </float-ui-content>
            <codex-action-picker [referenceId]="jobId" type="JOB_LOGIC"></codex-action-picker>
        </div>
        <div class="flex justify-end gap-1">
            <button class="primary-button button-large" data-name="save">
                <mat-icon>save</mat-icon>
                {{ 'general.save' | translate }}
            </button>
            <button class="secondary-button button-large" data-name="cancel" (click)="onCancel()">
                <mat-icon>cancel</mat-icon>
                {{ 'general.cancel' | translate }}
            </button>
        </div>
    </form>
</div>
