<div [formGroup]="formGroup" class="absolute p-2 bottom-2 left-1 right-1 flex flex-col">
    <app-dataformat-picker
        [showAddButton]="false"
        [showEditButton]="false"
        [showRemoveButton]="false"
        [required]="false"
        [addQuotesToValue]="false"
        [value]="undefined"
        (valueUpdated)="onAddDataFormat($event)">
    </app-dataformat-picker>
    <hr class="seperator" *ngIf="chosenDataFormats && chosenDataFormats.length > 0" />
    <mat-chip-listbox>
        <mat-chip-option color="primary" *ngFor="let dataFormat of chosenDataFormats">
            {{ dataFormat.name }}
            <button matChipRemove (click)="onRemoveDataFormat(dataFormat)">
                <mat-icon>cancel</mat-icon>
            </button>
        </mat-chip-option>
    </mat-chip-listbox>
    <hr class="seperator" />
    <mat-form-field appearance="outline">
        <mat-label>Supporting prompt</mat-label>
        <textarea
            (keydown.enter)="sendInput($event)"
            class="p-2 w-full !text-xxs border border-primary rounded !focus:border !focus:border-primary"
            matInput
            fxFlex="100%"
            name="input"
            align="end"
            required
            formControlName="input">
        </textarea>
    </mat-form-field>
</div>
