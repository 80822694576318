<div
    data-accordion="open"
    data-active-classes="none"
    data-inactive-classes="none"
    *ngIf="showSubArguments && this._argument.subArguments && this._argument.subArguments.length > 0"
    (cdkDropListDropped)="reorderSubArguments($event)"
    [cdkDropListData]="this._argument.subArguments"
    [attr.id]="'subarguments-accordion-' + this._argument.id"
    [attr.data-accordion]="'subarguments-accordion-' + this._argument.id"
    class="mb-2 accordion-border"
    cdkDropList>
    <ng-container *ngFor="let argument of this._argument.subArguments; let i = index; trackBy: identifyArgument">
        <h3
            [attr.id]="'subarguments-accordion-' + argument.id + '-objectitem-heading'"
            [attr.data-name]="'subarguments-accordion-objectitem-header-' + argument.id"
            class="p-0"
            cdkDrag>
            <button
                type="button"
                class="accordion-heading"
                [class.accordion-heading-first]="i === 0"
                [class.accordion-heading-last]="i === this._argument.subArguments.length - 1"
                [attr.data-accordion-target]="'#subarguments-accordion-' + argument.id + '-objectitem'"
                [attr.aria-controls]="'subarguments-accordion-' + argument.id + '-objectitem'"
                aria-expanded="true">
                <span class="leading-6 grow shrink text-left" [matTooltip]="argument.id">{{ 'item ' + i | truncate: 25 }}</span>
                <button cdkDragHandle class="accordion-heading-button" [matTooltip]="'general.drag-n-drop-tooltip' | translate">
                    <mat-icon>import_export</mat-icon>
                </button>
                <button
                    class="accordion-heading-button destructive-accordion-heading-button"
                    (click)="onRemoveSubArgument(argument); $event.stopPropagation()"
                    [matTooltip]="'v2.action.argument.json.field.remove' | translate">
                    <mat-icon>clear</mat-icon>
                </button>
                <svg
                    data-accordion-icon
                    class="w-3 h-3 rotate-180 shrink-0"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5" />
                </svg>
            </button>
        </h3>
        <div
            [attr.id]="'subarguments-accordion-' + argument.id + '-objectitem'"
            [attr.data-name]="'subarguments-' + argument.id"
            [attr.aria-labelledby]="'subarguments-accordion-' + argument.id + '-objectitem'"
            [class.accordion-panel-first]="i === 0"
            [class.accordion-panel-last]="i === this._argument.subArguments.length - 1"
            [class.accordion-panel]="true"
            class="hidden">
            <argument-value-picker
                class="mb-1 block"
                [argument]="argument"
                [arguments]="argument?.subArguments"
                [contextId]="contextId"
                [scope]="scope"
                [root]="root"
                [language]="language"
                [onlyLiteralValues]="onlyLiteralValues"
                (argumentUpdated)="onActionExecutionArgumentUpdated($event)"
                (openTab)="openTab.emit($event)"></argument-value-picker>
        </div>
    </ng-container>
</div>
<button class="mt-2 w-full justify-center primary-button button-medium" type="button" (click)="onAddField()">
    <mat-icon>add</mat-icon>
    <span
        >{{ 'v2.action.argument.array.add.item' | translate }}
        {{ this._argument.parameter?.name ? (this._argument.parameter?.name | truncate: 25) : 'array' }}</span
    >
</button>
