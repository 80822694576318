import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Argument, Scope, TabDefinition } from '@backoffice/data-access/editor';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { backofficeEnvironment } from '@shared/environment';
import { CrewAiAgentDto } from '../../../../../../data-access/editor/src/lib/interfaces/ai/crew-ai-agent.dto';

@Component({
    selector: 'argument-agent',
    templateUrl: './argument-agent.component.html',
    standalone: false,
})
export class ArgumentAgentComponent implements OnInit, OnDestroy {
    @Input()
    set arguments(argumentList: Argument[]) {
        this._arguments = argumentList;
    }

    _arguments!: Argument[];

    @Input()
    set argument(argument: Argument) {
        this._argument = argument;
    }

    @Input()
    contextId!: string;

    @Input()
    scope!: Scope;

    @Input()
    language!: string;

    @Input() root: Argument[];

    @Input()
    onlyLiteralValues: boolean = false;

    @Output()
    argumentUpdated: EventEmitter<{ argument: Argument }> = new EventEmitter<{ argument: Argument }>();

    @Output()
    openTab: EventEmitter<TabDefinition> = new EventEmitter<TabDefinition>();

    _argument!: Argument;

    formGroup!: FormGroup;

    subscriptions: Subscription = new Subscription();

    constructor(
        public changeDetectorRef: ChangeDetectorRef,
        private fb: FormBuilder
    ) {}

    ngOnDestroy(): void {
        setTimeout(() => {
            this.subscriptions.unsubscribe();
        }, backofficeEnvironment.autosavedebounce + 100);
    }

    ngOnInit(): void {
        this.initForm();
    }

    removeQuotesIfPresent(value: string): string {
        if (value && value.startsWith("'") && value.endsWith("'")) {
            return value.substring(1, value.length - 1);
        } else {
            return value;
        }
    }

    updateForm(): void {
        this.formGroup?.get('value')?.setValue(this._argument.value);
    }

    initForm(): void {
        let agent: CrewAiAgentDto | null = null;
        if (this._argument.value) {
            agent = JSON.parse(this._argument.value);
        }
        this.formGroup = this.fb.group({
            role: [agent?.role, Validators.required],
            goal: [agent?.goal, Validators.required],
            backstory: [agent?.backstory, Validators.required],
            model: [agent?.model, Validators.required],
            tools: [agent?.tools],
        });

        this.subscriptions.add(this.formGroup.valueChanges.pipe(debounceTime(1000)).subscribe(formValues => this.onChange(formValues)));
    }

    onChange(value: string) {
        this._argument.value = JSON.stringify(value);
        this.argumentUpdated.emit({ argument: this._argument });
    }
}
