import { FrontofficeEnvironmentDto } from './frontoffice-environment.dto';

export const frontofficeEnvironment: FrontofficeEnvironmentDto = {
    inputdebounce: 300,
    production: false,
    onEntryOnBackend: false,
    amLink: 'https://dev-login.nocode-x.com/auth',
    redirectUrl: 'https://nocode-x.dev-back.nocode-x.com/redirect',
    //localTestApp: 'https://dev-unnamed-application-21-allezgow-2.dev-back.nocode-x.com',
    localTestApp: 'https://dev-unnamed-application-84-my-second-company.dev-back.nocode-x.com',
    // localTestApp: 'https://dev-unnamed-application-15-allezgow-2.dev-back.nocode-x.com',
    // https://dev-unnamed-application-2-at-runtime-v2.dev-app.nocode-x.com/test
};

/**
 * Hoe steek ik dit spel in gang:
 * Template url: https://dev-unnamed-application-3-at-runtime-2.dev-back.nocode-x.com
 *
 * Optie één: Zijt zeker dat de template path in de url staat bv: localhost:4201/{path_value}
 * Optie twee: stel je applicatie in -> metadata -> homepage -> select template dan werkt het ook via localhost:4201
 */
