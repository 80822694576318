import { Component, OnInit } from '@angular/core';
import { backofficeEnvironment } from '@shared/environment';
import { MatDialogRef as MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxFloatUiPlacements, NgxFloatUiTriggers } from 'ngx-float-ui';
import { Observable } from 'rxjs';
import { BillingPackage } from '@billing/dto/billingpackage';
import { BillingFacade } from '@billing/facade/billing.facade';

@Component({
    selector: 'codex-action-create-form',
    templateUrl: './action-create-form.component.html',
    standalone: false,
})
export class ActionCreateFormComponent implements OnInit {
    selectedType = 'BLANK';

    environment = backofficeEnvironment;

    public formGroup: FormGroup;

    constructor(
        public dialogRef: MatDialogRef<ActionCreateFormComponent>,
        public fb: FormBuilder,
        private billingFacade: BillingFacade
    ) {}

    billingPackage$: Observable<BillingPackage>;

    ngOnInit(): void {
        this.formGroup = this.fb.group({
            name: ['Unnamed Action', Validators.required],
            authenticationLogicInPlace: [false],
            authenticationLogic: [null],
            apiDocumentation: [null],
            apiAuthentication: ['NONE'],
            apiHost: [null],
            apiAuthenticationDocumentation: [null],
        });
        this.billingPackage$ = this.billingFacade.package;
    }

    onSelectType(type: string) {
        this.selectedType = type;
    }

    onCreate() {
        const {
            name,
            authenticationLogicInPlace,
            authenticationLogic,
            apiDocumentation,
            apiAuthentication,
            apiHost,
            apiAuthenticationDocumentation,
        } = this.formGroup.value;
        this.dialogRef.close({
            name,
            type: this.selectedType,
            authenticationLogicInPlace,
            authenticationLogic,
            apiDocumentation,
            apiAuthentication,
            apiHost,
            apiAuthenticationDocumentation,
        });
    }

    protected readonly NgxFloatUiPlacements = NgxFloatUiPlacements;
    protected readonly NgxFloatUiTriggers = NgxFloatUiTriggers;
}
