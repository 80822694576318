<mat-dialog-content>
    <form (ngSubmit)="onCreate()">
        <div class="p-4">
            <h1 class="!mb-2">{{ 'v2.template.type.choose' | translate }}</h1>
            <div class="flex gap-2 mb-2 max-w-[430px] flex-wrap" *ngIf="false">
                <div *ngIf="false" class="choice-card" [class.choice-card-selected]="selectedType === 'PDF'" (click)="onSelectType('PDF')">
                    <mat-icon>picture_as_pdf</mat-icon>
                    <span class="choice-card-title">Blank template</span>
                </div>

                <div class="choice-card" [class.choice-card-selected]="selectedType === 'COMPONENT'" (click)="onSelectType('COMPONENT')">
                    <mat-icon>grid_view</mat-icon>
                    <span class="choice-card-title">Root template with top navigation</span>
                </div>

                <div class="choice-card" [class.choice-card-selected]="selectedType === 'COMPONENT'" (click)="onSelectType('COMPONENT')">
                    <mat-icon>grid_view</mat-icon>
                    <span class="choice-card-title">Overview page</span>
                </div>

                <div class="choice-card" [class.choice-card-selected]="selectedType === 'COMPONENT'" (click)="onSelectType('COMPONENT')">
                    <mat-icon>grid_view</mat-icon>
                    <span class="choice-card-title">Datatable overview page</span>
                </div>

                <div class="choice-card" [class.choice-card-selected]="selectedType === 'COMPONENT'" (click)="onSelectType('COMPONENT')">
                    <mat-icon>grid_view</mat-icon>
                    <span class="choice-card-title">Detail page</span>
                </div>
            </div>
            <div class="flex gap-2 mb-2 max-w-[430px] flex-wrap">
                <div *ngIf="false" class="choice-card" [class.choice-card-selected]="selectedType === 'PDF'" (click)="onSelectType('PDF')">
                    <mat-icon>picture_as_pdf</mat-icon>
                    <span class="choice-card-title">{{ 'v2.template.type.pdf' | translate }}</span>
                </div>

                <div
                    *ngIf="!reusableComponent"
                    class="choice-card"
                    [class.choice-card-selected]="selectedType === 'WEBPAGE'"
                    (click)="onSelectType('WEBPAGE')">
                    <mat-icon>web</mat-icon>
                    <span class="choice-card-title">{{ 'v2.template.type.web' | translate }}</span>
                </div>

                <div class="choice-card" [class.choice-card-selected]="selectedType === 'COMPONENT'" (click)="onSelectType('COMPONENT')">
                    <mat-icon>grid_view</mat-icon>
                    <span class="choice-card-title">{{ 'v2.template.type.component' | translate }}</span>
                </div>

                <div
                    *ngIf="false"
                    class="choice-card"
                    [class.choice-card-selected]="selectedType === 'ROOT_TEMPLATE_WITH_SIDENAV'"
                    (click)="onSelectType('ROOT_TEMPLATE_WITH_SIDENAV')">
                    <mat-icon>web</mat-icon>
                    <span class="choice-card-title">Root page with side navigation</span>
                </div>

                <div
                    *ngIf="false"
                    class="choice-card"
                    [class.choice-card-selected]="selectedType === 'ROOT_WITH_MINI_SIDE'"
                    (click)="onSelectType('ROOT_WITH_MINI_SIDE')">
                    <mat-icon>grid_view</mat-icon>
                    <span class="choice-card-title">Root template with mini side navigation</span>
                </div>

                <div
                    class="choice-card"
                    [class.choice-card-selected]="selectedType === 'FORM_COMPONENT_FROM_DATAFORMAT'"
                    (click)="onSelectType('FORM_COMPONENT_FROM_DATAFORMAT')">
                    <mat-icon>grid_view</mat-icon>
                    <span class="choice-card-title">Form from dataformat</span>
                </div>
            </div>

            <label for="template-name" class="small-input-label" data-name="template-name">Name</label>
            <input
                class="small-input"
                type="text"
                id="template-name"
                name="template-name"
                autocomplete="off"
                [(ngModel)]="templateName"
                [ngModelOptions]="{ standalone: true }"
                required />

            <label for="language" class="small-input-label">{{ 'v2.template.language.choose' | translate }}</label>
            <select id="language" class="small-input" [(ngModel)]="selectedLanguage" [ngModelOptions]="{ standalone: true }">
                <option *ngFor="let language of environment.languages" [value]="language.languageCode">
                    {{ language.languageName + ' (' + language.englishLanguageName + ')' }}
                </option>
            </select>

            <ng-container *ngIf="selectedType === 'FORM_COMPONENT_FROM_DATAFORMAT'">
                <app-dataformat-picker
                    [addQuotesToValue]="false"
                    [required]="true"
                    [showAddButton]="false"
                    [showEditButton]="false"
                    (valueUpdated)="onDataFormatPick($event)">
                </app-dataformat-picker>

                <div class="badges">
                    <span
                        id="badge-dismiss-default"
                        *ngFor="let dataFormat of dataFormats"
                        class="inline-flex items-center px-2 py-1 me-2 text-sm font-medium text-blue-800 bg-blue-100 rounded dark:bg-blue-900 dark:text-blue-300">
                        {{ dataFormat.name }}
                        <button
                            type="button"
                            class="inline-flex items-center p-1 ms-2 text-sm text-blue-400 bg-transparent rounded-sm hover:bg-blue-200 hover:text-blue-900 dark:hover:bg-blue-800 dark:hover:text-blue-300"
                            data-dismiss-target="#badge-dismiss-default"
                            aria-label="Remove"
                            (click)="onRemoveDataFormat(dataFormat)">
                            <svg class="w-2 h-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span class="sr-only">Remove badge</span>
                        </button>
                    </span>
                </div>
            </ng-container>

            <div class="flex justify-end gap-1">
                <button class="primary-button button-large" data-name="save" type="submit">
                    <mat-icon>add_circle</mat-icon>
                    {{ 'v2.template.create' | translate }}
                </button>
                <button class="secondary-button button-large" data-name="cancel" type="button" (click)="dialogRef.close({ create: false })">
                    <mat-icon>cancel</mat-icon>
                    {{ 'general.cancel' | translate }}
                </button>
            </div>
        </div>
    </form>
</mat-dialog-content>
