import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, OnInit } from '@angular/core';
import {
    ActionEditorFacade,
    DataFormatEditorFacade,
    EditorFacade,
    JobEditorFacade,
    OverviewActionDto,
    OverviewDataFormatDto,
    OverviewTemplateDto,
    TemplateEditorFacade,
} from '@backoffice/data-access/editor';
import { FormBuilder } from '@angular/forms';
import { debounceTime, take } from 'rxjs/operators';
import { combineLatest, Observable, Subject } from 'rxjs';
import { Page } from '@shared/data-access';
import * as d3 from 'd3';

interface NodeDatum extends d3.SimulationNodeDatum {
    id: string;
    name: string;
    type: string;
    img: string;
    icon?: string;
}

@Component({
    selector: 'codex-application-overview',
    templateUrl: './application-overview.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class ApplicationOverviewComponent implements OnInit, AfterViewInit {
    templates$: Observable<Page<OverviewTemplateDto>>;
    actions$: Observable<Page<OverviewActionDto>>;
    dataformats$: Observable<Page<OverviewDataFormatDto>>;
    private width: number;
    private height: number;
    private resizeSubject = new Subject<void>();
    private svg;
    private simulation;
    private zoom;

    constructor(
        public editorFacade: EditorFacade,
        public templateEditorFacade: TemplateEditorFacade, // Ensure correct facade class is used
        public dataFormatEditorFacade: DataFormatEditorFacade,
        public actionEditorFacade: ActionEditorFacade,
        public jobEditorFacade: JobEditorFacade,
        public changeDetectorRef: ChangeDetectorRef,
        public fb: FormBuilder,
        private elRef: ElementRef
    ) {}

    ngAfterViewInit() {
        this.updateDimensions();
    }

    @HostListener('window:resize')
    onResize() {
        this.resizeSubject.next();
    }

    updateDimensions() {
        const container = this.elRef.nativeElement.querySelector('#d3-container');
        if (container) {
            this.width = container.clientWidth;
            this.height = container.clientHeight;
            if (this.svg) {
                this.svg.attr('width', this.width).attr('height', this.height);
                this.simulation?.restart();
            }
        }
    }

    initGraph(templates: Page<OverviewTemplateDto>, actions: Page<OverviewActionDto>, dataformats: Page<OverviewDataFormatDto>) {
        this.updateDimensions();
        const laneHeight = this.height / 3;

        const nodes: NodeDatum[] = [
            ...templates?.content.map(t => ({
                id: t.id,
                name: t.name,
                type: 'template',
                img: 'template.png',
                x: this.width / 2,
                y: laneHeight / 2,
            })),
            ...actions?.content.map(a => ({
                id: a.id,
                name: a.name,
                type: 'action',
                img: 'action.png',
                x: this.width / 2,
                y: (2 * laneHeight) / 2,
            })),
            ...dataformats?.content.map(d => ({
                id: d.id,
                name: d.name,
                type: 'dataformat',
                icon: 'database',
                img: 'action.png',
                x: this.width / 2,
                y: (3 * laneHeight) / 2,
            })),
        ];

        d3.select('#d3-container svg').remove();

        // @ts-ignore
        const zoom: d3.ZoomBehavior<SVGSVGElement, unknown> = d3
            .zoom()
            .scaleExtent([0.5, 3]) // Set the min and max zoom levels
            .on('zoom', event => {
                g.attr('transform', event.transform); // Apply full zoom transformation
            });

        this.svg = d3
            .select('#d3-container')
            .append('svg')
            .attr('width', this.width)
            .attr('height', this.height)
            .attr('style', 'cursor: grab;');

        const g = this.svg.append('g');

        this.svg.call(zoom);

        const simulation = d3
            .forceSimulation<NodeDatum>(nodes)
            .force('charge', d3.forceManyBody().strength(-300))
            .force('x', d3.forceX(d => d.x).strength(0.1))
            .force('collide', d3.forceCollide(120)) // Prevent overlap
            .alpha(1)
            .restart();

        const node = g.selectAll('g').data(nodes).enter().append('g');

        node.append('foreignObject')
            .attr('width', 120)
            .attr('height', 120)
            .attr('x', -60)
            .attr('y', -60)
            .html(
                d => `
            <div class="node-container ${d.type}">
                <img src="${d.img}" class="node-icon" />
                <div class="node-label">${d.name}</div>
            </div>
        `
            );

        simulation.on('tick', () => {
            node.attr('transform', d => `translate(${d.x},${d.y})`);
        });
    }

    ngOnInit() {
        this.templateEditorFacade.currentContext$.pipe(take(1)).subscribe(context => {
            this.templates$ = this.templateEditorFacade.findAll(
                '',
                ['component:false'],
                'score desc',
                0,
                1000,
                context?.selectedApplication.id
            );
            this.actions$ = this.actionEditorFacade.findAll('', [], 'score desc', 0, 1000);
            this.dataformats$ = this.dataFormatEditorFacade.findAll('', [], 'score desc', 0, 1000);

            combineLatest([this.templates$, this.actions$, this.dataformats$])
                .pipe()
                .subscribe(([templates, actions, dataformats]) => this.initGraph(templates, actions, dataformats));
        });

        this.resizeSubject.pipe(debounceTime(300)).subscribe(() => {
            this.updateDimensions();
        });
    }
}
