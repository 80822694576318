<mat-accordion *ngIf="showSubArguments" class="inner-accordion">
    <ng-container *ngFor="let argument of this._argument.subArguments; trackBy: identifyArgument">
        <mat-expansion-panel
            [expanded]="argument.parameterId === this.openedSubArgument"
            (opened)="this.openedSubArgument = argument.parameterId"
            (closed)="this.openedSubArgument = null">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ argument.id }}
                    <button
                        class="deleteButton"
                        mat-icon-button
                        color="warn"
                        (click)="onRemoveSubArgument(argument); $event.stopPropagation()"
                        [matTooltip]="'v2.action.argument.json.field.remove' | translate">
                        <mat-icon>clear</mat-icon>
                    </button>
                </mat-panel-title>
            </mat-expansion-panel-header>

            <label for="json-name" class="small-input-label" data-name="json-name">{{
                'v2.action.argument.json.field.name' | translate
            }}</label>
            <input
                class="small-input"
                type="text"
                id="json-name"
                name="json-name"
                autocomplete="off"
                required
                [(ngModel)]="argument.id"
                (change)="onActionExecutionArgumentUpdated({ argument: argument })" />

            <argument-value-picker
                class="mb-1 block"
                [argument]="argument"
                [arguments]="this._argument.subArguments"
                [contextId]="contextId"
                [root]="root"
                [scope]="scope"
                [language]="language"
                (argumentUpdated)="onActionExecutionArgumentUpdated($event)"></argument-value-picker>
        </mat-expansion-panel>
    </ng-container>
</mat-accordion>
<button class="add-button mt-1 mb-1 w-[100%] !h-9" type="button" mat-raised-button color="primary" (click)="onAddField()">
    <mat-icon>add</mat-icon>
    <span>{{ 'v2.action.argument.json.field.add.field' | translate }}</span>
</button>
