import { Component, OnInit } from '@angular/core';
import { BillingFacade } from '@billing/facade/billing.facade';
import { GenerativeTaskService } from '../../../../../data-access/editor/src/lib/services/generative-task.service';
import { BehaviorSubject, combineLatest, interval, Observable, switchMap, takeWhile } from 'rxjs';
import { CompanyDto } from '@shared/interfaces/company.dto';
import { ApplicationDto } from '../../../../../../../apps/no-code-x-backoffice/src/app/v2-application/dto/application.dto';
import { selectCurrentContext } from '../../../../../../../apps/no-code-x-backoffice/src/app/store/data/authenticated.selector';
import { take, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ApplicationState } from '../../../../../../../apps/no-code-x-backoffice/src/app/store/application.state';
import { EditorFacade } from '@backoffice/data-access/editor';
import { GenerativeTaskDto } from '../../../../../data-access/editor/src/lib/generative-tasks/interfaces/generative-task.dto';
import { GenerativeTaskContextDto } from '../../../../../data-access/editor/src/lib/generative-tasks/interfaces/generative-task-context.dto';
import { NgxFloatUiPlacements, NgxFloatUiTriggers } from 'ngx-float-ui';

@Component({
    selector: 'codex-generative',
    templateUrl: './generative.component.html',
    styleUrls: ['./generative.component.scss'],
    standalone: false,
})
export class GenerativeComponent implements OnInit {
    private billingRefresh$ = new BehaviorSubject<void>(undefined);

    public billingPackage$ = this.billingRefresh$.pipe(switchMap(() => this.billingFacade.getBillingPackage()));

    public activeActionId: string;

    public prompt: string;

    public actions: string[] = [];

    currentContext$: Observable<{
        userLanguage: string;
        selectedCompany: CompanyDto;
        selectedApplication: ApplicationDto;
    }> = this.store.select(selectCurrentContext);

    currentConversation: BehaviorSubject<GenerativeTaskDto[]> = new BehaviorSubject<GenerativeTaskDto[]>([]);

    rows: number = 3;
    textareaHeight: string = `${this.rows * 2.5}em`;

    constructor(
        private billingFacade: BillingFacade,
        private editorFacade: EditorFacade,
        private generativeTaskService: GenerativeTaskService,
        private store: Store<ApplicationState>
    ) {}

    changeRows() {
        if (this.rows === 3) {
            this.rows = 10;
        } else {
            this.rows = 3;
        }

        this.textareaHeight = `${this.rows * 2.5}em`;
    }

    ngOnInit(): void {
        this.billingRefresh$.next();
        this.editorFacade.activeTab.pipe(take(1)).subscribe(activeTab => {
            if (activeTab.type === 'action') {
                this.activeActionId = activeTab.typeId;
                this.actions = ['generate-name', 'generate-description', 'explain-action'];
            }
        });

        this.initCurrentConversation();
    }

    initCurrentConversation() {
        if (this.currentConversation.value.length === 0) {
            let currenConversationList = this.currentConversation.value;

            currenConversationList.push({
                id: 'hello',
                status: 'SUCCESS',
                answer:
                    '#### Hey there! \n\n\n' +
                    'I am your personal assistant within NoCode-X. I am still in alpha but you can already test me out! \n\n' +
                    '##### Currently you can do the following: \n\n\n\n' +
                    '* **Explain Actions:** Quickly understand what the currently opened action does. \n\n' +
                    '* **Generate Action Names:** Get a clear, professional name for your opened action.\n\n' +
                    '* **Create Action Descriptions:** Automatically generate concise and accurate descriptions for the opened action.\n\n' +
                    '* **Build Actions:** Let me help you create actions effortlessly.\n\n' +
                    '* **Get information on NoCode-X:** Instantly find details from the documentation. \n\n' +
                    '' +
                    '##### In the future you will be able to: \n\n\n\n' +
                    '* Create & update templates \n\n' +
                    "* Create & update data formats (now available through 'create data format') \n\n" +
                    '* Create & update images in your media library \n\n' +
                    '* Create & update APIs \n\n' +
                    "* Generate test data (now available through 'create data') \n\n" +
                    '* Vibe program from our application overview \n\n',
                prompt: null,
                type: null,
                name: 'test',
                applicationId: null,
                companyId: null,
                quality: 0,
            });

            const textarea = document.getElementById('sender-chat');
            const chatMessages = document.querySelector('.generative-chat-messages');

            setTimeout(() => {
                if (textarea) {
                    textarea.focus(); // Focus on the textarea
                    // @ts-ignore
                    textarea.setSelectionRange(0, 0);
                }
                if (chatMessages) {
                    chatMessages.scrollTo({ top: chatMessages.scrollHeight, behavior: 'smooth' });
                }
            }, 500);

            this.currentConversation.next(currenConversationList);
        }
    }

    onCloseGenerative() {
        const generativeChat = document.getElementById('generative-chat');
        setTimeout(() => {
            if (generativeChat) {
                generativeChat.classList.remove('show'); // Start the slide-in animation
            }
        }, 10);
    }
    executeGenerativeTask(taskType: string, referenceId: string, referenceType: string) {
        let prompt = this.prompt;
        this.prompt = '';
        const textarea = document.getElementById('sender-chat');
        const chatMessages = document.querySelector('.generative-chat-messages');

        let currenConversationList = this.currentConversation.value;
        currenConversationList.push({
            id: 'current',
            status: 'GENERATING',
            answer: null,
            prompt: prompt,
            type: taskType,
            name: 'test',
            applicationId: null,
            companyId: null,
            quality: 0,
        });
        setTimeout(() => {
            if (textarea) {
                textarea.focus(); // Focus on the textarea
                // @ts-ignore
                textarea.setSelectionRange(0, 0);
            }
            if (chatMessages) {
                chatMessages.scrollTo({ top: chatMessages.scrollHeight, behavior: 'smooth' });
            }
        }, 500);

        this.currentConversation.next(currenConversationList);

        return combineLatest([this.currentContext$, this.editorFacade.activeTab])
            .pipe(
                take(1),
                switchMap(([currentContext, activeTab]) => {
                    return this.generativeTaskService
                        .create({
                            prompt: prompt,
                            type: taskType,
                            name: 'test',
                            companyId: currentContext.selectedCompany.id,
                            applicationId: currentContext.selectedApplication.id,
                            context: {
                                contextReferenceId: activeTab.typeId,
                                contextType: activeTab.type,
                            },
                        })
                        .pipe(
                            tap(() => {
                                setTimeout(() => {
                                    if (textarea) {
                                        textarea.focus(); // Focus on the textarea
                                        // @ts-ignore
                                        textarea.setSelectionRange(0, 0);
                                    }
                                    if (chatMessages) {
                                        chatMessages.scrollTo({ top: chatMessages.scrollHeight, behavior: 'smooth' });
                                    }
                                }, 500);
                            }),
                            switchMap(generativeTaskCreated =>
                                interval(2000).pipe(
                                    take(1000), // Poll every 1 second
                                    switchMap(() =>
                                        this.generativeTaskService.get(
                                            generativeTaskCreated.id,
                                            currentContext.selectedApplication.id,
                                            currentContext.selectedCompany.id
                                        )
                                    ),
                                    tap(generativeTaskDto => {
                                        let existingGenerativeTask = this.currentConversation.value.find(
                                            currentConversationGenerativeTask =>
                                                generativeTaskDto.id === currentConversationGenerativeTask.id
                                        );
                                        if (existingGenerativeTask) {
                                            let currenConversationList = this.currentConversation.value;
                                            let index = currenConversationList.indexOf(existingGenerativeTask);
                                            currenConversationList.splice(index, 1);
                                            currenConversationList[index] = generativeTaskDto;
                                            this.currentConversation.next(currenConversationList);
                                        } else {
                                            let existingGenerativeTask = this.currentConversation.value.find(
                                                currentConversationGenerativeTask => 'current' === currentConversationGenerativeTask.id
                                            );
                                            let currenConversationList = this.currentConversation.value;
                                            let index = currenConversationList.indexOf(existingGenerativeTask);
                                            currenConversationList.splice(index, 1);
                                            currenConversationList[index] = generativeTaskDto;
                                            this.currentConversation.next(currenConversationList);
                                        }
                                        if (generativeTaskDto.status === 'SUCCESS' || generativeTaskDto.status === 'FAILED') {
                                            setTimeout(() => {
                                                if (chatMessages) {
                                                    chatMessages.scrollTo({
                                                        top: chatMessages.scrollHeight,
                                                        behavior: 'smooth',
                                                    });
                                                }
                                                this.billingRefresh$.next();
                                            }, 500);
                                        }
                                    }),
                                    takeWhile(generativeTask => generativeTask.status === 'GENERATING', true) // Keep polling while status is 'GENERATING'
                                )
                            )
                        );
                })
            )
            .subscribe();
    }

    identifyConversation(index, item) {
        return item.id;
    }

    protected readonly NgxFloatUiTriggers = NgxFloatUiTriggers;
    protected readonly NgxFloatUiPlacements = NgxFloatUiPlacements;
}
