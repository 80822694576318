<ng-container [formGroup]="formGroup">
    <hr *ngIf="invocation && invocation?.invocationOutputs && invocation?.invocationOutputs.length > 0" class="seperator" />
    <ng-container *ngFor="let output of invocation?.invocationOutputs; trackBy: identifyOutput">
        <ng-container *ngIf="output && output.type !== 'ACTION_OUTPUTS'">
            <div
                class="flex mb-1 mt-1 gap-1 justify-start"
                [disabled]="!output?.output?.getDescription('en')"
                [showTrigger]="NgxFloatUiTriggers.hover"
                [floatUi]="argumentInformationPopover"
                [appendTo]="'body'"
                [boundariesElement]="'body'"
                [preventOverflow]="false"
                [positionFixed]="true"
                [applyClass]="'!max-w-[250px]'"
                [placement]="NgxFloatUiPlacements.LEFT">
                <h4 class="!text-sm !font-bold !mb-1 !mt-1 !w-full flex items-center gap-1">
                    {{ output?.output?.getName(language) }}
                    <ng-container *ngIf="output?.output?.getDescription('en')">
                        <svg class="icon icon-button" aria-hidden="true" focusable="false">
                            <use href="#help" class="ui-element"></use>
                        </svg>
                    </ng-container>
                </h4>
            </div>
            <float-ui-content #argumentInformationPopover
                ><div class="popover">{{ output?.output?.getDescription('en') }}</div></float-ui-content
            >
        </ng-container>
        <ng-container *ngIf="output && output.type !== 'ACTION_OUTPUTS' && output.type !== 'DATA_FORMAT'">
            <label for="output-variable-name" class="small-input-label" data-name="output-variable-name">Variable name</label>
            <input
                class="small-input"
                type="text"
                id="output-variable-name"
                name="output-variable-name"
                autocomplete="off"
                [formControlName]="output.outputId"
                cd-code />
        </ng-container>
        <ng-container *ngIf="output && output.output?.type === 'DATA_FORMAT'">
            <app-dataformat-picker
                [value]="output.value"
                [required]="false"
                (valueUpdated)="onDataFormatChange($event, output)"
                (openTab)="this.openTab.emit($event)">
            </app-dataformat-picker>
        </ng-container>
        <ng-container *ngIf="output && output.type === 'ACTION_OUTPUTS'">
            <codex-action-edit-invocation-argument-action-output
                [output]="output"
                [contextId]="invocation.id"
                [arguments]="invocation.arguments"
                [language]="language"
                (outputUpdated)="onOutputChange($event)"
                (openTab)="this.openTab.emit($event)">
            </codex-action-edit-invocation-argument-action-output>
        </ng-container>
    </ng-container>
</ng-container>
