import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';

import { catchError, concatMap, debounceTime, exhaustMap, filter, map, mergeMap, retry, switchMap, take, tap } from 'rxjs/operators';
import { AppFacade } from '@core/facades/app.facade';
import { Store } from '@ngrx/store';
import { EditorState } from '../editor.state';
import { OverviewTemplateDto, Template, toFilter } from '@backoffice/data-access/editor';
import { TemplateEditorFacade } from '../facades/template-editor.facade';
import { TemplateService } from '../../services/template/template.service';
import {
    clearTemplateDialogFilter,
    createTemplateSuccess,
    deleteTemplate,
    deleteTemplates,
    deleteTemplatesSuccess,
    deleteTemplateSuccess,
    fetchTemplateError,
    fetchTemplateSuccess,
    findTemplate,
    loadTemplateDialogData,
    loadTemplateDialogSuccess,
    refreshTemplate,
    refreshTemplateSuccess,
    templateDialogFacetsChanged,
    templateDialogFilterPluginsChanged,
    templateDialogPaginationChanged,
    templateDialogSearchTermChanged,
    updateTemplate,
    updateTemplateSuccess,
} from '../actions/templates-editor.actions';
import { templateEditorSelectors } from '../selectors/template-editor.selectors';
import { closeTab, closeTabs, registerTab, updateTab } from '../actions/editor.actions';
import { backofficeEnvironment } from '@shared/environment';
import { forkJoin, of } from 'rxjs';
import { Page } from '@shared/data-access';
import {
    refreshUserAchievements,
    requestUserAchievements,
    selectApplicationSuccess,
} from '../../../../../../../../apps/no-code-x-backoffice/src/app/store/context/context.actions';
import { createActionSuccess } from '../actions/action-editor.actions';
import { driver } from 'driver.js';
import { AchievementsService } from '@core/services/achievements.service';

@Injectable()
export class TemplateEffects {
    constructor(
        protected readonly actions$: Actions,
        private readonly store: Store<EditorState>,
        private readonly appFacade: AppFacade,
        private readonly editorFacade: TemplateEditorFacade,
        private readonly templateService: TemplateService,
        private readonly achievementsService: AchievementsService
    ) {}

    applicationChange$ = createEffect(() =>
        this.actions$.pipe(
            ofType(selectApplicationSuccess),
            map(() => clearTemplateDialogFilter())
        )
    );

    bulkDelete$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteTemplates),
            concatLatestFrom(() => [this.appFacade.selectedCompany, this.appFacade.selectedApplication]),
            switchMap(([{ ids }, { id: companyId }, { id: applicationId }]) =>
                forkJoin(ids.map(id => this.templateService.delete(id, companyId, applicationId))).pipe(map(() => ids))
            ),
            map((ids: string[]) => deleteTemplatesSuccess({ ids }))
        )
    );

    closeTabs$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteTemplatesSuccess),
            map(({ ids }) => closeTabs({ typeIds: ids, tabType: 'template' }))
        )
    );

    delete$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteTemplate),
            concatLatestFrom(() => [this.appFacade.selectedCompany, this.appFacade.selectedApplication]),
            concatMap(([{ id }, { id: companyId }, { id: applicationId }]) =>
                this.templateService.delete(id, companyId, applicationId).pipe(map(() => deleteTemplateSuccess({ id })))
            )
        )
    );

    fetch$ = createEffect(() =>
        this.actions$.pipe(
            ofType(findTemplate),
            concatLatestFrom(({ id, templateApplicationId, languageCode }) => [
                this.appFacade.selectedCompany,
                this.appFacade.selectedApplication,
                this.store.select(templateEditorSelectors.byId(id)),
            ]),
            filter(([_template, company, application, detail]) => !!company && !!application),
            mergeMap(([{ id, templateApplicationId, languageCode }, { id: companyId }, { id: applicationId }, detail]) => {
                if (detail) {
                    return of(fetchTemplateSuccess({ template: detail }));
                } else {
                    return this.templateService
                        .findById(id, companyId, templateApplicationId ? templateApplicationId : applicationId, languageCode)
                        .pipe(
                            tap(result => {
                                if (result.languageSyncStatus !== 'IDLE') {
                                    throw 'SYNCING';
                                }
                            }),
                            map(result => fetchTemplateSuccess({ template: result })),
                            catchError(errors => {
                                if (errors !== 'SYNCING') {
                                    return of(fetchTemplateError({ template: Template.createDeleted(id) }));
                                } else {
                                    throw errors;
                                }
                            }),
                            retry({ count: 10, delay: 5000 })
                        );
                }
            })
        )
    );

    fetchDialogData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                deleteTemplatesSuccess,
                loadTemplateDialogData,
                templateDialogPaginationChanged,
                templateDialogSearchTermChanged,
                templateDialogFacetsChanged,
                templateDialogFilterPluginsChanged
            ),
            concatLatestFrom(() => [this.appFacade.selectedCompany, this.appFacade.selectedApplication, this.editorFacade.filter]),
            switchMap(([_, { id: companyId }, { id: applicationId }, { page, maxResults, searchTerm, facets, filterPlugins }]) =>
                this.templateService.find(companyId, applicationId, {
                    page: page,
                    maxResults,
                    keyword: searchTerm,
                    filters: toFilter(facets, filterPlugins),
                })
            ),
            map((data: Page<OverviewTemplateDto>) => loadTemplateDialogSuccess({ data }))
        )
    );

    refresh$ = createEffect(() =>
        this.actions$.pipe(
            ofType(refreshTemplate),
            concatLatestFrom(({ id }) => [this.appFacade.selectedCompany, this.appFacade.selectedApplication]),
            filter(([_, company, application]) => !!company && !!application),
            mergeMap(([{ id }, { id: companyId }, { id: applicationId }]) =>
                this.templateService
                    .findById(id, companyId, applicationId)
                    .pipe(map(result => refreshTemplateSuccess({ template: result })))
            )
        )
    );

    update$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateTemplate),
            debounceTime(backofficeEnvironment.autosavedebounce),
            concatLatestFrom(() => [this.appFacade.selectedCompany, this.appFacade.selectedApplication]),
            filter(([{ template }, company, application]) => template.isValid() && !!company && !!application),
            concatMap(([{ template }, { id: companyId }, { id: applicationId }]) =>
                this.templateService.update(template, companyId, applicationId).pipe(
                    exhaustMap(() => this.templateService.findById(template.id, companyId, applicationId)),
                    map(fetchedTemplate => updateTemplateSuccess({ template: fetchedTemplate }))
                )
            )
        )
    );

    closeTab$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteTemplateSuccess),
            map(({ id }) => closeTab({ typeId: id, tabType: 'template' }))
        )
    );

    openTab$ = createEffect(() =>
        this.actions$.pipe(
            ofType(createTemplateSuccess),
            map(({ id }) => registerTab({ definition: { type: 'template', typeId: id } }))
        )
    );

    updateTab$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateTemplateSuccess, fetchTemplateSuccess),
            map(({ template }) => {
                const { id: typeId, name, iconName: icon } = template;
                return updateTab({ definition: { type: 'template', typeId, name, icon } });
            })
        )
    );

    activateTemplateOnboarding$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(createTemplateSuccess),
                concatLatestFrom(() => [this.appFacade.user, this.appFacade.context]),
                tap(([{ id }, userContext, context]) => {
                    let genericOnboardingAchievement = userContext.achievements?.find(
                        achievement => achievement.code === 'TEMPLATE_ONBOARDING'
                    );
                    if (!genericOnboardingAchievement) {
                        const driverObj = driver({
                            showProgress: true,
                            onDestroyed: () => {
                                this.achievementsService
                                    .createAchievement(userContext.id, 'TEMPLATE_ONBOARDING')
                                    .pipe(take(1))
                                    .subscribe(() => {
                                        this.store.dispatch(refreshUserAchievements({ info: userContext }));
                                    });
                            },
                            steps: [
                                {
                                    popover: {
                                        title: 'Aah, your very first page!',
                                        description:
                                            '<img src="images/theme/guide.gif" width="300" style="height: 300px; margin:auto;"/> ' +
                                            "<br></br>Ready? Let’s dive in and start our journey — You'll see soon that the possibilities are endless!",
                                        popoverClass: 'driver-intro',
                                    },
                                },
                                {
                                    element: '.template-grid-5',
                                    popover: {
                                        title: 'Template canvas',
                                        description:
                                            'As a user you can have access to one or more workspaces. Click here to switch between workspaces or create new workspaces.',
                                    },
                                },
                                {
                                    element: 'template-edit .left-drawer-block:first-child',
                                    popover: {
                                        title: 'Element selector',
                                        description:
                                            'As a user you can have access to one or more workspaces. Click here to switch between workspaces or create new workspaces.',
                                    },
                                },
                                {
                                    element: '#filter_parttypes',
                                    popover: {
                                        title: 'Element filter',
                                        description:
                                            'Within a workspace you can have as many applications as you want, Click here to create new applications or switch between existing applications.',
                                    },
                                },
                                {
                                    element: 'template-part-picker-items .draggables-grid button:first-of-type',
                                    popover: {
                                        title: 'Element',
                                        description:
                                            'Within a workspace you can have as many applications as you want, Click here to create new applications or switch between existing applications.',
                                    },
                                },
                                {
                                    element: '.template-grid-5',
                                    popover: {
                                        title: 'Drag & drop it into place!',
                                        description:
                                            '<img src="images/theme/guide.gif" width="300" style="height: 300px; margin:auto;"/> ' +
                                            "<br></br>Ready? Let’s dive in and start our journey — You'll see soon that the possibilities are endless!",
                                    },
                                },
                                {
                                    element: '.template-specific-actions .screensize-selector',
                                    popover: {
                                        title: 'Design for each screen size',
                                    },
                                },
                                {
                                    element: '.template-specific-actions .view-controls',
                                    popover: {
                                        title: 'Center/Grid/Borders',
                                    },
                                },
                                {
                                    element: '.template-specific-actions .embed-controls',
                                    popover: {
                                        title: 'Embed your template!',
                                    },
                                },
                                {
                                    element: '.template-specific-actions .language-controls',
                                    popover: {
                                        title: 'Multi-language by nature',
                                    },
                                },
                                {
                                    element: '.template-specific-actions .hierarchy-controls',
                                    popover: {
                                        title: 'Template hierarchy',
                                    },
                                },
                                {
                                    element: '.template-specific-actions .authentication-controls',
                                    popover: {
                                        title: 'Authentication/Authorization',
                                    },
                                },
                                {
                                    element: '.template-specific-actions .settings-controls',
                                    popover: {
                                        title: 'Template hierarchy',
                                    },
                                },
                                {
                                    element: '.template-specific-actions .toolbox-controls',
                                    popover: {
                                        title: 'Toolbox',
                                    },
                                },
                                {
                                    element: '.template-specific-actions .play',
                                    popover: {
                                        title: 'Test your page',
                                    },
                                },
                            ],
                        });
                        setTimeout(() => {
                            driverObj.drive();
                        }, 1000);
                    }
                })
            ),
        { dispatch: false }
    );
}
