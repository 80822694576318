<div class="generative-message w-full">
    <div class="flex-col flex items-start w-full" *ngIf="message.status === 'SUCCESS'">
        <div class="generative-message-sender-name">NoCode-X</div>
        <div class="generative-message-text-wrapper">
            <div
                class="generative-message-text relative"
                [ngClass]="message.changeType === 'ACTION' && showQualityOverlay ? 'mb-[6.5rem]' : 'mb-6'">
                <markdown [start]="5" [data]="message.answer" [disableSanitizer]="false"></markdown>
                <div
                    class="absolute right-2 w-full action-container flex justify-end gap-2.5 mb-[-25px]"
                    *ngIf="message.changeType === 'ACTION'">
                    <div class="link bg-light-background_level_0 border-dark-border border" (click)="onExecuteActionChange(message)">
                        <svg class="icon" aria-hidden="true" focusable="false">
                            <use href="#make-changes" class="ui-element"></use>
                        </svg>
                    </div>

                    <div class="link bg-light-background_level_0 border-dark-border border" (click)="onExecuteExplanation(message)">
                        <svg aria-hidden="true" focusable="false" class="icon">
                            <use href="#explanation" class="ui-element"></use>
                        </svg>
                    </div>

                    <div class="link bg-light-background_level_0 border-dark-border border" (click)="onExecuteChangeReference(message)">
                        <svg class="icon" aria-hidden="true" focusable="false">
                            <use href="#edit" class="ui-element"></use>
                        </svg>
                    </div>

                    <div class="link bg-light-background_level_0 border-dark-border border" (click)="onExecuteActionUndo(message)">
                        <svg class="icon" aria-hidden="true" focusable="false">
                            <use href="#undo-changes" class="ui-element"></use>
                        </svg>
                    </div>

                    <div class="link bg-light-background_level_0 border-dark-border border" (click)="onOpenChangeQuality(message)">
                        <svg class="icon" aria-hidden="true" focusable="false">
                            <use href="#score" class="ui-element"></use>
                        </svg>
                    </div>
                </div>
                <div *ngIf="showQualityOverlay" class="generative-message-quality-overlay">
                    <mat-slider min="0" max="10" step="1" showTickMarks discrete>
                        <input matSliderThumb [(ngModel)]="selectedQuality" />
                    </mat-slider>
                    <div class="flex justify-end mt-0.5">
                        <div class="link bg-light-background_level_0 border-dark-border border" (click)="onConfirmQuality(message)">
                            <svg class="icon" aria-hidden="true" focusable="false">
                                <use href="#ok" class="ui-element"></use>
                            </svg>
                        </div>

                        <div class="link bg-light-background_level_0 border-dark-border border" (click)="onCloseQualityOverlay()">
                            <svg class="icon" aria-hidden="true" focusable="false">
                                <use href="#remove" class="ui-element"></use>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex mt-4" *ngIf="backofficeEnvironment.vfebruary && message.changeType === 'ACTION' && message.status === 'SUCCESS'">
            <div class="generative-message-text bubble bg-light-background_level_0 border-dark-border border p-2">
                Would you like to create or update any tests for this action?
                <div class="flex justify-end gap-2 mt-2">
                    <div class="link bg-light-background_level_0 border-dark-border border" (click)="onGenerateTests(message)">
                        <svg class="icon" aria-hidden="true" focusable="false">
                            <use href="#generate-tests" class="ui-element"></use>
                        </svg>
                    </div>
                    <div class="link bg-light-background_level_0 border-dark-border border" (click)="onSkipTests(message)">
                        <svg class="icon" aria-hidden="true" focusable="false">
                            <use href="#skip-tests" class="ui-element"></use>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="flex-col flex items-start w-full" *ngIf="message.status === 'FAILED'">
        <div class="generative-message-sender-name">NoCode-X</div>
        <div class="generative-message-text-wrapper">
            <div class="generative-message-text max-w-[85%]">An error occured</div>
        </div>
    </div>
    <div class="flex-col flex items-start w-full" *ngIf="message.status === 'GENERATING' && !message.answer">
        <div class="generative-message-sender-name">NoCode-X</div>
        <div class="generative-message-text-wrapper">
            <div class="generative-message-text generating">
                <div class="h-5 w-12 rounded">
                    <img height="20" width="50" class="object-cover" src="/src/images/theme/chat_loading.gif" style="height: 20px" />
                </div>
            </div>
        </div>
    </div>

    <div class="flex-col flex items-start w-full" *ngIf="message.status === 'GENERATING' && message.answer">
        <div class="generative-message-sender-name">NoCode-X</div>
        <div class="generative-message-text-wrapper">
            <div class="generative-message-text generating">
                {{ message.answer }}
            </div>
        </div>
    </div>
</div>
