import { ActionEditorState } from './state/action-editor.state';
import { DataEditorState } from './state/data-editor.state';
import { DataFormatEditorState } from '../dataformat/store/state/data-format-editor.state';
import { ApiEditorState } from './state/api-editor.state';
import { MediaEditorState } from './state/media-editor.state';
import { TemplateEditorState } from './state/template-editor.state';
import { JobEditorState } from './state/job-editor.state';
import { PartTypeEditorState } from './state/part-type-editor.state';
import { TemplatesContextEditorState } from './state/template-context-editor.state';
import { ActionContextState } from './state/action-context.state';
import { TemplateDialogState } from './state/template-dialog.state';
import { MediaDialogState } from './state/media-dialog.state';
import { ApiDialogState } from './state/api-dialog.state';
import { DataDialogState } from './state/data-dialog.state';
import { DataFormatDialogState } from '../dataformat/store/state/data-format-dialog.state';
import { JobDialogState } from './state/job-dialog.state';
import { ActionDialogState } from './state/action-dialog.state';
import { DesignSystemEditorState } from '../designsystem/store/state/design-system-editor.state';
import { DesignSystemDialogState } from '../designsystem/store/state/design-system-dialog.state';
import { GroupEditorState } from '../group/store/state/group-editor.state';
import { GroupDialogState } from '../group/store/state/group-dialog.state';
import { RightEditorState } from '../right/store/state/right-editor.state';
import { RightDialogState } from '../right/store/state/right-dialog.state';
import { ApplicationEventsState } from './state/application-events.state';

export const editorStateKey = 'editor';

export interface EditorState {
    tabs: Tab[];
    actions: ActionEditorState;
    actionDialog: ActionDialogState;
    actionContexts: ActionContextState;
    api: ApiEditorState;
    apiDialog: ApiDialogState;
    data: DataEditorState;
    dataDialog: DataDialogState;
    dataformat: DataFormatEditorState;
    dataformatDialog: DataFormatDialogState;
    media: MediaEditorState;
    mediaDialog: MediaDialogState;
    designSystem: DesignSystemEditorState;
    designSystemDialog: DesignSystemDialogState;
    group: GroupEditorState;
    groupDialog: GroupDialogState;
    right: RightEditorState;
    rightDialog: RightDialogState;
    template: TemplateEditorState;
    templateContext: TemplatesContextEditorState;
    templateDialog: TemplateDialogState;
    job: JobEditorState;
    jobDialog: JobDialogState;
    partType: PartTypeEditorState;
    applicationEvents: ApplicationEventsState;
}

export interface Tab {
    active: boolean;
    id: string;
    index: number;
    name: string | undefined;
    type:
        | 'action'
        | 'api'
        | 'data'
        | 'data-format'
        | 'media'
        | 'template'
        | 'job'
        | 'right'
        | 'group'
        | 'designsystem'
        | 'application-overview';
    typeId: string;
    icon?: string | undefined;
    arguments?: { key: string; value: string }[];
}
