<ng-container [formGroup]="formGroup">
    <div class="mb-2 input-button-container">
        <textarea
            class="small-input scrollbar scrollbar-primary"
            type="text"
            id="{{ this._argument.id }}"
            autocomplete="off"
            formControlName="value"
            [rows]="rows"
            [style.height]="textareaHeight"></textarea>
        <svg
            class="icon input-button !h-5 !w-5 !right-2 z-[10000]"
            [showTrigger]="NgxFloatUiTriggers.hover"
            [floatUi]="actionNamePopover"
            appendTo="body"
            [preventOverflow]="false"
            [boundariesElement]="'rootBoundary'"
            [positionFixed]="true"
            [placement]="NgxFloatUiPlacements.RIGHT"
            [appendTo]="'body'"
            (click)="changeRows()">
            <use href="#expand" class="ui-element"></use>
        </svg>
        <float-ui-content #actionNamePopover>
            <div class="popover navigation-info">
                <div class="draggable-card-popover-title-without-text">
                    <h3 class="draggable-card-popover-title-element">Make this field bigger/smaller</h3>
                </div>
            </div>
        </float-ui-content>
    </div>
</ng-container>
