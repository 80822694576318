import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Action } from '@backoffice/editor/data-access/action';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { LoggerService } from '@backoffice/utils';
import { Invocation } from '@backoffice/data-access/editor';
import { backofficeEnvironment } from '@shared/environment';

@Component({
    selector: 'codex-action-edit-invocation-properties',
    templateUrl: './action-edit-invocation-properties.component.html',
    styleUrls: ['./action-edit-invocation-properties.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class ActionEditInvocationPropertiesComponent implements OnInit, OnDestroy {
    @Input()
    set invocation(invocation: Invocation) {
        this._invocation = invocation;
        if (this._invocation && this._currentOpenedInvocation !== this._invocation.id) {
            this.updateForm();
            this._currentOpenedInvocation = this.invocation.id;
        }
    }

    _invocation: Invocation;
    _currentOpenedInvocation: string;

    @Input()
    action: Action;

    @Output()
    invocationUpdated: EventEmitter<{ invocation: Invocation; action: Action }> = new EventEmitter<{
        invocation: Invocation;
        action: Action;
    }>();

    formGroup: FormGroup;

    subscriptions: Subscription = new Subscription();

    constructor(
        public changeDetectorRef: ChangeDetectorRef,
        private fb: FormBuilder,
        private log: LoggerService
    ) {}

    ngOnInit(): void {
        this.initForm();
    }

    ngOnDestroy(): void {
        setTimeout(() => {
            this.subscriptions.unsubscribe();
        }, backofficeEnvironment.autosavedebounce + 100);
    }

    updateForm(): void {
        this.formGroup?.get('name')?.setValue(this._invocation.name, { emitEvent: false });
        this.formGroup?.get('iconName')?.setValue(this._invocation.iconName, { emitEvent: false });
        this.formGroup?.get('description')?.setValue(this._invocation.description, { emitEvent: false });
    }

    initForm(): void {
        this.formGroup = this.fb.group({
            name: [this._invocation.name, Validators.required],
            iconName: [this._invocation.iconName, Validators.required],
            description: [this._invocation.description],
        });

        this.subscriptions.add(
            this.formGroup
                .get('name')
                ?.valueChanges.pipe(debounceTime(backofficeEnvironment.inputdebounce))
                .subscribe(value => this.onChangeName(value))
        );
        this.subscriptions.add(
            this.formGroup
                .get('description')
                ?.valueChanges.pipe(debounceTime(backofficeEnvironment.inputdebounce))
                .subscribe(value => this.onChangeDescription(value))
        );
    }

    onChangeName(newName: string): void {
        this.log.debug('(invocation properties) on change name');
        if (this._invocation.name !== newName) {
            this._invocation.name = newName;
            this.invocationUpdated.emit({ invocation: this._invocation, action: this.action });
        }
    }

    onChangeDescription(newDescription: string): void {
        this.log.debug('(invocation properties) on change description');
        if (this._invocation.description !== newDescription) {
            this._invocation.description = newDescription;
            this.invocationUpdated.emit({ invocation: this._invocation, action: this.action });
        }
    }

    onChangeIconName(invocation: Invocation, $event): void {
        this.log.debug('(invocation properties) on change icon');
        if (this._invocation.iconName !== $event) {
            this._invocation.iconName = $event;
            this.invocationUpdated.emit({ invocation: this._invocation, action: this.action });
        }
    }
}
