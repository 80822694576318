import { NgModule } from '@angular/core';
import { SearchMediaDialogComponent } from './dialogs/search-media-dialog/search-media-dialog.component';
import { BackofficeDataAccessEditorModule } from '@backoffice/data-access/editor';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MediaOverviewRowComponent } from './components/media-overview-row/media-overview-row.component';
import { V2SharedModule } from '../../../../../../apps/no-code-x-backoffice/src/app/v2-shared/v2-shared.module';
import { CommonsModule } from '../../../../../../apps/no-code-x-backoffice/src/app/common/common.module';
import { MediaCreateFormComponent } from './components/media-create-form/media-create-form.component';
import { MediaEditFormComponent } from './components/media-edit-form/media-edit-form.component';
import { V2TagModule } from '../../../../../../apps/no-code-x-backoffice/src/app/v2-tag/v2-tag.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { DataCreateFormComponent } from './components/data-create-form/data-create-form.component';
import { DataEditFormComponent } from './components/data-edit-form/data-edit-form.component';
import { DataOverviewRowComponent } from './components/data-overview-row/data-overview-row.component';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { SearchDataDialogComponent } from './dialogs/search-data-dialog/search-data-dialog.component';
import { ActionOverviewRowComponent } from './components/action-overview-row/action-overview-row.component';
import { SearchActionDialogComponent } from './dialogs/search-action-dialog/search-action-dialog.component';
import { SearchDataformatDialogComponent } from './dialogs/search-dataformat-dialog/search-dataformat-dialog.component';
import { SearchDesignsystemDialogComponent } from './dialogs/search-designsystem-dialog/search-designsystem-dialog.component';
import { SearchJobDialogComponent } from './dialogs/search-job-dialog/search-job-dialog.component';
import { SearchTemplateDialogComponent } from './dialogs/search-template-dialog/search-template-dialog.component';
import { JobEditComponent } from './components/job-edit/job-edit.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { JobOverviewRowComponent } from './components/job-overview-row/job-overview-row.component';
import { TemplateOverviewRowComponent } from './components/template-overview-row/template-overview-row.component';
import { ApiOverviewRowComponent } from './components/api-overview-row/api-overview-row.component';
import { DataformatOverviewRowComponent } from './components/dataformat-overview-row/dataformat-overview-row.component';
import { SearchApiDialogComponent } from './dialogs/search-api-dialog/search-api-dialog.component';
import { BackofficeUiArgumentsModule } from '@backoffice/ui-arguments';
import { BackofficeUiSearchModule } from '@backoffice/ui/search';
import { BackofficeFeatureEditorPickersModule } from 'backoffice/feature/editor/pickers';
import { DesignsystemOverviewRowComponent } from './components/design-system-item-overview-row/designsystem-overview-row.component';
import { SearchRightDialogComponent } from './right/dialogs/search-right-dialog/search-right-dialog.component';
import { SearchGroupDialogComponent } from './group/dialogs/search-group-dialog/search-group-dialog.component';
import { RightOverviewRowComponent } from './right/components/right-overview-row/right-overview-row.component';
import { GroupOverviewRowComponent } from './group/components/group-overview-row/group-overview-row.component';
import { ActionSettingsComponent } from './action/components/action-settings/action-settings.component';
import { ActionEditPropertiesComponent } from './action/components/action-edit-properties/action-edit-properties.component';
import { ActionToolPaneComponent } from './action/components/action-tool-pane/action-tool-pane.component';
import { ActionEditOutputsComponent } from './action/components/action-edit-outputs/action-edit-outputs.component';
import { ActionEditOutputComponent } from './action/components/action-edit-output/action-edit-output.component';
import { ActionEditInvocationArgumentsComponent } from './arguments/arguments/action-edit-invocation-arguments.component';
import { HexCharactersDirective } from '../../../../feature/editor/pickers/src/lib/color-picker-v2/hex-characters-directive';
import { ParameterComponent } from './parameters/parameter/parameter.component';
import { ParametersComponent } from './parameters/parameters/parameters.component';
import { SelectParameterComponent } from './parameters/select-parameter/select-parameter.component';
import { SelectParametersComponent } from './parameters/select-parameters/select-parameters.component';
import { ParameterOptionsComponent } from './parameters/parameter-options/parameter-options.component';
import { ParameterPickerComponent } from './parameters/parameter-picker/parameter-picker.component';
import { ArgumentScopePickerComponent } from './arguments/argument-scope-picker/argument-scope-picker.component';
import { ArgumentPartPickerComponent } from './arguments/argument-part-picker/argument-part-picker.component';
import { ArgumentLayerPickerComponent } from './arguments/argument-layer-picker/argument-layer-picker.component';
import { ArgumentTemplatePickerComponent } from './arguments/argument-template-picker/argument-template-picker.component';
import { ArgumentActionPickerComponent } from './arguments/argument-action-picker/argument-action-picker.component';
import { ArgumentNumberInputComponent } from './arguments/argument-number-input/argument-number-input.component';
import { ArgumentStringInputComponent } from './arguments/argument-string-input/argument-string-input.component';
import { ArgumentSelectOneInputComponent } from './arguments/argument-select-one-input/argument-select-one-input.component';
import { ArgumentSelectMultiInputComponent } from './arguments/argument-select-multi-input/argument-select-multi-input.component';
import { ArgumentPasswordInputComponent } from './arguments/argument-password-input/argument-password-input.component';
import { ArgumentBooleanInputComponent } from './arguments/argument-boolean-input/argument-boolean-input.component';
import { ArgumentTagPickerComponent } from './arguments/argument-tag-picker/argument-tag-picker.component';
import { ArgumentActionParamsComponent } from './arguments/argument-action-params/argument-action-params.component';
import { ArgumentJsonComponent } from './arguments/argument-json/argument-json.component';
import { ArgumentXmlComponent } from './arguments/argument-xml/argument-xml.component';
import { ArgumentDataformatPickerComponent } from './arguments/argument-dataformat-picker/argument-dataformat-picker.component';
import { ArgumentTemplateParamsComponent } from './arguments/argument-template-params/argument-template-params.component';
import { ArgumentDataformatFiltersComponent } from './arguments/argument-dataformat-filters/argument-dataformat-filters.component';
import { ArgumentDataformatAttributePickerComponent } from './arguments/argument-dataformat-attribute-picker/argument-dataformat-attribute-picker.component';
import { ArgumentDataformatAttributesComponent } from './arguments/argument-dataformat-attributes/argument-dataformat-attributes.component';
import { ActionEditInvocationArgumentActionOutputComponent } from './arguments/argument-action-output/action-edit-invocation-argument-action-output.component';
import { ArgumentDataformatSortOrdersComponent } from './arguments/argument-dataformat-sort-orders/argument-dataformat-sort-orders.component';
import { ArgumentDatabaseConnectionComponent } from './arguments/argument-database-connection/argument-database-connection.component';
import { ArgumentArrayInputComponent } from './arguments/argument-array-input/argument-array-input.component';
import { ArgumentComposedListComponent } from './arguments/argument-composed-list/argument-composed-list.component';
import { ArgumentComposedComponent } from './arguments/argument-composed/argument-composed.component';
import { ArgumentTitleComponent } from './arguments/argument-title/argument-title.component';
import { ArgumentTemplateContractComponent } from './arguments/argument-template-contract/argument-template-contract.component';
import { ArgumentObjectDataformatComponent } from './arguments/argument-object-dataformat/argument-object-dataformat.component';
import { ArgumentObjectFreeComponent } from './arguments/argument-object-free/argument-object-free.component';
import { ArgumentObjectParametersComponent } from './arguments/argument-object-parameters/argument-object-parameters.component';
import { ArgumentObjectComponent } from './arguments/argument-object/argument-object.component';
import { ArgumentArrayComponent } from './arguments/argument-array/argument-array.component';
import { ArgumentArrayFreeComponent } from './arguments/argument-array-free/argument-array-free.component';
import { ArgumentArrayDataformatComponent } from './arguments/argument-array-dataformat/argument-array-dataformat.component';
import { ArgumentArrayParametersComponent } from './arguments/argument-array-parameters/argument-array-parameters.component';
import { ArgumentActionPrototypeComponent } from './arguments/argument-action-prototype/argument-action-prototype.component';
import { ArgumentValuePickerComponent } from './arguments/argument-value-picker/argument-value-picker.component';
import { ActionEditInvocationPropertiesComponent } from './action/components/action-edit-invocation-properties/action-edit-invocation-properties.component';
import { ActionEditInvocationOutputsComponent } from './action/components/action-edit-invocation-outputs/action-edit-invocation-outputs.component';
import { ActionEditInvocationNextinvocationsComponent } from './action/components/action-edit-invocation-nextinvocations/action-edit-invocation-nextinvocations.component';
import { ActionEditScopeDialogComponent } from './action/components/action-edit-scope-dialog/action-edit-scope-dialog.component';
import { ActionEditConditionalsComponent } from './action/components/action-edit-conditionals/action-edit-conditionals.component';
import { ActionEditConditionalComponent } from './action/components/action-edit-conditional/action-edit-conditional.component';
import { ActionInvocationArgumentsComponent } from './action/components/action-invocation-arguments/action-invocation-arguments.component';
import { EditGroupFormComponent } from './group/components/edit-group-form/edit-group-form.component';
import { EditRoleFormComponent } from './right/components/edit-role-form/edit-role-form.component';
import { ApiEditFormComponent } from './api/api-edit-form/api-edit-form.component';
import { ApiCreateFormComponent } from './api/api-create-form/api-create-form.component';
import { DataformatCreateFormComponent } from './dataformat/dataformat-create-form/dataformat-create-form.component';
import { NgxUploaderModule } from 'ngx-uploader';
import { ActionCreateFormComponent } from './action/components/action-create-form/action-create-form.component';
import { ArgumentObjectMappingComponent } from './arguments/argument-object-mapping/argument-object-mapping.component';
import { ActionTestComponent } from './action/components/action-test/action-test.component';
import { BackofficeFeatureApplicationLogsModule } from '../../../../feature/application-logs/src';
import { ActionOutputsComponent } from './action/components/action-outputs/action-outputs.component';
import { ActionCreateAssertionComponent } from './action/components/action-create-assertion/action-create-assertion.component';
import { ActionLinksComponent } from './action/components/action-links/action-links.component';
import { ArgumentAgentComponent } from './arguments/argument-agent/argument-agent.component';
import { ArgumentTaskComponent } from './arguments/argument-task/argument-task.component';
import { GenerativeComponent } from './generative/generative.component';
import { GenerativeAiMessageTextComponent } from '../../../../feature/editor/src/lib/pages/generative-ai-message/generative-ai-message-text/generative-ai-message-text.component';
import { GenerativeSenderMessageTextComponent } from '../../../../feature/editor/src/lib/pages/generative-sender-message/generative-sender-message-text/generative-sender-message-text.component';
import { MarkdownModule } from 'ngx-markdown';
import { ApplicationOverviewComponent } from './overview/components/application-overview.component';

@NgModule({
    declarations: [
        ActionOverviewRowComponent,
        ApiOverviewRowComponent,
        DataCreateFormComponent,
        DataEditFormComponent,
        DataOverviewRowComponent,
        DataformatOverviewRowComponent,
        DesignsystemOverviewRowComponent,
        JobEditComponent,
        JobOverviewRowComponent,
        MediaCreateFormComponent,
        MediaEditFormComponent,
        RightOverviewRowComponent,
        GroupOverviewRowComponent,
        SearchActionDialogComponent,
        SearchApiDialogComponent,
        SearchDataformatDialogComponent,
        SearchDesignsystemDialogComponent,
        SearchDataDialogComponent,
        SearchJobDialogComponent,
        SearchMediaDialogComponent,
        SearchTemplateDialogComponent,
        SearchRightDialogComponent,
        SearchGroupDialogComponent,
        TemplateOverviewRowComponent,
        MediaOverviewRowComponent,
        ActionSettingsComponent,
        ActionTestComponent,
        ActionLinksComponent,
        ActionOutputsComponent,
        ActionEditPropertiesComponent,
        ActionToolPaneComponent,
        ActionEditOutputsComponent,
        ActionEditOutputComponent,
        ParameterComponent,
        ParametersComponent,
        SelectParameterComponent,
        SelectParametersComponent,
        ParameterOptionsComponent,
        ParameterPickerComponent,
        ArgumentScopePickerComponent,
        ArgumentPartPickerComponent,
        ArgumentLayerPickerComponent,
        ArgumentTemplatePickerComponent,
        ArgumentActionPickerComponent,
        ArgumentNumberInputComponent,
        ArgumentStringInputComponent,
        ArgumentSelectOneInputComponent,
        ArgumentSelectMultiInputComponent,
        ArgumentPasswordInputComponent,
        ArgumentBooleanInputComponent,
        ArgumentTagPickerComponent,
        ArgumentActionParamsComponent,
        ArgumentJsonComponent,
        ArgumentXmlComponent,
        ArgumentDataformatPickerComponent,
        ArgumentTemplateParamsComponent,
        ArgumentDataformatFiltersComponent,
        ArgumentDataformatAttributePickerComponent,
        ArgumentDataformatAttributesComponent,
        ActionEditInvocationArgumentActionOutputComponent,
        ArgumentDataformatSortOrdersComponent,
        ArgumentDatabaseConnectionComponent,
        ArgumentArrayInputComponent,
        ArgumentComposedListComponent,
        ArgumentComposedComponent,
        ActionEditInvocationArgumentsComponent,
        ArgumentTitleComponent,
        ArgumentTemplateContractComponent,
        ArgumentObjectDataformatComponent,
        ArgumentObjectFreeComponent,
        ArgumentObjectParametersComponent,
        ArgumentObjectComponent,
        ArgumentArrayComponent,
        ArgumentArrayFreeComponent,
        ArgumentArrayDataformatComponent,
        ArgumentArrayParametersComponent,
        ArgumentActionPrototypeComponent,
        HexCharactersDirective,
        ArgumentValuePickerComponent,
        ActionEditInvocationPropertiesComponent,
        ActionEditInvocationOutputsComponent,
        ActionEditInvocationNextinvocationsComponent,
        ActionEditScopeDialogComponent,
        ActionEditConditionalsComponent,
        ActionEditConditionalComponent,
        ActionInvocationArgumentsComponent,
        EditGroupFormComponent,
        EditRoleFormComponent,
        ApiEditFormComponent,
        ApiCreateFormComponent,
        DataformatCreateFormComponent,
        ActionCreateFormComponent,
        ArgumentObjectMappingComponent,
        ActionCreateAssertionComponent,
        ArgumentAgentComponent,
        ArgumentTaskComponent,
        GenerativeComponent,
        GenerativeAiMessageTextComponent,
        GenerativeSenderMessageTextComponent,
        ApplicationOverviewComponent,
    ],
    exports: [
        DataEditFormComponent,
        JobEditComponent,
        MediaEditFormComponent,
        DataCreateFormComponent,
        MediaCreateFormComponent,
        SearchMediaDialogComponent,
        SearchDataDialogComponent,
        SearchApiDialogComponent,
        SearchActionDialogComponent,
        SearchDataformatDialogComponent,
        SearchDesignsystemDialogComponent,
        SearchJobDialogComponent,
        SearchTemplateDialogComponent,
        SearchRightDialogComponent,
        SearchGroupDialogComponent,
        ActionToolPaneComponent,
        ParametersComponent,
        ParameterPickerComponent,
        ArgumentValuePickerComponent,
        EditGroupFormComponent,
        EditRoleFormComponent,
        ApiEditFormComponent,
        ActionEditConditionalsComponent,
        ActionEditPropertiesComponent,
        ActionEditOutputsComponent,
        GenerativeComponent,
        ApplicationOverviewComponent,
    ],
    imports: [
        BackofficeDataAccessEditorModule,
        BackofficeFeatureEditorPickersModule,
        BackofficeUiArgumentsModule,
        BackofficeUiSearchModule,
        BackofficeFeatureApplicationLogsModule,
        CommonsModule,
        MatButtonModule,
        MatCardModule,
        MatIconModule,
        MatInputModule,
        MatFormFieldModule,
        MatPaginatorModule,
        MatTooltipModule,
        MonacoEditorModule,
        V2SharedModule,
        V2TagModule,
        NgxUploaderModule,
        MarkdownModule,
    ],
})
export class BackofficeUiEditorModule {}
