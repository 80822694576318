<form
    (ngSubmit)="onCreateNewApplicationVersion()"
    *ngIf="formGroup"
    [cdkTrapFocusAutoCapture]="true"
    [formGroup]="formGroup"
    cdkTrapFocus
    novalidate>
    <label for="application-name" class="small-input-label" data-name="application-name">{{
        'v2.application.edit.name' | translate
    }}</label>
    <input
        class="small-input"
        type="text"
        id="application-name"
        name="application-name"
        autocomplete="off"
        required
        formControlName="name" />
    <p class="small-input-error" *ngIf="this.formGroup.get('name').invalid && this.formGroup.get('name').errors.required">
        {{ 'v2.applicationversion.edit.name.error.required' | translate }}
    </p>
    <p class="small-input-error" *ngIf="this.formGroup.get('name').invalid && this.formGroup.get('name').errors.nameAlreadyExists">
        {{ 'v2.applicationversion.edit.name.error.alreadyexists' | translate }}
    </p>

    <label for="application-description" class="small-input-label" data-name="application-description">{{
        'v2.application.edit.description' | translate
    }}</label>
    <textarea
        class="small-input"
        type="text"
        id="application-description"
        name="application-description"
        autocomplete="off"
        formControlName="description"></textarea>

    <div class="buttonContainer">
        <button [disabled]="!formGroup.valid" class="primary-button button-large" data-name="save" type="submit">
            <mat-icon>save</mat-icon>
            {{ 'general.save' | translate }}
        </button>
        <button (click)="this.onHide.emit({ created: false })" class="secondary-button button-large" data-name="cancel" type="button">
            <mat-icon>cancel</mat-icon>
            {{ 'general.cancel' | translate }}
        </button>
    </div>
</form>
