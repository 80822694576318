import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';

import { catchError, concatMap, debounceTime, filter, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import {
    actionDialogFacetsChanged,
    actionDialogFilterPluginsChanged,
    actionDialogPaginationChanged,
    actionDialogSearchTermChanged,
    clearActionDialogFilter,
    createActionSuccess,
    deleteAction,
    deleteActions,
    deleteActionsSuccess,
    deleteActionSuccess,
    fetchActionError,
    fetchActionSuccess,
    findAction,
    loadActionDialogData,
    loadActionDialogSuccess,
    updateAction,
    updateActionSuccess,
} from '../actions/action-editor.actions';
import { ActionService } from '../../../../../../editor/data-access/action/src/lib/services/action.service';
import { Action } from '../../action/model/action';
import { AppFacade } from '@core/facades/app.facade';
import { Store } from '@ngrx/store';
import { EditorState } from '../editor.state';
import { actionEditorSelectors } from '../selectors/action-editor.selectors';
import { closeTab, closeTabs, registerTab, updateTab } from '../actions/editor.actions';
import {
    calculateScope,
    fetchActionContextSuccess,
    fetchActionMethodsSuccess,
    findActionCalledActions,
    findActionContext,
    findActionMethod,
    updateActionScope,
    updateCalledActions,
} from '../actions/action-context.actions';
import { OverviewActionDto, toFilter } from '@backoffice/data-access/editor';
import { ActionContextFacade } from '../facades/action-context.facade';
import { ActionEditorFacade } from '../facades/action-editor.facade';
import { forkJoin, of } from 'rxjs';
import { backofficeEnvironment } from '@shared/environment';
import { LoggerService } from '@backoffice/utils';
import { Page } from '@shared/data-access';
import { ActionContext } from '../../interfaces/action-context.interface';
import {
    refreshUserAchievements,
    requestUserAchievements,
    selectApplicationSuccess,
} from '../../../../../../../../apps/no-code-x-backoffice/src/app/store/context/context.actions';
import { driver } from 'driver.js';
import { AchievementsService } from '@core/services/achievements.service';

@Injectable()
export class ActionEffects {
    constructor(
        protected readonly actions$: Actions,
        private readonly store: Store<EditorState>,
        private readonly appFacade: AppFacade,
        private readonly actionService: ActionService,
        private readonly actionContextFacade: ActionContextFacade,
        private readonly editorFacade: ActionEditorFacade,
        private readonly achievementsService: AchievementsService,
        private readonly log: LoggerService
    ) {}

    applicationChange$ = createEffect(() =>
        this.actions$.pipe(
            ofType(selectApplicationSuccess),
            map(() => clearActionDialogFilter())
        )
    );

    bulkDelete$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteActions),
            concatLatestFrom(() => [this.appFacade.selectedCompany, this.appFacade.selectedApplication]),
            switchMap(([{ ids }, { id: companyId }, { id: applicationId }]) =>
                forkJoin(ids.map(id => this.actionService.delete(id, companyId, applicationId))).pipe(map(() => ids))
            ),
            map((ids: string[]) => deleteActionsSuccess({ ids }))
        )
    );

    closeTab$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteActionSuccess),
            map(({ id }) => closeTab({ typeId: id, tabType: 'action' }))
        )
    );

    closeTabs$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteActionsSuccess),
            map(({ ids }) => closeTabs({ typeIds: ids, tabType: 'action' }))
        )
    );

    delete$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteAction),
            concatLatestFrom(() => [this.appFacade.selectedCompany, this.appFacade.selectedApplication]),
            concatMap(([{ id }, { id: companyId }, { id: applicationId }]) =>
                this.actionService.delete(id, companyId, applicationId).pipe(map(() => deleteActionSuccess({ id })))
            )
        )
    );

    fetch$ = createEffect(() =>
        this.actions$.pipe(
            ofType(findAction),
            concatLatestFrom(({ id }) => [
                this.appFacade.selectedCompany,
                this.appFacade.selectedApplication,
                this.store.select(actionEditorSelectors.byId(id)),
            ]),
            filter(([_action, company, application, detail]) => !!company && !!application),
            mergeMap(([{ id }, { id: companyId }, { id: applicationId }, detail]) => {
                if (detail && !detail.invalidated) {
                    return of(fetchActionSuccess({ action: detail }));
                } else if (!detail) {
                    return this.actionService.findById(id, companyId, applicationId).pipe(
                        map(result => fetchActionSuccess({ action: result })),
                        catchError(() => of(fetchActionError({ action: Action.createDeleted(id) })))
                    );
                } else if (detail.invalidated) {
                    return this.actionService.findById(id, companyId, applicationId).pipe(
                        map(result => {
                            console.log('jajaj backend is updated');
                            result.backEndUpdate = true;
                            return updateActionSuccess({ action: result });
                        }),
                        catchError(() => of(fetchActionError({ action: Action.createDeleted(id) })))
                    );
                } else {
                    return of(fetchActionError({ action: Action.createDeleted(id) }));
                }
            })
        )
    );

    fetchContext$ = createEffect(() =>
        this.actions$.pipe(
            ofType(findActionContext),
            debounceTime(500),
            concatLatestFrom(({ action }) => [this.appFacade.selectedCompany, this.appFacade.selectedApplication]),
            filter(([_action, company, application]) => !!company && !!application),
            mergeMap(([{ action }, { id: companyId }, { id: applicationId }]) =>
                this.actionService.findContextById(action.id, companyId, applicationId).pipe(
                    take(1),
                    map(result =>
                        fetchActionContextSuccess({
                            action,
                            actionContext: result,
                        })
                    )
                )
            )
        )
    );

    fetchDialogData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                deleteActionsSuccess,
                loadActionDialogData,
                actionDialogPaginationChanged,
                actionDialogSearchTermChanged,
                actionDialogFacetsChanged,
                actionDialogFilterPluginsChanged
            ),
            concatLatestFrom(() => [this.appFacade.selectedCompany, this.appFacade.selectedApplication, this.editorFacade.filter]),
            switchMap(([_, { id: companyId }, { id: applicationId }, { page, maxResults, searchTerm, facets, filterPlugins }]) =>
                this.actionService.findAll(companyId, applicationId, {
                    page,
                    maxResults,
                    keyword: searchTerm,
                    filters: toFilter(facets, filterPlugins),
                })
            ),
            map((data: Page<OverviewActionDto>) => loadActionDialogSuccess({ data }))
        )
    );

    recalculateMethods$ = createEffect(() =>
        this.actions$.pipe(
            ofType(findActionMethod),
            debounceTime(500),
            concatLatestFrom(({ action: Action }) => [this.appFacade.selectedCompany, this.appFacade.selectedApplication]),
            mergeMap(([{ action }, company, application]) =>
                this.actionService.findMethodsByReferenceId(action.id, company.id, application.id).pipe(
                    take(1),
                    map(result =>
                        fetchActionMethodsSuccess({
                            action: action,
                            methods: result,
                        })
                    )
                )
            )
        )
    );

    recalculateCalledActions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(findActionCalledActions),
            debounceTime(500),
            map(({ action }) => {
                const calledActionIds = new Set<string>();
                action.program.invocations.forEach(invocation => {
                    if (invocation && invocation.invocationOutputs) {
                        invocation.invocationOutputs.forEach(invocationOutput => {
                            if (!!invocationOutput.subOutputsForAction && !invocationOutput.subOutputsForAction.startsWith('{')) {
                                calledActionIds.add(invocationOutput.subOutputsForAction);
                            }
                        });
                    }
                });
                return updateCalledActions({
                    id: action.id,
                    calledActions: Array.from(calledActionIds.values()),
                });
            })
        )
    );

    recalculateScope$ = createEffect(() =>
        this.actions$.pipe(
            ofType(calculateScope),
            debounceTime(500),
            concatLatestFrom(({ action }) => [
                this.actionContextFacade.getActionContext(action.id).pipe(
                    take(1),
                    filter((actionContext: ActionContext | undefined): actionContext is ActionContext => {
                        return !!actionContext && !!actionContext.actionCtxs && !!actionContext.methods && !!actionContext.language;
                    })
                ),
            ]),
            mergeMap(([{ action }, actionContext]) => {
                return this.actionContextFacade.getActionCalledActions(action.id).pipe(
                    take(1),
                    switchMap(calledActions => {
                        return this.actionContextFacade
                            .loadActionScope(action, actionContext.methods, calledActions, actionContext.actionCtxs, actionContext.language)
                            .pipe(
                                map(scope =>
                                    updateActionScope({
                                        id: action.id,
                                        scope,
                                    })
                                )
                            );
                    })
                );
            })
        )
    );

    update$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateAction),
            debounceTime(backofficeEnvironment.autosavedebounce),
            concatLatestFrom(() => [this.appFacade.selectedCompany, this.appFacade.selectedApplication]),
            filter(([{ action }, company, application]) => action.isValid() && !!company && !!application),
            switchMap(([{ action }, { id: companyId }, { id: applicationId }]) =>
                this.actionService.update(action, companyId, applicationId).pipe(map(() => updateActionSuccess({ action })))
            )
        )
    );

    openTab$ = createEffect(() =>
        this.actions$.pipe(
            ofType(createActionSuccess),
            map(({ id }) => registerTab({ definition: { type: 'action', typeId: id } }))
        )
    );

    updateTab$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateActionSuccess),
            map(({ action }) => {
                const { id: typeId, name, iconName: icon } = action;
                return updateTab({ definition: { type: 'action', typeId, name, icon } });
            })
        )
    );

    activateActionOnboarding$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(createActionSuccess),
                concatLatestFrom(() => [this.appFacade.user, this.appFacade.context]),
                tap(([{ id }, userContext, context]) => {
                    let genericOnboardingAchievement = userContext.achievements?.find(
                        achievement => achievement.code === 'ACTION_ONBOARDING'
                    );
                    if (!genericOnboardingAchievement) {
                        const driverObj = driver({
                            showProgress: true,
                            onDestroyed: () => {
                                this.achievementsService
                                    .createAchievement(userContext.id, 'ACTION_ONBOARDING')
                                    .pipe(take(1))
                                    .subscribe(() => {
                                        this.store.dispatch(refreshUserAchievements({ info: userContext }));
                                    });
                            },
                            steps: [
                                {
                                    popover: {
                                        title: 'Getting Started with Logic Building',
                                        description:
                                            '<img src="images/theme/guide.gif" width="300" style="height: 300px; margin:auto;"/> ' +
                                            '<br></br>Getting started with logic building in NoCode-X can feel overwhelming due to the many possibilities at your disposal. But don’t worry—we’re here to guide you through the basics. We’ll explain everything step by step!',
                                        popoverClass: 'driver-intro',
                                    },
                                },
                                {
                                    element: 'foreignObject .invocation',
                                    popover: {
                                        title: 'Start block (1/2)',
                                        description:
                                            "Every action (or logic) begins with a Start Block. This block is permanent and cannot be deleted. As you build your logic, you'll connect other blocks to it. Keep in mind that execution always begins here, flowing sequentially from one block to the next.",
                                    },
                                },
                                {
                                    element: 'foreignObject .invocation',
                                    popover: {
                                        title: 'Start block (2/2)',
                                        description:
                                            '<img src="images/theme/start_block.gif" width="300" style="height: 300px; margin:auto;"/> ' +
                                            '<br></br>Clicking the Start Block allows you to customize its settings. You can modify its name and description, add parameters for incoming data, and define outputs for data flowing out of the action.',
                                    },
                                },
                                {
                                    element: 'codex-method-picker',
                                    popover: {
                                        title: 'Function picker',
                                        description:
                                            'Functions are the core components of logic, and you can combine them in any way you like. The function selector offers a wide range of functions to choose from.',
                                    },
                                },
                                {
                                    element: 'codex-method-picker .drawer-search-section input',
                                    popover: {
                                        title: 'Function filter',
                                        description:
                                            'Too many functions too browse? We understand there are a lot! Use the filter function to quickly find what you are looking for.',
                                    },
                                },
                                {
                                    element: 'codex-method-picker-items .draggables-grid button:first-of-type',
                                    popover: {
                                        title: 'Function',
                                        description:
                                            '<img src="images/theme/drag_drop_function.gif" width="300" style="height: 300px; margin:auto;"/> ' +
                                            '<br></br>Choose the function that will do the job & drag & drop it into place',
                                    },
                                },
                                {
                                    popover: {
                                        title: 'Connect it up!',
                                        description:
                                            '<img src="images/theme/hook_em_up.gif" width="300" style="height: 300px; margin:auto;"/> ' +
                                            "<br></br>Connect up your blocks, configure it according to your needs & you're ready to go!",
                                        popoverClass: 'driver-intro',
                                    },
                                },
                                {
                                    element: '.extra-menu',
                                    popover: {
                                        title: 'Controls',
                                        description:
                                            '<p>Within your application, you can build using the following concepts:</p>\n' +
                                            '<ul>\n' +
                                            '  <li><strong>Build templates</strong></li>' +
                                            '  <li><strong>Build APIs</strong></li>' +
                                            '  <li><strong>Build Actions</strong></li>' +
                                            '  <li><strong>Build Data-formats</strong></li>' +
                                            '  <li><strong>Store/Use Data</strong></li>' +
                                            '  <li><strong>Store/Use Media</strong></li>' +
                                            '  <li><strong>Build Scheduled jobs</strong></li>' +
                                            '  <li><strong>Create/Use Groups/Rights</strong></li>' +
                                            '  <li><strong>Install plugins from our Hub</strong></li>' +
                                            '</ul><br>' +
                                            '<p>To navigate between these different building concepts within NoCode-X, use the side navigation.</p>',
                                    },
                                },
                                {
                                    element: 'codex-top-right-navigation div[data-name="application-logs"]',
                                    popover: {
                                        title: 'Application logs',
                                        description:
                                            'Templates in NoCode-X are the foundation for building pages and reusable elements (which can be used across pages). They allow you to design and structure your web applications efficiently through our visual editor.',
                                    },
                                },
                                {
                                    element: 'codex-top-right-navigation div[data-name="audit-logs"]',
                                    popover: {
                                        title: 'Application logs',
                                        description:
                                            'Templates in NoCode-X are the foundation for building pages and reusable elements (which can be used across pages). They allow you to design and structure your web applications efficiently through our visual editor.',
                                    },
                                },
                            ],
                        });
                        driverObj.drive();
                    }
                })
            ),
        { dispatch: false }
    );
}
