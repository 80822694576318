<mat-dialog-content class="min-h-[95vh] !p-4 scrollbar-primary scrollbar flex flex-col">
    <div class="title-button-header">
        <h1 class="!mb-2">Test: {{ action.name }}</h1>
        <div class="title-button-header-buttons">
            <button class="primary-button button-medium" (click)="createNewTest()" type="button">
                <mat-icon>add_circle</mat-icon>
                Create new test
            </button>
        </div>
    </div>
    <div class="flex row gap-4 grow">
        <div class="w-1/2 p-2 border-r border-light-border dark:border-dark-border">
            <div *ngIf="(actionTests$ | async)?.length === 0" class="flex-col flex justify-center items-center">
                <img class="w-[350px] h-[150px] object-cover" src="/src/images/theme/no_results_found.gif" />
                <h1 class="text-center !mb-2">We couldn't find any tests for this action.</h1>
                <p class="!mb-8 text-white">Start adding tests & test out your logic repeatedly.</p>
                <button type="button" class="primary-button button-extra-large" (click)="createNewTest()">
                    <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h14m-7 7V5" />
                    </svg>
                    Create new test
                </button>
            </div>
            <ng-container *ngIf="(actionTests$ | async)?.length !== 0">
                <div class="flex justify-end mb-2">
                    <button class="primary-button button-medium" (click)="executeAllTests()" type="button">
                        <mat-icon>add_circle</mat-icon>
                        Execute all tests
                    </button>
                </div>
                <div id="accordion-action-selection" data-accordion="collapse" data-active-classes="none" data-inactive-classes="none">
                    <ng-container *ngFor="let actionTest of actionTests$ | async; trackBy: identifyTest">
                        <h3 [attr.id]="'accordion-action-tests-' + actionTest.id" [attr.data-name]="'edge-header'" class="pt-0">
                            <button
                                type="button"
                                class="alt-accordion-heading"
                                [attr.data-accordion-target]="'#accordion-action-tests-body-' + actionTest.id"
                                [attr.aria-controls]="'accordion-action-tests-body-' + actionTest.id"
                                (click)="this.selectedActionTest = actionTest.id; onSelectActionTest()"
                                aria-expanded="false">
                                <svg
                                    *ngIf="!this.latestResults.get(actionTest.id)?.assertionFailed"
                                    floatUi="Assertion succeeded"
                                    [applyClass]="'tooltip'"
                                    appendTo="body"
                                    [showTrigger]="NgxFloatUiTriggers.hover"
                                    data-tooltip-target="success-tooltip"
                                    class="icon icon-button"
                                    aria-hidden="true"
                                    focusable="false">
                                    <use href="#check-success" class="ui-element !stroke-dark-success"></use>
                                </svg>
                                <svg
                                    *ngIf="this.latestResults.get(actionTest.id)?.assertionFailed"
                                    floatUi="Assertion failed"
                                    [applyClass]="'tooltip'"
                                    appendTo="body"
                                    [showTrigger]="NgxFloatUiTriggers.hover"
                                    class="icon icon-button"
                                    aria-hidden="true"
                                    focusable="false">
                                    <use href="#check-failed" class="ui-element !stroke-dark-destructive_button-background"></use>
                                </svg>
                                <span class="grow shrink text-left">{{ actionTest.name }}</span>
                                <button
                                    class="accordion-heading-button destructive-accordion-heading-button"
                                    (click)="onDeleteActionTest(actionTest.id)"
                                    [matTooltip]="'Delete test'">
                                    <mat-icon>clear</mat-icon>
                                </button>
                                <svg
                                    data-accordion-icon
                                    class="w-3 h-3 rotate-180 shrink-0"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 10 6">
                                    <path
                                        stroke="currentColor"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M9 5 5 1 1 5" />
                                </svg>
                            </button>
                        </h3>
                        <div
                            [attr.id]="'accordion-action-tests-body-' + actionTest.id"
                            [attr.data-name]="'edge-content'"
                            [attr.aria-labelledby]="'accordion-action-tests-body-' + actionTest.id"
                            class="hidden p-4 scrollbar scrollbar-primary">
                            <label for="test-name" class="small-input-label" data-name="test-name">Test name</label>
                            <input
                                class="small-input"
                                type="text"
                                id="test-name"
                                name="test-name"
                                autocomplete="off"
                                required
                                [(ngModel)]="testName" />
                            <p class="small-input-error" *ngIf="this.testNameShowRequiredMessage">Please provide a test name.</p>
                            <ng-container *ngIf="action.program.parameters && action.program.parameters.length > 0">
                                <h2 class="!mb-2">Arguments</h2>
                                <argument-action-params
                                    class="!mb-2"
                                    *ngIf="action.program.parameters && action.program.parameters.length > 0"
                                    [argument]="mockArgument"
                                    [arguments]="mockArguments"
                                    [contextId]="contextId"
                                    [language]="language"
                                    [root]="mockRootArguments"
                                    [onlyLiteralValues]="true"
                                    [reloadAction]="true"
                                    [scope]="mockScope"
                                    (argumentUpdated)="onArgumentUpdated(actionTest)">
                                </argument-action-params>
                            </ng-container>
                            <label class="toggle-line">
                                Assert on publish
                                <input
                                    type="checkbox"
                                    value=""
                                    class="sr-only peer"
                                    [(ngModel)]="actionTest.assertOnPublish"
                                    [ngModelOptions]="{ standalone: true }" />
                                <div
                                    class="toggle peer-focus:outline-none peer-checked:bg-dark-button-background peer-checked:after:translate-x-full peer rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white"></div>
                            </label>
                            <div class="flex justify-end gap-1">
                                <button class="secondary-button button-medium" (click)="onSaveTest(actionTest)" type="button">
                                    <mat-icon>save</mat-icon>
                                    Save test
                                </button>
                                <button class="primary-button button-medium" (click)="onExecuteTest(actionTest)" type="button">
                                    <mat-icon>cancel</mat-icon>
                                    Execute test
                                </button>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
        </div>
        <div class="w-1/2 p-2">
            <ng-container *ngIf="latestResults.has(this.selectedActionTest)">
                <div class="!mb-2">
                    <h2 class="!mb-2">Logs</h2>
                    <codex-application-log-lines [logs]="logs | async"></codex-application-log-lines>
                </div>
                <div class="!mb-2">
                    <div class="title-button-header">
                        <h2 class="!mb-2">Output/Checks</h2>
                        <div class="title-button-header-buttons">
                            <button class="secondary-button button-medium" (click)="onInitCreateNewAssertion()" type="button">
                                <mat-icon>add_circle</mat-icon>
                                Create new assertion
                            </button>
                        </div>
                    </div>
                    <codex-action-outputs
                        *ngIf="
                            latestResults.get(this.selectedActionTest).outputs &&
                            latestResults.get(this.selectedActionTest).outputs.length > 0
                        "
                        [outputs]="latestResults.get(this.selectedActionTest).outputs"
                        [outputAssertions]="outputAssertions"
                        [assertionResults]="latestResults.get(this.selectedActionTest).assertionResults"
                        (addAssertion)="onAddAssertion()"
                        (editAssertion)="onEditAssertion($event)"
                        (removeAssertion)="onRemoveAssertion($event)"
                        (addOutputAsAssertion)="onAddOutputAsAssertion($event)"></codex-action-outputs>
                    <ng-container
                        *ngIf="
                            !latestResults.get(this.selectedActionTest).outputs ||
                            latestResults.get(this.selectedActionTest).outputs.length === 0
                        ">
                        <p class="small text-white">No outputs set during run.</p>
                    </ng-container>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="flex justify-end gap-1">
        <button class="secondary-button button-medium" (click)="onClose()" type="button">
            <mat-icon>cancel</mat-icon>
            Close
        </button>
    </div>
</mat-dialog-content>
