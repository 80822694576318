import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Argument } from '@backoffice/data-access/editor';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { LoggerService } from '@backoffice/utils';
import { backofficeEnvironment } from '@shared/environment';
import { Scope } from '@backoffice/data-access/editor';
import { NgxFloatUiPlacements, NgxFloatUiTriggers } from 'ngx-float-ui';

@Component({
    selector: 'argument-string-input',
    templateUrl: './argument-string-input.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class ArgumentStringInputComponent implements OnInit, OnDestroy {
    @Input()
    set arguments(argumentList: Argument[]) {
        this._arguments = argumentList;
    }

    _arguments!: Argument[];

    @Input()
    set argument(argument: Argument) {
        this._argument = argument;
    }

    @Input()
    contextId!: string;

    @Input()
    scope!: Scope;

    @Output()
    argumentUpdated: EventEmitter<{ argument: Argument }> = new EventEmitter<{ argument: Argument }>();

    _argument!: Argument;

    formGroup!: FormGroup;

    subscriptions: Subscription = new Subscription();

    rows: number = 3;
    textareaHeight: string = `${this.rows * 2.5}em`;

    constructor(
        public changeDetectorRef: ChangeDetectorRef,
        private fb: FormBuilder,
        private log: LoggerService
    ) {}

    changeRows() {
        if (this.rows === 3) {
            this.rows = 10;
        } else {
            this.rows = 3;
        }

        this.textareaHeight = `${this.rows * 2.5}em`;
    }

    ngOnDestroy(): void {
        setTimeout(() => {
            this.subscriptions.unsubscribe();
        }, backofficeEnvironment.autosavedebounce + 100);
    }

    ngOnInit(): void {
        this.initForm();
    }

    removeQuotesIfPresent(value: string): string {
        if (value && value.startsWith("'") && value.endsWith("'")) {
            return value.substring(1, value.length - 1).replace(/'/g, '&#39;');
        } else if (value) {
            return value.replace(/'/g, '&#39;');
        } else {
            return value;
        }
    }

    updateForm(): void {
        if (
            this.formGroup?.get('value')?.value !== this.removeQuotesIfPresent(this._argument.value) &&
            this._argument.inputSelectionType === 'string'
        ) {
            this.formGroup?.get('value')?.setValue(this.removeQuotesIfPresent(this._argument.value));
        }
    }

    initForm(): void {
        this.formGroup = this.fb.group({
            value: [this.removeQuotesIfPresent(this._argument.value), this._argument.parameter?.required ? Validators.required : null],
        });

        this.subscriptions.add(
            this.formGroup
                .get('value')
                ?.valueChanges.pipe(debounceTime(backofficeEnvironment.inputdebounce))
                .subscribe(value => this.onChange(value))
        );
    }

    textToUnicodeEscape(text: string): string {
        return Array.from(text)
            .map(char => `\\u${char.charCodeAt(0).toString(16).padStart(4, '0')}`)
            .join('');
    }

    onChange(value: string) {
        this.log.debug('(invocation argument string) on change value');
        this._argument.value = `'${value.replace(/'/g, '&#39;')}'`;
        this.argumentUpdated.emit({ argument: this._argument });
    }

    protected readonly NgxFloatUiTriggers = NgxFloatUiTriggers;
    protected readonly NgxFloatUiPlacements = NgxFloatUiPlacements;
}
