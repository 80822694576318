import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { backofficeEnvironment } from '@shared/environment';
import { MAT_DIALOG_DATA, MatDialogRef as MatDialogRef } from '@angular/material/dialog';
import { DataFormat, DataFormatEditorFacade } from '@backoffice/data-access/editor';
import { take } from 'rxjs/operators';
import { initFlowbite } from 'flowbite';

@Component({
    selector: 'codex-template-type-and-language',
    templateUrl: './template-type-and-language.component.html',
    standalone: false,
})
export class TemplateTypeAndLanguageComponent implements OnInit, AfterViewInit {
    selectedType = 'WEBPAGE';

    selectedLanguage: 'en';

    templateName = 'Unnamed template';

    environment = backofficeEnvironment;

    dataFormats: {
        name: string;
        id: string;
    }[] = [];

    reusableComponent: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<TemplateTypeAndLanguageComponent>,
        private dataFormatEditorFacade: DataFormatEditorFacade,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit(): void {
        this.reusableComponent = this.data.reusableComponent;
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.selectedLanguage = 'en';
        }, 0);
    }

    onSelectType(type: string) {
        this.selectedType = type;
    }

    onDataFormatPick($event: { value: string | undefined }) {
        if ($event.value) {
            this.dataFormatEditorFacade
                .findById($event.value)
                .pipe(take(1))
                .subscribe(dataFormat => {
                    this.dataFormats.push({ id: dataFormat.id, name: dataFormat.name });
                });
        }
    }

    onRemoveDataFormat(dataFormatToRemove: { name: string; id: string }): void {
        let index = this.dataFormats.findIndex(dataFormat => dataFormat.id === dataFormatToRemove.id);
        this.dataFormats.splice(index, 1);
        setTimeout(() => initFlowbite());
    }

    onCreate() {
        let dataFormatIds = this.dataFormats.map(dataFormat => dataFormat.id);
        this.dialogRef.close({
            create: true,
            language: this.selectedLanguage,
            type: this.selectedType,
            templateName: this.templateName,
            dataFormatIds,
        });
    }
}
