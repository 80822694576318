import { PartDetail } from '../../../part-detail.model';

export class UploaderPartDetail extends PartDetail {
    code: string;
    dragAndDropText: string;
    chooseFileText: string;
    startUploadButtonText: string;
    concurrency: number;
    maxUploads: number;
    maxFileSize: number;
    allowedTypes: Array<string>;
    showDropContainer: boolean;
    showUploadButton: boolean;
    showPickFileButton: boolean;
    pickFileButtonText: string;

    isValid(): boolean {
        return (
            this.isCodeValid() &&
            this.isDropContainerValid() &&
            this.isChooseFileValid() &&
            this.isMaxUploadsValid() &&
            this.isMaxFileSizeValid() &&
            this.isConcurrencyValid() &&
            this.isAllowedTypesValid()
        );
    }

    isAllowedTypesValid(): boolean {
        return !!this.allowedTypes && this.allowedTypes.length > 0;
    }

    isCodeValid(): boolean {
        return !!this.code && this.code !== '';
    }

    isMaxUploadsValid(): boolean {
        return !!this.maxUploads && this.maxUploads >= 1;
    }

    isMaxFileSizeValid(): boolean {
        return !!this.maxFileSize && this.maxFileSize >= 1;
    }

    isConcurrencyValid(): boolean {
        return !!this.concurrency && this.concurrency >= 1;
    }

    isStartUploadLabelValid(): boolean {
        return !!this.startUploadButtonText && this.startUploadButtonText !== '';
    }

    isDropContainerValid(): boolean {
        return !this.showDropContainer || (!!this.dragAndDropText && this.dragAndDropText !== '');
    }

    isChooseFileValid(): boolean {
        return !this.showPickFileButton || (!!this.chooseFileText && this.chooseFileText !== '');
    }

    getValidationErrors(): string[] {
        const validationErrors: string[] = [];
        if (!this.isCodeValid()) {
            validationErrors.push('v2.part.uploader.error.code');
        }
        if (!this.isDropContainerValid()) {
            validationErrors.push('v2.part.uploader.error.dropcontainer');
        }
        if (!this.isChooseFileValid()) {
            validationErrors.push('v2.part.uploader.error.choosefile');
        }
        if (!this.isMaxUploadsValid()) {
            validationErrors.push('v2.part.uploader.error.maxupload');
        }
        if (!this.isMaxFileSizeValid()) {
            validationErrors.push('v2.part.uploader.error.maxfilesize');
        }
        if (!this.isConcurrencyValid()) {
            validationErrors.push('v2.part.uploader.error.concurrency');
        }
        if (!this.isAllowedTypesValid()) {
            validationErrors.push('v2.part.uploader.error.allowedtypes');
        }
        return validationErrors;
    }
}
