<ng-container
    *ngIf="{
        billingPackage: billingPackage$ | async,
    } as observables">
    <div class="generative-container">
        <div class="generative-messages">
            <div class="generative-header">
                <div class="link" (click)="onCloseGenerative()">
                    <svg aria-hidden="true" focusable="false" class="icon ng-star-inserted">
                        <use href="#collapse_off" class="ui-element"></use>
                    </svg>
                </div>
                <div class="generative-credits">
                    <svg class="icon" aria-hidden="true" focusable="false">
                        <use href="#generative-credits" class="ui-element"></use>
                    </svg>
                    <span>Credits left: {{ observables.billingPackage?.amountOfAiCreditsLeft }}</span>
                </div>
            </div>
            <div class="generative-chat-messages">
                <ng-container *ngFor="let currentConversationMessage of currentConversation | async; trackBy: identifyConversation">
                    <codex-generative-sender-message-text
                        *ngIf="currentConversationMessage.prompt"
                        [message]="currentConversationMessage"></codex-generative-sender-message-text>
                    <codex-generative-ai-message-text [message]="currentConversationMessage"></codex-generative-ai-message-text>
                </ng-container>
            </div>
            <div class="generative-actions shrink-0">
                <div
                    *ngIf="actions.indexOf('generate-name') > -1"
                    class="generative-action"
                    (click)="executeGenerativeTask('GENERATE_ACTION_NAME', activeActionId, 'ACTION')">
                    <svg class="icon" aria-hidden="true" focusable="false">
                        <use href="#name" class="ui-element"></use>
                    </svg>
                    <span>Generate name</span>
                </div>
                <div
                    *ngIf="actions.indexOf('generate-description') > -1"
                    class="generative-action"
                    (click)="executeGenerativeTask('GENERATE_ACTION_DESCRIPTION', activeActionId, 'ACTION')">
                    <svg class="icon" aria-hidden="true" focusable="false">
                        <use href="#description" class="ui-element"></use>
                    </svg>
                    <span>Generate description</span>
                </div>
                <div
                    *ngIf="actions.indexOf('explain-action') > -1"
                    class="generative-action"
                    (click)="executeGenerativeTask('EXPLAIN_ACTION', activeActionId, 'ACTION')">
                    <svg class="icon" aria-hidden="true" focusable="false">
                        <use href="#explanation" class="ui-element"></use>
                    </svg>
                    <span>Explain action</span>
                </div>
            </div>

            <div class="mb-2 input-button-container">
                <textarea
                    class="generative-chat-input small-input shrink-0 scrollbar scrollbar-primary"
                    type="text"
                    tabindex="-1"
                    id="sender-chat"
                    name="sender-chat"
                    autocomplete="off"
                    [(ngModel)]="prompt"
                    [rows]="rows"
                    [style.height]="textareaHeight"
                    (keydown.enter)="executeGenerativeTask('OPEN', activeActionId, 'ACTION')"></textarea>
                <svg
                    class="icon input-button !h-5 !w-5 !right-2 z-[10000]"
                    [showTrigger]="NgxFloatUiTriggers.hover"
                    [floatUi]="actionNamePopover"
                    appendTo="body"
                    [preventOverflow]="false"
                    [boundariesElement]="'rootBoundary'"
                    [positionFixed]="true"
                    [placement]="NgxFloatUiPlacements.RIGHT"
                    [appendTo]="'body'"
                    (click)="changeRows()">
                    <use href="#expand" class="ui-element"></use>
                </svg>
                <float-ui-content #actionNamePopover>
                    <div class="popover navigation-info">
                        <div class="draggable-card-popover-title-without-text">
                            <h3 class="draggable-card-popover-title-element">Make this field bigger/smaller</h3>
                        </div>
                    </div>
                </float-ui-content>
            </div>
        </div>
    </div>
</ng-container>
