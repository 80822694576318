<mat-dialog-content class="!p-4 scrollbar scrollbar-primary">
    <form [formGroup]="formGroup" (ngSubmit)="onCreateUser()">
        <h1 class="!mb-2">Create a user</h1>

        <label for="email" class="small-input-label" data-name="email">Email</label>
        <input class="small-input" type="text" id="email" name="email" autocomplete="off" formControlName="email" required />
        <p
            class="small-input-error"
            *ngIf="formGroup.controls.email.touched && formGroup.controls.email.invalid && formGroup.controls.email.hasError('required')">
            Please provide a valid email address of the user you wish to create.
        </p>

        <label class="toggle-line">
            Verified email
            <input type="checkbox" value="" class="sr-only peer" formControlName="emailVerified" />
            <div
                class="toggle peer-focus:outline-none peer-checked:bg-dark-button-background peer-checked:after:translate-x-full peer rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white"></div>
        </label>

        <label class="toggle-line">
            Trigger email verification immediatly
            <input type="checkbox" value="" class="sr-only peer" formControlName="triggerEmailValidationImmediatly" />
            <div
                class="toggle peer-focus:outline-none peer-checked:bg-dark-button-background peer-checked:after:translate-x-full peer rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white"></div>
        </label>

        <hr class="seperator" />

        <label for="userPassword" class="small-input-label" data-name="media-name">Password</label>
        <div class="relative">
            <input
                class="small-input"
                type="password"
                id="userPassword"
                name="userPassword"
                autocomplete="off"
                formControlName="password"
                required />
            <div class="absolute inset-y-0 end-0 flex items-center pe-3.5">
                <svg
                    *ngIf="!showingUserPassword"
                    class="icon icon-button"
                    aria-hidden="true"
                    focusable="false"
                    (click)="togglePasswordType('userPassword'); showingUserPassword = !showingUserPassword">
                    <use id="userPassword-toggle" href="#view" class="ui-element"></use>
                </svg>
                <svg
                    *ngIf="showingUserPassword"
                    class="icon icon-button"
                    aria-hidden="true"
                    focusable="false"
                    (click)="togglePasswordType('userPassword'); showingUserPassword = !showingUserPassword">
                    <use id="userPassword-toggle" href="#hide" class="ui-element"></use>
                </svg>
            </div>
        </div>
        <p
            class="small-input-error"
            *ngIf="
                formGroup.controls.password.touched &&
                formGroup.controls.password.invalid &&
                formGroup.controls.password.hasError('passwordStrength')
            ">
            Foutje!
        </p>
        <p
            class="small-input-error"
            *ngIf="
                formGroup.controls.password.touched &&
                formGroup.controls.password.invalid &&
                formGroup.controls.password.hasError('required')
            ">
            Please provide a password of the user you wish to create.
        </p>

        <label for="confirmPassword" class="small-input-label" data-name="media-name">Confirm password</label>
        <div class="relative">
            <input
                class="small-input"
                type="password"
                id="confirmPassword"
                name="password"
                autocomplete="off"
                formControlName="confirmPassword"
                required />
            <div class="absolute inset-y-0 end-0 flex items-center pe-3.5">
                <svg
                    *ngIf="!showingConfirmPassword"
                    class="icon icon-button"
                    aria-hidden="true"
                    focusable="false"
                    (click)="togglePasswordType('confirmPassword'); showingConfirmPassword = !showingConfirmPassword">
                    <use id="confirmPassword-toggle" href="#view" class="ui-element"></use>
                </svg>
                <svg
                    *ngIf="showingConfirmPassword"
                    class="icon icon-button"
                    aria-hidden="true"
                    focusable="false"
                    (click)="togglePasswordType('confirmPassword'); showingConfirmPassword = !showingConfirmPassword">
                    <use id="confirmPassword-toggle" href="#hide" class="ui-element"></use>
                </svg>
            </div>
        </div>
        <p
            class="small-input-error"
            *ngIf="
                formGroup.controls.confirmPassword.touched &&
                formGroup.controls.confirmPassword.invalid &&
                formGroup.controls.confirmPassword.hasError('passwordStrength')
            ">
            Foutje!
        </p>
        <p
            class="small-input-error"
            *ngIf="
                formGroup.controls.confirmPassword.touched &&
                formGroup.controls.confirmPassword.invalid &&
                formGroup.controls.confirmPassword.hasError('required')
            ">
            Please confirm the password of the user you wish to create.
        </p>
        <p class="small-input-error" *ngIf="showConfirmPasswordError">Please make sure "password" and "confirm password" are equal.</p>

        <label class="toggle-line">
            Temporary password
            <input type="checkbox" value="" class="sr-only peer" formControlName="temporaryPassword" />
            <div
                class="toggle peer-focus:outline-none peer-checked:bg-dark-button-background peer-checked:after:translate-x-full peer rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white"></div>
        </label>

        <label class="toggle-line">
            Force OTP
            <input type="checkbox" value="" class="sr-only peer" formControlName="forceOtp" />
            <div
                class="toggle peer-focus:outline-none peer-checked:bg-dark-button-background peer-checked:after:translate-x-full peer rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white"></div>
        </label>

        <label class="toggle-line" *ngIf="false">
            Passwordless
            <input type="checkbox" value="" class="sr-only peer" formControlName="passwordLess" />
            <div
                class="toggle peer-focus:outline-none peer-checked:bg-dark-button-background peer-checked:after:translate-x-full peer rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white"></div>
        </label>

        <label class="toggle-line">
            Force webauthn
            <input type="checkbox" value="" class="sr-only peer" formControlName="forceWebAuthn" />
            <div
                class="toggle peer-focus:outline-none peer-checked:bg-dark-button-background peer-checked:after:translate-x-full peer rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white"></div>
        </label>

        <hr class="seperator" />

        <label for="firstName" class="small-input-label" data-name="firstName">Firstname</label>
        <input class="small-input" type="text" id="firstName" name="firstName" autocomplete="off" formControlName="firstName" required />
        <p
            class="small-input-error"
            *ngIf="
                formGroup.controls.firstName.touched &&
                formGroup.controls.firstName.invalid &&
                formGroup.controls.firstName.hasError('required')
            ">
            Please provide the firstname of the user you wish to create.
        </p>

        <label for="lastName" class="small-input-label" data-name="lastName">Lastname</label>
        <input class="small-input" type="text" id="lastName" name="lastName" autocomplete="off" formControlName="lastName" required />
        <p
            class="small-input-error"
            *ngIf="
                formGroup.controls.lastName.touched &&
                formGroup.controls.lastName.invalid &&
                formGroup.controls.lastName.hasError('required')
            ">
            Please provide the lastname of the user you wish to create.
        </p>

        <label for="environment" class="small-input-label">{{ 'v2.usermanagement.users.environment.title' | translate }}</label>
        <select id="environment" class="small-input" formControlName="environment">
            <option [value]="'DEV'">{{ 'v2.usermanagement.users.environment.dev' | translate }}</option>
            <option [value]="'TEST'">{{ 'v2.usermanagement.users.environment.test' | translate }}</option>
            <option [value]="'ACCEPT'">{{ 'v2.usermanagement.users.environment.accept' | translate }}</option>
            <option [value]="'PRODUCTION'">{{ 'v2.usermanagement.users.environment.production' | translate }}</option>
        </select>

        <div class="flex justify-end gap-1">
            <button class="primary-button button-medium" type="submit">
                <mat-icon>save</mat-icon>
                {{ 'general.save' | translate }}
            </button>
            <button class="secondary-button button-medium" (click)="onCancel()" type="button">
                <mat-icon>cancel</mat-icon>
                {{ 'general.cancel' | translate }}
            </button>
        </div>
    </form>
</mat-dialog-content>
