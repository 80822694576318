<ng-container *ngIf="_argument">
    <argument-title class="mb-1" [argument]="_argument"></argument-title>
    <div class="button-group" role="group" *ngIf="allowedSelectionTypes.size > 1">
        <button
            (click)="onChangeSelectionType('scope')"
            *ngIf="!allowedSelectionTypes || allowedSelectionTypes.has('scope')"
            [matTooltip]="'v2.parameter.types.scope' | translate"
            type="button"
            class="button-group-button-middle"
            [ngClass]="selectedSelectionType === 'scope' ? 'button-group-button-selected' : ''"
            autofocus>
            <mat-icon>integration_instructions</mat-icon>
        </button>
        <button
            (click)="onChangeSelectionType('part')"
            *ngIf="!allowedSelectionTypes || allowedSelectionTypes.has('part')"
            [matTooltip]="'v2.parameter.types.part' | translate"
            type="button"
            class="button-group-button-middle"
            [ngClass]="selectedSelectionType === 'part' ? 'button-group-button-selected' : ''">
            <mat-icon>grid_view</mat-icon>
        </button>
        <button
            (click)="onChangeSelectionType('layer')"
            *ngIf="!allowedSelectionTypes || allowedSelectionTypes.has('layer')"
            [matTooltip]="'v2.parameter.types.layer' | translate"
            type="button"
            class="button-group-button-middle"
            [ngClass]="selectedSelectionType === 'layer' ? 'button-group-button-selected' : ''">
            <mat-icon>layers</mat-icon>
        </button>
        <button
            (click)="onChangeSelectionType('template')"
            *ngIf="!allowedSelectionTypes || allowedSelectionTypes.has('template')"
            [matTooltip]="'v2.parameter.types.template' | translate"
            type="button"
            class="button-group-button-middle"
            [ngClass]="selectedSelectionType === 'template' ? 'button-group-button-selected' : ''">
            <mat-icon>widgets</mat-icon>
        </button>
        <button
            (click)="onChangeSelectionType('array')"
            *ngIf="!allowedSelectionTypes || allowedSelectionTypes.has('array')"
            [matTooltip]="'v2.parameter.types.array' | translate"
            type="button"
            class="button-group-button-middle"
            [ngClass]="selectedSelectionType === 'array' ? 'button-group-button-selected' : ''">
            <mat-icon>data_array</mat-icon>
        </button>
        <button
            (click)="onChangeSelectionType('tag')"
            *ngIf="!allowedSelectionTypes || allowedSelectionTypes.has('tag')"
            [matTooltip]="'v2.parameter.types.tag' | translate"
            type="button"
            class="button-group-button-middle"
            [ngClass]="selectedSelectionType === 'tag' ? 'button-group-button-selected' : ''">
            <mat-icon>label</mat-icon>
        </button>
        <button
            (click)="onChangeSelectionType('action')"
            *ngIf="!allowedSelectionTypes || allowedSelectionTypes.has('action')"
            [matTooltip]="'v2.parameter.types.action' | translate"
            type="button"
            class="button-group-button-middle"
            [ngClass]="selectedSelectionType === 'action' ? 'button-group-button-selected' : ''">
            <mat-icon>bolt</mat-icon>
        </button>
        <button
            (click)="onChangeSelectionType('data-format')"
            *ngIf="!allowedSelectionTypes || allowedSelectionTypes.has('data-format')"
            [matTooltip]="'v2.parameter.types.data-format' | translate"
            type="button"
            class="button-group-button-middle"
            [ngClass]="selectedSelectionType === 'data-format' ? 'button-group-button-selected' : ''">
            <mat-icon>data_array</mat-icon>
        </button>
        <button
            (click)="onChangeSelectionType('data-format-filters')"
            *ngIf="!allowedSelectionTypes || allowedSelectionTypes.has('data-format-filters')"
            [matTooltip]="'v2.parameter.types.data-format-filters' | translate"
            type="button"
            class="button-group-button-middle"
            [ngClass]="selectedSelectionType === 'data-format-filters' ? 'button-group-button-selected' : ''">
            <mat-icon>filter_alt</mat-icon>
        </button>
        <button
            (click)="onChangeSelectionType('data-format-attribute')"
            *ngIf="!allowedSelectionTypes || allowedSelectionTypes.has('data-format-attribute')"
            [matTooltip]="'v2.parameter.types.data-format-attribute' | translate"
            type="button"
            class="button-group-button-middle"
            [ngClass]="selectedSelectionType === 'data-format-attribute' ? 'button-group-button-selected' : ''">
            <mat-icon>filter_alt</mat-icon>
        </button>
        <button
            (click)="onChangeSelectionType('data-format-attributes')"
            *ngIf="!allowedSelectionTypes || allowedSelectionTypes.has('data-format-attributes')"
            [matTooltip]="'v2.parameter.types.data-format-attributes' | translate"
            type="button"
            class="button-group-button-middle"
            [ngClass]="selectedSelectionType === 'data-format-attributes' ? 'button-group-button-selected' : ''">
            <mat-icon>filter_alt</mat-icon>
        </button>
        <button
            (click)="onChangeSelectionType('data-format-sort-orders')"
            *ngIf="!allowedSelectionTypes || allowedSelectionTypes.has('data-format-sort-orders')"
            [matTooltip]="'v2.parameter.types.data-format-sort-orders' | translate"
            type="button"
            class="button-group-button-middle"
            [ngClass]="selectedSelectionType === 'data-format-sort-orders' ? 'button-group-button-selected' : ''">
            <mat-icon>filter_alt</mat-icon>
        </button>
        <button
            (click)="onChangeSelectionType('number')"
            *ngIf="!allowedSelectionTypes || allowedSelectionTypes.has('number')"
            [matTooltip]="'v2.parameter.types.number' | translate"
            type="button"
            class="button-group-button-middle"
            [ngClass]="selectedSelectionType === 'number' ? 'button-group-button-selected' : ''">
            <mat-icon>123</mat-icon>
        </button>
        <button
            (click)="onChangeSelectionType('string')"
            *ngIf="!allowedSelectionTypes || allowedSelectionTypes.has('string')"
            [matTooltip]="'v2.parameter.types.string' | translate"
            type="button"
            class="button-group-button-middle"
            [ngClass]="selectedSelectionType === 'string' ? 'button-group-button-selected' : ''">
            <mat-icon>abc</mat-icon>
        </button>
        <button
            (click)="onChangeSelectionType('password')"
            *ngIf="!allowedSelectionTypes || allowedSelectionTypes.has('password')"
            [matTooltip]="'v2.parameter.types.password' | translate"
            type="button"
            class="button-group-button-middle"
            [ngClass]="selectedSelectionType === 'password' ? 'button-group-button-selected' : ''">
            <mat-icon>password</mat-icon>
        </button>
        <button
            (click)="onChangeSelectionType('boolean')"
            *ngIf="!allowedSelectionTypes || allowedSelectionTypes.has('boolean')"
            [matTooltip]="'v2.parameter.types.boolean' | translate"
            type="button"
            class="button-group-button-middle"
            [ngClass]="selectedSelectionType === 'boolean' ? 'button-group-button-selected' : ''">
            <mat-icon>check_box</mat-icon>
        </button>
        <button
            (click)="onChangeSelectionType('select-one')"
            *ngIf="!allowedSelectionTypes || allowedSelectionTypes.has('select-one')"
            [matTooltip]="'v2.parameter.types.select-one' | translate"
            type="button"
            class="button-group-button-middle"
            [ngClass]="selectedSelectionType === 'select-one' ? 'button-group-button-selected' : ''">
            <mat-icon>list_alt</mat-icon>
        </button>
        <button
            (click)="onChangeSelectionType('select-multi')"
            *ngIf="!allowedSelectionTypes || allowedSelectionTypes.has('select-multi')"
            [matTooltip]="'v2.parameter.types.select-multi' | translate"
            type="button"
            class="button-group-button-middle"
            [ngClass]="selectedSelectionType === 'select-multi' ? 'button-group-button-selected' : ''">
            <mat-icon>check_box</mat-icon>
        </button>
        <button
            (click)="onChangeSelectionType('template-params')"
            *ngIf="!allowedSelectionTypes || allowedSelectionTypes.has('template-params')"
            [matTooltip]="'v2.parameter.types.template-params' | translate"
            type="button"
            class="button-group-button-middle"
            [ngClass]="selectedSelectionType === 'template-params' ? 'button-group-button-selected' : ''">
            <mat-icon>input</mat-icon>
        </button>
        <button
            (click)="onChangeSelectionType('action-params')"
            *ngIf="!allowedSelectionTypes || allowedSelectionTypes.has('action-params')"
            [matTooltip]="'v2.parameter.types.action-params' | translate"
            type="button"
            class="button-group-button-middle"
            [ngClass]="selectedSelectionType === 'action-params' ? 'button-group-button-selected' : ''">
            <mat-icon>input</mat-icon>
        </button>
        <button
            (click)="onChangeSelectionType('json')"
            *ngIf="!allowedSelectionTypes || allowedSelectionTypes.has('json')"
            [matTooltip]="'v2.parameter.types.json' | translate"
            type="button"
            class="button-group-button-middle"
            [ngClass]="selectedSelectionType === 'json' ? 'button-group-button-selected' : ''">
            <mat-icon>input</mat-icon>
        </button>
        <button
            (click)="onChangeSelectionType('object')"
            *ngIf="!allowedSelectionTypes || allowedSelectionTypes.has('object')"
            [matTooltip]="'v2.parameter.types.object' | translate"
            type="button"
            class="button-group-button-middle"
            [ngClass]="selectedSelectionType === 'object' ? 'button-group-button-selected' : ''">
            <mat-icon>input</mat-icon>
        </button>
        <button
            (click)="onChangeSelectionType('xml')"
            *ngIf="!allowedSelectionTypes || allowedSelectionTypes.has('xml')"
            [matTooltip]="'v2.parameter.types.xml' | translate"
            type="button"
            class="button-group-button-middle"
            [ngClass]="selectedSelectionType === 'xml' ? 'button-group-button-selected' : ''">
            <mat-icon>input</mat-icon>
        </button>
        <button
            (click)="onChangeSelectionType('databaseconnection')"
            *ngIf="!allowedSelectionTypes || allowedSelectionTypes.has('databaseconnection')"
            [matTooltip]="'v2.parameter.types.database-connection' | translate"
            type="button"
            class="button-group-button-middle"
            [ngClass]="selectedSelectionType === 'databaseconnection' ? 'button-group-button-selected' : ''">
            <mat-icon>database</mat-icon>
        </button>
        <button
            (click)="onChangeSelectionType('composed_list')"
            *ngIf="!allowedSelectionTypes || allowedSelectionTypes.has('composed_list')"
            [matTooltip]="'v2.parameter.types.composed_list' | translate"
            type="button"
            class="button-group-button-middle"
            [ngClass]="selectedSelectionType === 'composed_list' ? 'button-group-button-selected' : ''">
            <mat-icon>clear_all</mat-icon>
        </button>
        <button
            (click)="onChangeSelectionType('composed')"
            *ngIf="!allowedSelectionTypes || allowedSelectionTypes.has('composed')"
            [matTooltip]="'v2.parameter.types.composed' | translate"
            type="button"
            class="button-group-button-middle"
            [ngClass]="selectedSelectionType === 'composed' ? 'button-group-button-selected' : ''">
            <mat-icon>clear_all</mat-icon>
        </button>
        <button
            (click)="onChangeSelectionType('prototype')"
            *ngIf="!allowedSelectionTypes || allowedSelectionTypes.has('prototype')"
            [matTooltip]="'v2.parameter.types.prototype' | translate"
            type="button"
            class="button-group-button-middle"
            [ngClass]="selectedSelectionType === 'prototype' ? 'button-group-button-selected' : ''">
            <mat-icon>gavel</mat-icon>
        </button>
        <button
            (click)="onChangeSelectionType('template-contract')"
            *ngIf="!allowedSelectionTypes || allowedSelectionTypes.has('template-contract')"
            [matTooltip]="'v2.parameter.types.template-contract' | translate"
            type="button"
            class="button-group-button-middle"
            [ngClass]="selectedSelectionType === 'template-contract' ? 'button-group-button-selected' : ''">
            <mat-icon>gavel</mat-icon>
        </button>
        <button
            (click)="onChangeSelectionType('agent')"
            *ngIf="!allowedSelectionTypes || allowedSelectionTypes.has('agent')"
            [matTooltip]="'v2.parameter.types.agent' | translate"
            type="button"
            class="button-group-button-middle"
            [ngClass]="selectedSelectionType === 'agent' ? 'button-group-button-selected' : ''">
            <mat-icon>smart_toy</mat-icon>
        </button>
        <button
            (click)="onChangeSelectionType('task')"
            *ngIf="!allowedSelectionTypes || allowedSelectionTypes.has('task')"
            [matTooltip]="'v2.parameter.types.task' | translate"
            type="button"
            class="button-group-button-middle"
            [ngClass]="selectedSelectionType === 'task' ? 'button-group-button-selected' : ''">
            <mat-icon>task</mat-icon>
        </button>
    </div>
    <ng-container *ngIf="selectedSelectionType === 'scope'">
        <argument-scope-picker
            (argumentUpdated)="this.argumentUpdated.emit($event)"
            [argument]="_argument"
            [arguments]="_arguments"
            [contextId]="contextId"
            [scope]="scope">
        </argument-scope-picker>
    </ng-container>
    <ng-container *ngIf="selectedSelectionType === 'part'">
        <argument-part-picker
            (argumentUpdated)="this.argumentUpdated.emit($event)"
            [argument]="_argument"
            [arguments]="_arguments"
            [contextId]="contextId"
            [scope]="scope">
        </argument-part-picker>
    </ng-container>
    <ng-container *ngIf="selectedSelectionType === 'layer'">
        <argument-layer-picker
            (argumentUpdated)="this.argumentUpdated.emit($event)"
            [argument]="_argument"
            [arguments]="_arguments"
            [contextId]="contextId"
            [scope]="scope">
        </argument-layer-picker>
    </ng-container>
    <ng-container *ngIf="selectedSelectionType === 'template'">
        <argument-template-picker
            (argumentUpdated)="this.argumentUpdated.emit($event)"
            (openTab)="this.openTab.emit($event)"
            [argument]="_argument"
            [arguments]="_arguments"
            [contextId]="contextId"
            [scope]="scope">
        </argument-template-picker>
    </ng-container>
    <ng-container *ngIf="selectedSelectionType === 'template-contract'">
        <app-argument-template-contract
            (argumentUpdated)="this.argumentUpdated.emit($event)"
            (openTab)="this.openTab.emit($event)"
            [argument]="_argument"
            [arguments]="_arguments"
            [contextId]="contextId"
            [scope]="scope">
        </app-argument-template-contract>
    </ng-container>
    <ng-container *ngIf="selectedSelectionType === 'template-params'">
        <argument-template-params
            (argumentUpdated)="this.argumentUpdated.emit($event)"
            (openTab)="this.openTab.emit($event)"
            [argument]="_argument"
            [arguments]="_arguments"
            [contextId]="contextId"
            [scope]="scope"
            [onlyLiteralValues]="_onlyLiteralValues">
        </argument-template-params>
    </ng-container>
    <ng-container *ngIf="selectedSelectionType === 'tag'">
        <argument-tag-picker
            (argumentUpdated)="this.argumentUpdated.emit($event)"
            [argument]="_argument"
            [arguments]="_arguments"
            [contextId]="contextId"
            [scope]="scope">
        </argument-tag-picker>
    </ng-container>
    <ng-container *ngIf="selectedSelectionType === 'action'">
        <argument-action-picker
            (argumentUpdated)="this.argumentUpdated.emit($event)"
            (openTab)="this.openTab.emit($event)"
            [argument]="_argument"
            [arguments]="_arguments"
            [contextId]="contextId"
            [scope]="scope">
        </argument-action-picker>
    </ng-container>
    <ng-container *ngIf="selectedSelectionType === 'prototype'">
        <app-argument-action-prototype
            (argumentUpdated)="this.argumentUpdated.emit($event)"
            (openTab)="this.openTab.emit($event)"
            [argument]="_argument"
            [arguments]="_arguments"
            [contextId]="contextId"
            [scope]="scope">
        </app-argument-action-prototype>
    </ng-container>
    <ng-container *ngIf="selectedSelectionType === 'action-params'">
        <argument-action-params
            (argumentUpdated)="this.argumentUpdated.emit($event)"
            (openTab)="this.openTab.emit($event)"
            [argument]="_argument"
            [arguments]="_arguments"
            [contextId]="contextId"
            [language]="language"
            [root]="root"
            [scope]="scope"
            [onlyLiteralValues]="_onlyLiteralValues">
        </argument-action-params>
    </ng-container>
    <ng-container *ngIf="selectedSelectionType === 'data-format'">
        <argument-dataformat-picker
            (argumentUpdated)="this.argumentUpdated.emit($event)"
            (openTab)="this.openTab.emit($event)"
            [argument]="_argument"
            [arguments]="_arguments"
            [contextId]="contextId"
            [scope]="scope">
        </argument-dataformat-picker>
    </ng-container>
    <ng-container *ngIf="selectedSelectionType === 'data-format-filters'">
        <argument-dataformat-filters
            (argumentUpdated)="this.argumentUpdated.emit($event)"
            [argument]="_argument"
            [arguments]="_arguments"
            [contextId]="contextId"
            [language]="language"
            [scope]="scope"
            [onlyLiteralValues]="_onlyLiteralValues">
        </argument-dataformat-filters>
    </ng-container>
    <ng-container *ngIf="selectedSelectionType === 'data-format-attribute'">
        <argument-dataformat-attribute-picker
            (argumentUpdated)="this.argumentUpdated.emit($event)"
            [argument]="_argument"
            [contextId]="contextId"
            [language]="language"
            [root]="root"
            [scope]="scope">
        </argument-dataformat-attribute-picker>
    </ng-container>
    <ng-container *ngIf="selectedSelectionType === 'data-format-attributes'">
        <argument-dataformat-attributes
            (argumentUpdated)="this.argumentUpdated.emit($event)"
            [argument]="_argument"
            [arguments]="_arguments"
            [contextId]="contextId"
            [language]="language"
            [root]="root"
            [scope]="scope">
        </argument-dataformat-attributes>
    </ng-container>
    <ng-container *ngIf="selectedSelectionType === 'data-format-sort-orders'">
        <argument-dataformat-sort-orders
            (argumentUpdated)="this.argumentUpdated.emit($event)"
            [argument]="_argument"
            [arguments]="_arguments"
            [contextId]="contextId"
            [language]="language"
            [root]="root"
            [scope]="scope">
        </argument-dataformat-sort-orders>
    </ng-container>
    <ng-container *ngIf="selectedSelectionType === 'json'">
        <argument-json
            (argumentUpdated)="this.argumentUpdated.emit($event)"
            [argument]="_argument"
            [arguments]="_arguments"
            [root]="root"
            [contextId]="contextId"
            [language]="language"
            [scope]="scope"
            [onlyLiteralValues]="_onlyLiteralValues">
        </argument-json>
    </ng-container>
    <ng-container *ngIf="selectedSelectionType === 'object'">
        <argument-object
            (argumentUpdated)="this.argumentUpdated.emit($event)"
            [argument]="_argument"
            [arguments]="_arguments"
            [contextId]="contextId"
            [language]="language"
            [root]="root"
            [scope]="scope"
            [onlyLiteralValues]="_onlyLiteralValues"
            (openTab)="this.openTab.emit($event)">
        </argument-object>
    </ng-container>
    <ng-container *ngIf="selectedSelectionType === 'xml'">
        <argument-xml
            (argumentUpdated)="this.argumentUpdated.emit($event)"
            [argument]="_argument"
            [arguments]="_arguments"
            [contextId]="contextId"
            [language]="language"
            [scope]="scope">
        </argument-xml>
    </ng-container>
    <ng-container *ngIf="selectedSelectionType === 'number'">
        <argument-number-input
            (argumentUpdated)="this.argumentUpdated.emit($event)"
            [argument]="_argument"
            [arguments]="_arguments"
            [contextId]="contextId"
            [scope]="scope">
        </argument-number-input>
    </ng-container>
    <ng-container *ngIf="selectedSelectionType === 'string'">
        <argument-string-input
            (argumentUpdated)="this.argumentUpdated.emit($event)"
            [argument]="_argument"
            [arguments]="_arguments"
            [contextId]="contextId"
            [scope]="scope">
        </argument-string-input>
    </ng-container>
    <ng-container *ngIf="selectedSelectionType === 'password'">
        <argument-password-input
            (argumentUpdated)="this.argumentUpdated.emit($event)"
            [argument]="_argument"
            [arguments]="_arguments"
            [contextId]="contextId"
            [scope]="scope">
        </argument-password-input>
    </ng-container>
    <ng-container *ngIf="selectedSelectionType === 'boolean'">
        <argument-boolean-input
            (argumentUpdated)="this.argumentUpdated.emit($event)"
            [argument]="_argument"
            [arguments]="_arguments"
            [contextId]="contextId"
            [scope]="scope">
        </argument-boolean-input>
    </ng-container>
    <ng-container *ngIf="selectedSelectionType === 'select-one'">
        <argument-select-one-input
            (argumentUpdated)="this.argumentUpdated.emit($event)"
            [argument]="_argument"
            [arguments]="_arguments"
            [contextId]="contextId"
            [scope]="scope">
        </argument-select-one-input>
    </ng-container>
    <ng-container *ngIf="selectedSelectionType === 'select-multi'">
        <argument-select-multi-input
            (argumentUpdated)="this.argumentUpdated.emit($event)"
            [argument]="_argument"
            [arguments]="_arguments"
            [contextId]="contextId"
            [scope]="scope">
        </argument-select-multi-input>
    </ng-container>
    <ng-container *ngIf="selectedSelectionType === 'databaseconnection'">
        <argument-database-connection
            (argumentUpdated)="this.argumentUpdated.emit($event)"
            [argument]="_argument"
            [arguments]="_arguments"
            [root]="root"
            [contextId]="contextId"
            [scope]="scope"
            [onlyLiteralValues]="_onlyLiteralValues">
        </argument-database-connection>
    </ng-container>
    <ng-container *ngIf="selectedSelectionType === 'array'">
        <argument-array
            (argumentUpdated)="this.argumentUpdated.emit($event)"
            (openTab)="this.openTab.emit($event)"
            [argument]="_argument"
            [arguments]="_arguments"
            [contextId]="contextId"
            [root]="root"
            [scope]="scope"
            [onlyLiteralValues]="_onlyLiteralValues">
        </argument-array>
    </ng-container>
    <ng-container *ngIf="selectedSelectionType === 'composed_list'">
        <argument-composed-list
            (argumentUpdated)="this.argumentUpdated.emit($event)"
            (openTab)="this.openTab.emit($event)"
            [argument]="_argument"
            [arguments]="_arguments"
            [contextId]="contextId"
            [root]="root"
            [scope]="scope">
        </argument-composed-list>
    </ng-container>
    <ng-container *ngIf="selectedSelectionType === 'composed'">
        <argument-composed
            (argumentUpdated)="this.argumentUpdated.emit($event)"
            (openTab)="this.openTab.emit($event)"
            [argument]="_argument"
            [arguments]="_arguments"
            [contextId]="contextId"
            [language]="language"
            [root]="root"
            [scope]="scope"
            [onlyLiteralValues]="_onlyLiteralValues">
        </argument-composed>
    </ng-container>
    <ng-container *ngIf="selectedSelectionType === 'agent'">
        <argument-agent
            (argumentUpdated)="this.argumentUpdated.emit($event)"
            (openTab)="this.openTab.emit($event)"
            [argument]="_argument"
            [arguments]="_arguments"
            [contextId]="contextId"
            [scope]="scope"
            [language]="language"
            [root]="root"
            [onlyLiteralValues]="_onlyLiteralValues">
        </argument-agent>
    </ng-container>
    <ng-container *ngIf="selectedSelectionType === 'task'">
        <argument-task
            (argumentUpdated)="this.argumentUpdated.emit($event)"
            (openTab)="this.openTab.emit($event)"
            [argument]="_argument"
            [arguments]="_arguments"
            [contextId]="contextId"
            [scope]="scope"
            [language]="language"
            [root]="root"
            [onlyLiteralValues]="_onlyLiteralValues">
        </argument-task>
    </ng-container>
</ng-container>
