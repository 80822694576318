<form autocomplete="off">
    <ng-container *ngIf="invocation.arguments && invocation.arguments.length > 0">
        <ng-container *ngFor="let argument of invocation.arguments; trackBy: identifyArgument">
            <argument-value-picker
                class="mb-1 block"
                *ngIf="showArgumentInput(argument, invocation.arguments)"
                [argument]="argument"
                [arguments]="invocation.arguments"
                [contextId]="invocation.id"
                [language]="language"
                [root]="invocation.arguments"
                [scope]="_scope"
                [onlyLiteralValues]="onlyLiteralValues"
                (argumentUpdated)="onArgumentUpdated($event)"
                (openTab)="openTab.emit($event)"></argument-value-picker>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="!invocation.arguments || invocation.arguments.length === 0">
        <div class="flex flex-col justify-center items-center">
            <img class="w-[350px] h-[200px] object-contain" src="/images/theme/no_params.gif" />
            <h1 class="text-center !mb-2 !leading-9">No arguments for this invocation.</h1>
            <p class="!mb-8 text-white text-center">This invocations has no arguments to configure.</p>
        </div>
    </ng-container>
</form>
